import React, { Component, useState, useCallback } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';


import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { getStepLabelUtilityClass } from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SchemaIcon from '@mui/icons-material/Schema';
import HomeIcon from '@mui/icons-material/Home';
import CheckIcon from '@mui/icons-material/Check';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress, {
    circularProgressClasses,
  } from '@mui/material/CircularProgress';

// import { AppModule } from "./modules";
import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, registerInitHook } from "../network";
import { AvailableWorkflows } from "./workflow-available";
import { CompletedWorkflows } from "./workflow-completed";
import { openPdfDocument, openPdfDocumentCompare }  from "../webviewer";
import { SelectPdfDraftDialog } from "./workflow-select-draft";
import { EvaluateFormDialog } from "./management-workflow-forms";

var wreload = 0;
const DEBOUNCE_TIME = 120;

const typeOf = obj => {
    let type = ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1]
    if (type === 'Object') {
        const results = (/^(function|class)\s+(\w+)/).exec(obj.constructor.toString())
        type = (results && results.length > 2) ? results[2] : ''
    }
    return type.toLowerCase()
};

const stringify  = function(value, space) {
    var cache = [];

    var output = JSON.stringify(value, function (key, value) {

        //filters vue.js internal properties
        if(key && key.length>0 && (key.charAt(0)=="$" || key.charAt(0)=="_")) {

            return;
        }

        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Circular reference found, discard key
                return;
            }
            // Store value in our collection
            cache.push(value);
        }


        return value;
    }, space)

    cache = null; // Enable garbage collection

    return output;
}

function ChooseViewPdfDialog(props) {
    const {
        open,
        files,
        onCancel,
        previousDrafts,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleFileClick = (file, label) => {
        /*
        WorkflowModule.pageIndexChange(1);
        const { annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + file);
        */
        openPdfDocument(file, label, previousDrafts);
        onClose();
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Choose the PDF File to Open</DialogTitle>
            <DialogContent>
                <List>
                    { files.map( item => (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleFileClick(item.path, item.label)}>
                                <ListItemIcon>
                                    <OpenInNewIcon fontSize="0.92em" fontWeight="bold"/>
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    )
                    )
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function WorkflowStepInfo(props) {
    const {
        def,
        instId,
        stepId,
        history,
        ...other
    } = props;

    const [ stepInstance, setStepInstance] = React.useState(null);

    React.useEffect(() => {

        // console.log('Fetching workflow definition ' + stepId + '...');

        netGet('/api/workflow/step/find/' + instId + '/' + stepId)
            .then(response => response.json())
            .then(data => {
                let si = { ...data };
                if (data.startDate) {
                    // console.log('in date [' + typeOf(data.startDate) + '] = ' + data.startDate);
                    si["startDate"] = new Date(data.startDate);
                }
                if (data.endDate) {
                    si["endDate"] = new Date(data.endDate);
                }
                setStepInstance(si);
                // console.log('Type of startDate: ' + typeOf(si.startDate) + ' = ' + si.startDate);
            })
            .catch(error => {
                // ignore
            });


    }, [instId, stepId]); // add dependency on props to call this for each instance

    return (
        <Box sx={{padding: '2ex'}}>
            { history ?
                <Typography variant="h7" sx={{fontWeight: 'bold'}}>{def.label}</Typography> : null
            }
            <Typography sx={{ paddingTop: '1ex' }}>{def.description ? def.description : ''}</Typography>
            {stepInstance && stepInstance.user ?
                <Box sx={{ mb: 2, paddingTop: '1ex', marginBottom: 0 }}>
                    <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline-block', minWidth: '8em' }}>{history ? 'Performed by: ' : 'Assigned to: '}</Typography>
                    <Typography component="span">{stepInstance.user}</Typography>
                </Box> : null
            }
            {stepInstance && stepInstance.startDate ?
                <Box sx={{ mb: 2, paddingTop: '0.2ex', marginBottom: 0 }}>
                    <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline-block', minWidth: '8em' }}>Start date: </Typography>
                    <Typography component="span">{stepInstance.startDate.toLocaleString()}</Typography>
                </Box> : null
            }
            {stepInstance && stepInstance.endDate ?
                <Box sx={{ mb: 2, paddingTop: '0.2ex', marginBottom: 0 }}>
                    <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline-block', minWidth: '8em' }}>End date: </Typography>
                    <Typography component="span">{stepInstance.endDate.toLocaleString()}</Typography>
                </Box> : null
            }
        </Box>
    );
}

function SubWorkflowInstance(props) {
    const {
        instance,
        update,
        workflowDef,
        workflowDefList,
        sid,
        stepInstance,
        ...other
    } = props;
    const [activeStep, setActiveStep ] = React.useState(instance ? instance.stepId : -1);
    const [destStepIndex, setDestStepIndex] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverIndex, setPopoverIndex] = React.useState(-1);
    const [viewPdfOpen, setViewPdfOpen] = React.useState(false);
    const [viewPdfs, setViewPdfs] = React.useState([]);
    const [previousDrafts, setPreviousDrafts] = React.useState([]);
    const [selectPdfOpen, setSelectPdfOpen] = React.useState(false);
    const [substeps, setSubsteps] = React.useState([]);
    const [parentStep, setParentStep] = React.useState(null);
    const [levelSteps, setLevelSteps] = React.useState([]);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const desc = '';

    React.useEffect(() => {
        if ( instance ) {
            setActiveStep(instance.stepId);
        }
        if ( workflowDef ) {
            const parentStep = findStep(workflowDef.steps, sid);
            if ( parentStep ) {
                setParentStep(parentStep);
                if ( parentStep.properties && parentStep.properties.substeps ) {
                    let pnum = parentStep.properties.hnum;
                    if (!pnum ) {
                        pnum = `${parentStep.id + 1}`
                    }
                    const lsteps = [];
                    let sindex = 1;
                    parentStep.properties.substeps.forEach( item => {
                        if ( !item.properties ) {
                            item.properties = {};
                        }
                        if ( ! item.sid ) {
                            item.sid = item.id;
                        }
                        if ( ! item.properties.hnum ) {
                            if ( item.name !== 'START' ) {
                                item.properties.hnum = `${pnum}.${sindex}`;
                                sindex++;
                            }
                        }
                        lsteps.push(item.sid);
                    });
                    setLevelSteps(lsteps);
                    setSubsteps(parentStep.properties.substeps);
                }
            }
        }
        setDestStepIndex(0);
        setActionButtonDisabled(false);
    },[instance,update,sid]);

    React.useEffect(() => {
        changeGotoStepIndex(0, stepInstance);
    }, [stepInstance]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    const findStep = (steps,sid) => {
        for(let i=0; i<steps.length; i++) {
            const s = steps[i];
            if ( s.sid === sid ) {
                return s;
            }
            if ( s.properties && s.properties.substeps ) {
                const ss = findStep(s.properties.substeps, sid);
                if ( ss ) {
                    return ss;
                }
            }
        }
        return undefined;
    };

    const findTargetStep = (steps, sid) => {
        let target = undefined;
        for(const item of steps) {
            if ( sid === item.sid) {
                target = item;
                break;
            }
            if ( item.name === 'CALL') {
                if ( item.properties ) {
                    const substeps = item.properties.substeps;
                    if ( substeps ) {
                        target = findTargetStep(substeps,sid);
                        if ( target ) {
                            break;
                        }
                    }
                }
            }
        }
        return target;
    }


    const handleNext = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // console.log('handleNext1: ' + JSON.stringify(UserInfo) + ' : iuser: ' + JSON.stringify(stepInstance));
        if (UserInfo && UserInfo.info && stepInstance) {
            // console.log('cuser: ' + UserInfo.info.name + ' : iuser: ' + stepInstance.user);
            setActionButtonDisabled(true);
            if (UserInfo.info.name === stepInstance.user) {
                netGet('/api/workflow/step/complete/' + instance.id)
                    .then(response => {
                        if (!response.ok) {
                            console.log('Could not complete step: error = ' + response.status);
                        }
                    });
            } else {
                netGet('/api/workflow/step/claim/' + instance.id)
                    .then(response => {
                        if (!response.ok) {
                            console.log('Could not claim step: error = ' + response.status);
                        }
                    });
            }
        }
    };

    const handleNextCallback = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (UserInfo.info.name === stepInstance.user) {
            netGet('/api/workflow/step/complete/' + instance.id)
                .then(response => {
                    if (!response.ok) {
                        console.log('Could not complete step: error = ' + response.status);
                    }
                });
        } else {
            netGet('/api/workflow/step/claim/' + instance.id)
                .then(response => {
                    if (!response.ok) {
                        console.log('Could not claim step: error = ' + response.status);
                    }
                });
        }
    };

    const debouncedHandleNext = useCallback(
        debounce(handleNext, DEBOUNCE_TIME)
      , [stepInstance]);

    const handlePopoverClick = (event, index) => {
        if ( index < activeStep ) {
            setAnchorEl(event.currentTarget);
            setPopoverIndex(index);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverIndex(-1);
    };

    const openPopOver = Boolean(anchorEl);

    const handleGotoStepChange = (event, stepInst) => {
        const gotoIndex = event.target.value;
        stepInst.properties.gotoStepIndex = gotoIndex;
        setDestStepIndex(gotoIndex);
        netPost('/api/workflow/step/update' , stepInst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete step: error = ' + response.status);
                    
                }
            });

    };

    const changeGotoStepIndex = (gotoIndex, stepInst) => {
        if( !stepInst ) {
            return;
        }
        if ( ! stepInst.properties ) {
            stepInst.properties = {};
        }
        stepInst.properties.gotoStepIndex = gotoIndex;
        setDestStepIndex(gotoIndex);
        netPost('/api/workflow/step/update' , stepInst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete step: error = ' + response.status);
                    
                }
            });

    }

    const resolveStepDestinationLabel = (route, wdef, wlist) => {
        switch (route.name) {
            case ('STEP'):
                if ( route.targetSid ) {
                    let tstep = findTargetStep(wdef.steps, route.targetSid);
                    if ( tstep && tstep.name == 'END') {
                        const testep = findTargetStep(wdef.steps, route.targetSid + 1);
                        if ( testep ) {
                            tstep = testep;
                        }
                    }
                    if ( tstep && tstep.properties) {
                        const p = tstep.properties;
                        route.label = `STEP ${p.hnum}: ${tstep.label}`;
                    }
                } else {
                    route.label = `STEP ${route.targetId+1}${route.targetId >=0 ? ': ' + wdef.steps[route.targetId].label : ''}`;
                }
                break;
            case ('WORKFLOW'):
                let dest = 'WORKFLOW:  ';
                if ( route.targetWorkflowId >= 0 ) {
                    const w = wlist.find( item => item.id === route.targetWorkflowId );
                    if ( w ) {
                        dest = dest + w.label ;
                    }
                    if ( route.targetId >= 0 && Array.isArray(w.steps)) {
                        dest = dest + ' / ' + w.steps[route.targetId].label;
                    }
                } 
                route.label = dest;
                break;
            default:
                route.label = 'NEXT STEP';
                break;
                
        }
    };


    const getStepRoutes = (stepDef, workflowDef, workflowList) => {
        if ( Array.isArray(stepDef.routes) && stepDef.routes.length > 0 ) {
            console.log('Step routes: ' + JSON.stringify(stepDef.routes));
            let routes = [...stepDef.routes];
            if ( workflowDef && workflowList ) {
                routes.forEach( item => resolveStepDestinationLabel(item, workflowDef, workflowList));
            }
            return routes;
        }
        // default is NEXT
        return [{ id: 0, name: 'NEXT', label: 'NEXT STEP'}];
    };

    const getButtonDisabled = () => {
        if ( ! stepInstance ) {
            return true;
        }
        if ( actionButtonDisabled ) {
            return true;
        }
        // const stepDef = workflowDef.steps[instance.stepId];
        const stepDef = findStep(workflowDef.steps, instance.stepId);
        // console.log('Current step def:' + workflowDef.id + ' : ' +  JSON.stringify(stepDef));
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if ( stepInstance.properties.stepUsers.includes(UserInfo.info.name) ) {
                return false;
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
                } else {
                    return false;
                }
            } else if ( stepInstance.properties.stepUsers.length > 0 ) {
                return true;
            } else if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                return !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
            } else {
                return false;
            }
        } else {
            // console.log("Active step: I don't have users");
            if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                const ican = !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
                // console.log('I can claim: ' + ican);
                return ican;
            } else {
                return false;
            }
        }
        return true;
        // return stepInstance && UserInfo.info.name === stepInstance.user ? false : !(stepInstance && !stepInstance.user);
    };

    const getButtonLabel = () => {
        if ( ! stepInstance ) {
            return 'Claim';
        }
        const stepDef = findStep(workflowDef.steps, instance.stepId); // workflowDef.steps[instance.stepId];
        // console.log('Current step:' + stepDef.name);
        // console.log('Instance: ' + JSON.stringify(stepInstance));
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if ( stepInstance.properties.stepUsers.includes(UserInfo.info.name) ) {
                return 'Complete';
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return stepDef.roles.some((role) => UserInfo.info.roles.includes(role)) ? 'Claim' : 'Claim'; // Claim label anyway since it will be disabled if not relevant
                }
            }
        }
        return 'Claim';
    };

    const getActionButton = () => {
        let button;
        if ( stepInstance && (UserInfo.info.name === stepInstance.user || (Array.isArray(stepInstance.properties.stepUsers) && stepInstance.properties.stepUsers.includes(UserInfo.info.name)))) {
            switch ( stepInstance.properties.name ) {
                case 'EDIT_DOC':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleOpenDoc(stepInstance)}
                            sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                            disabled={getButtonDisabled()}
                        >
                            Open Document
                        </Button>
                    );
                    break;

                case 'CHECK_PDF_DRAFT':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleCheckPdfDraft(stepInstance)}
                            sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                            disabled={getButtonDisabled()}
                        >
                            Check PDF Draft
                        </Button>
                    );
                    break;
                case 'SELECT_PDF_DRAFT':
                    button = (
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                onClick={() => handleSelectPdfDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                                disabled={getButtonDisabled()}
                            >
                                Select PDF Draft
                            </Button>
                            { stepInstance.properties.pdfDraftFile && 
                            <Button
                                variant="outlined"
                                onClick={() => handleViewSelectPdfDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                                disabled={getButtonDisabled()}
                            >
                                View PDF Draft
                            </Button>
                            }
                        </React.Fragment>
                    );
                    break;

            }
        }
        return button;
    };

    const canComplete = () => {
        const r = getButtonLabel() === 'Complete' && !getButtonDisabled();
        // console.log('can complete: ' + r);
        return r;
    };

    const handleOpenDoc = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening link ' + info.link);
                // window.open('https://test-2014.uniscope.cloud/ucs/#content/editor', 'UCS');
                window.open(info.link, 'UCS');
            });
    };

    const handleCheckPdfDraft = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening PDF file ' + info.file);
                if ( Array.isArray(info.file) ) {
                    console.log('Opening PDF file array: ' + info.file.length);
                    if ( info.file.length == 1 ) {
                        
                        if (instance.properties.previousDrafts && instance.properties.previousDrafts.length > 0) {
                            const prevDraft = instance.properties.previousDrafts[instance.properties.previousDrafts.length - 1];
                            const ppath = prevDraft[0].path;
                            console.log('Found previous draft = ' + ppath);
                            const fpath = info.file[0].path;
                            if (fpath) {
                                /*
                                const { UI, Core } = pdftronInstance;
                                WorkflowModule.pageIndexChange(1);

                                const { annotationManager } = pdftronInstance.Core;
                                annotationManager.setCurrentUser(UserInfo.info.name);

                                UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                                    Core.getDocumentViewers()[0].loadDocument('/file/' + UserInfo.info.sessionId + ppath); // Load 'pdf_vers1.pdf' on first DocumentViewer
                                    Core.getDocumentViewers()[1].loadDocument('/file/' + UserInfo.info.sessionId + fpath); // Load 'pdf_vers2.pdf' on second DocumentViewer
                                })
                                UI.enableFeatures([UI.Feature.MultiViewerMode]);
                                */
                                const flabel = info.file[0].label;
                                openPdfDocument(fpath, flabel, instance.properties.previousDrafts);
                            }
                        } else {
                            
                            const fpath = info.file[0].path;
                            const flabel = info.file[0].label;
                            if (fpath) {
                                openPdfDocument(fpath, flabel);
                            }
                        }
                    } else if ( info.file.length > 1 ) {
                        setViewPdfs(info.file);
                        setPreviousDrafts(instance.properties.previousDrafts);
                        setViewPdfOpen(true);
                    }
                } else {
                    openPdfDocument(info.file);
                    /*
                    WorkflowModule.pageIndexChange(1);
                    const { annotationManager } = pdftronInstance.Core;
                    annotationManager.setCurrentUser(UserInfo.info.name);
                    pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + info.file);
                    */
                }
            });
        
    };

    const handleSelectPdfDraft = (sinst) => {
        /*
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('PDF Draft folders ' + info.draftFolders);
                // do something
            });
        */
       setSelectPdfOpen(true);
    };

    const saveSelectPdf = (target) => {
        setSelectPdfOpen(false);
        stepInstance.properties['pdfDraftFile'] = target;
        netPost('/api/workflow/step/update', stepInstance)
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete step: error = ' + response.status);
                }
            }).catch ( error => {
                console.log('Could update step' + error);
            });
    };

    const handleViewSelectPdfDraft = (sinst) => {
        if ( stepInstance.properties.pdfDraftFile ) {
            if ( stepInstance.properties.pdfDraftFile.length == 1 ) {
                /*
                if ( instance.properties.previousDrafts && instance.properties.previousDrafts.length > 0) {
                    const prevDraft = instance.properties.previousDrafts[instance.properties.previousDrafts.length-1];
                    const ppath = prevDraft[0].path;
                    console.log('Found previous draft = ' + ppath);
                    const fpath = stepInstance.properties.pdfDraftFile[0].path;
                    if ( ppath && fpath ) {
                        const { UI, Core } =  pdftronInstance;
                        WorkflowModule.pageIndexChange(1);
                        
                        const { annotationManager } = pdftronInstance.Core;
                        annotationManager.setCurrentUser(UserInfo.info.name);
                        
                        UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                            Core.getDocumentViewers()[0].loadDocument('/file/' + UserInfo.info.sessionId + ppath); // Load 'pdf_vers1.pdf' on first DocumentViewer
                            Core.getDocumentViewers()[1].loadDocument('/file/' + UserInfo.info.sessionId + fpath); // Load 'pdf_vers2.pdf' on second DocumentViewer
                        })
                        UI.enableFeatures([UI.Feature.MultiViewerMode]);
                    }
                } else {
                    console.log('Single PDF...');
                    */
                    const fpath = stepInstance.properties.pdfDraftFile[0].path;
                    if (fpath) {
                        /*
                        WorkflowModule.pageIndexChange(1);
                        const { UI, Core } =  pdftronInstance;
                        UI.disableFeatures([UI.Feature.MultiViewerMode]);

                        const { annotationManager } = pdftronInstance.Core;
                        annotationManager.setCurrentUser(UserInfo.info.name);
                        
                        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + fpath);
                        */
                       // openPdfDocument(fpath);
                       const flabel = stepInstance.properties.pdfDraftFile[0].label;
                       // console.log('VIEW: label=' + flabel + ', prev=' + JSON.stringify(instance.properties.previousDrafts));
                       openPdfDocument(fpath, flabel, instance.properties.previousDrafts);
                    }
                //}
            } else if ( stepInstance.properties.pdfDraftFile.length > 1 ) {
                setViewPdfs(stepInstance.properties.pdfDraftFile);
                setPreviousDrafts(instance.properties.previousDrafts);
                // console.log('VIEW:  prev=' + JSON.stringify(instance.properties.previousDrafts));
                setViewPdfOpen(true);
            }
        }
    };

    const getActiveStep = (astep) => {
        if ( levelSteps.includes(astep) ) {
            return astep;
        }
        const stepDef = findTargetStep(workflowDef.steps, astep);
        if ( stepDef && levelSteps.includes(stepDef.sid) ) {
            return stepDef.sid;
        } else {
            console.log('Step not found: ' + astep);
        }
        if ( stepDef && stepDef.pid ) {
            if (levelSteps.includes(stepDef.pid) ) {
                return stepDef.pid;
            } else {
                return getActiveStep(stepDef.pid);
            }
        }
        return astep; // fallback
    };

    const getCurrentStep = (astep) => {
        const cid = getActiveStep(astep);
        console.log('Active step: ' + astep + ' : levelActiveStep = ' + cid);
        return cid;
    };

    return (
        substeps && substeps.length > 0 ? (
            
            <Box sx={{ maxWidth: '100%', padding: '20px', height: '100%', overflow: 'auto' }}>
                <Stepper activeStep={getActiveStep(activeStep)} orientation="vertical">
                    {
                        substeps.map( (step,index) => 
                            { return step.name != 'START' && step.name != 'END' ?
                            <Step key={'WIS' + step.sid} index={step.sid}>
                                <StepLabel icon={step.properties && step.properties.hnum ? step.properties.hnum : `${index+1}`}>
                                    <Chip label={step.label} variant="outlined" clickable={step.sid < activeStep} onClick={(event) => handlePopoverClick(event, step.sid)} />
                                    <Popover
                                        id={openPopOver && popoverIndex === step.sid ? 'WISPO' + step.sid : undefined}
                                        open={openPopOver && popoverIndex === step.sid}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <WorkflowStepInfo def={step} instId={instance.id} stepId={step.sid} history={true} />
                                    </Popover>
                                </StepLabel>
                                <StepContent sx={{paddingBottom: 0}}>
                                    <Typography>{step.description ? step.description : desc }</Typography>
                                    <table style={{padding: 0, borderSpacing: 0, paddingTop: '1ex', paddingBottom: '1ex'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                <Typography component="span" sx={{ fontWeight: 'bold' }}>Stakeholders: </Typography>
                                            </td>
                                            <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                                                <Typography component="span" >{
                                                    stepInstance && stepInstance.properties && Array.isArray(stepInstance.properties.participants) &&
                                                    stepInstance.properties.participants.reduce((total, user, index) => {
                                                        if (index > 0) {
                                                            total = total + ', ';
                                                        }
                                                        total = total + user;
                                                        return total;
                                                    }, '')

                                                }</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            {stepInstance && stepInstance.user ?
                                                <React.Fragment>
                                                    <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                        <Typography component="span" sx={{ fontWeight: 'bold' }}>Assigned to: </Typography>
                                                    </td>
                                                    <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                                                        <Typography component="span">{
                                                            Array.isArray(stepInstance.properties.stepUsers) ?
                                                                stepInstance.properties.stepUsers.reduce((total, user, index) => {
                                                                    if (index > 0) {
                                                                        total = total + ',';
                                                                    }
                                                                    total = total + user;
                                                                    return total;
                                                                }, '')
                                                                : stepInstance.user
                                                        }</Typography>
                                                    </td>
                                                </React.Fragment> :
                                                <td colSpan="2" style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Not Assigned</Typography>
                                                </td>
                                            }
                                        </tr>
                                        {step.properties && step.properties.multiLock &&
                                            <tr>
                                                <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top'}}>
                                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Completed: </Typography>
                                                </td>
                                                <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top'}}>
                                                    <Typography component="span">{
                                                        stepInstance && stepInstance.properties && Array.isArray(stepInstance.properties.completedUsers) &&
                                                        stepInstance.properties.completedUsers.reduce((total, user, index) => {
                                                            if (index > 0) {
                                                                total = total + ', ';
                                                            }
                                                            total = total + user;
                                                            return total;
                                                        }, '')

                                                    }</Typography>
                                                </td>
                                            </tr>
                                        }
                                    
                                        <tr>
                                        { stepInstance && stepInstance.startDate ? 
                                                <React.Fragment>
                                                    <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top'}}>
                                                        <Typography component="span" sx={{ fontWeight: 'bold' }}>Started: </Typography>
                                                    </td>
                                                    <td  style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top'}}>
                                                        <Typography component="span">{stepInstance.startDate.toLocaleString()}</Typography>
                                                    </td>
                                                </React.Fragment> : 
                                                <td  colSpan="2" style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Not Started</Typography>
                                                </td>
                                        }
                                        </tr>
                                        </tbody>
                                    </table>
                                    {stepInstance &&
                                        <React.Fragment>
                                        <Box sx={{ mb: 1, width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 'bold', width: '16em', whiteSpace: 'nowrap' }}>Destination after completion:</Typography>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <Select
                                                    value={destStepIndex}
                                                    onChange={(event) => handleGotoStepChange(event, stepInstance)}
                                                    sx={{ minWidth: '10em', maxWidth: '100%' }}
                                                    MenuProps={MenuProps}
                                                    readOnly={!canComplete() || getStepRoutes(step).length < 2}
                                                    disabled={!canComplete() || getStepRoutes(step).length < 2}
                                                >
                                                    {getStepRoutes(step, workflowDef, workflowDefList).map((w, i) => (
                                                        <MenuItem
                                                            key={'gts' + i}
                                                            value={i}
                                                        >
                                                            {w.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        </React.Fragment>
                                    }
                                    <Box sx={{ mb: 2 }}>

                                        <Button
                                            variant="contained"
                                            onClick={debouncedHandleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled={getButtonDisabled() || actionButtonDisabled}
                                        >
                                            {getButtonLabel()}
                                        </Button>
                                        { getActionButton() }
                                        <CircularProgress
                                            variant="indeterminate"
                                            sx={{
                                                color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                                                animationDuration: '550ms',
                                                [`& .${circularProgressClasses.circle}`]: {
                                                    strokeLinecap: 'round',
                                                },
                                                visibility: (actionButtonDisabled ? 'visible' : 'hidden'),
                                                position: 'relative',
                                                top: 10,
                                                right: 10,
                                                zIndex: 10,
                                                padding: 0,
                                                float: 'right',
                                            }}
                                            size={34}
                                            thickness={4}
                                        />
                                    </Box>
                                </StepContent>
                            </Step> : null
                                }
                        )
                    }
                </Stepper>
                <ChooseViewPdfDialog open={viewPdfOpen} onCancel={() => setViewPdfOpen(false)} files={viewPdfs} previousDrafts={previousDrafts}/>
                <SelectPdfDraftDialog open={selectPdfOpen} onCancel={ () => setSelectPdfOpen(false)} onSave={saveSelectPdf} workflowInstance={instance} stepInstance={stepInstance} />
            </Box>
        ) : null
      );
  
}


function WorkflowInstance(props) {
    const {
        instance,
        update,
        ...other
     } = props;
    const [ workflowDef, setWorkflowDef ] = React.useState(null);
    const [ workflowUpdate, setWorkflowUpdate] = React.useState(0); // increment to update
    const [ activeStep, setActiveStep ] = React.useState(instance ? instance.stepId : -1);
    const [ stepInstance, setStepInstance] = React.useState(null);
    const [ destStepIndex, setDestStepIndex] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverIndex, setPopoverIndex] = React.useState(-1);
    const [viewPdfOpen, setViewPdfOpen] = React.useState(false);
    const [viewPdfs, setViewPdfs] = React.useState([]);
    const [previousDrafts, setPreviousDrafts] = React.useState([]);
    const [selectPdfOpen, setSelectPdfOpen] = React.useState(false);
    const [workflowDefList, setWorkflowDefList] = React.useState([]);
    // steps' SIDs at this level, excluding the nested subworkflows steps
    const [levelSteps, setLevelSteps] = React.useState([]);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);
    const [evaluateFormOpen, setEvaluateFormOpen] = React.useState(false);
    const [formInstance, setFormInstance] = React.useState(null);

    const desc = '';

    React.useEffect(() => {
        if (instance) {
            try {
            // console.log('Fetching workflow definition ' + instance.definitionId + '...');

            netGet('/api/workflow/def/' + instance.definitionId)
                .then(response => response.json())
                .then(data => {
                    // console.log(Array.isArray(data.steps) + ' : ' + data.steps.length);

                    setWorkflowDef(data);
                    // console.log('Active step: ' + instance.stepId);
                    setActiveStep(instance.stepId);
                    if ( Array.isArray(data.steps) ) {
                        let lsteps = [];
                        data.steps.forEach( item => {
                            if ( ! item.sid ) {
                                item.sid = item.id;
                            }
                            if ( ! item.properties ) {
                                item.properties = {};
                            }
                            if ( ! item.properties.hnum ) {
                                item.properties.hnum = `${item.id + 1}`;
                            }
                            lsteps.push(item.sid);
                        });
                        setLevelSteps(lsteps);
                    }

                    netGet('/api/workflow/step/find/' + instance.id + '/' + instance.stepId)
                        .then(response => response.json())
                        .then(data => {
                            let si = { ...data };
                            if (data.startDate) {
                                // console.log('in date [' + typeOf(data.startDate) + '] = ' + data.startDate);
                                si = { ...data, startDate: new Date(data.startDate) };
                            }
                            if ( data.endDate ) {
                                si = { ...data, endDate: new Date(data.endDate) };
                            }
                            setStepInstance(si);
                            setDestStepIndex(getGotoStepIndex(si));
                            // console.log('Type of startDate: ' + typeOf(si.startDate) + ' = ' + si.startDate);
                        })
                        .catch( error => {
                            console.log('Error fetching step: ' + error);
                        });
                    
                });
            } catch (err) {
                console.log('Error processing instance: ' + err + ' < ' + instance);
            }

            // get also the full list in case we need to resolve jump to other workflows
            loadWorkflowDefinitions();
        } else {
            console.log('Workflow instance undefined!!!');
        }
        setActionButtonDisabled(false);
      }, [workflowUpdate, instance, update]); // add dependency on props to call this for each instance

      const loadWorkflowDefinitions = () => {
        netGet('/api/workflow/def/list?categ=DEF')
            .then(response => response.json())
            .then(defs => {
                if ( defs && Array.isArray(defs) ) {
                    // console.log('Workflow Definitions: ' + defs.length);
                    setWorkflowDefList(defs);
                }

            }).catch ( error => {
                console.log('Error fetching workflow definitions: ' + error);
            });
    };

    const handleNext = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // console.log('handleNext: ' + JSON.stringify(UserInfo) + ' : iuser: ' + JSON.stringify(stepInstance));
        if (UserInfo && UserInfo.info && stepInstance ) {
            // console.log('cuser: ' + UserInfo.info.name + ' : iuser: ' + stepInstance.user);
            setActionButtonDisabled(true);
            if (UserInfo.info.name === stepInstance.user) {
                netGet('/api/workflow/step/complete/' + instance.id)
                    .then(response => {
                        if (!response.ok) {
                            console.log('Could not complete step: error = ' + response.status);
                        }
                    });
            } else {
                netGet('/api/workflow/step/claim/' + instance.id)
                    .then(response => {
                        if (!response.ok) {
                            console.log('Could not claim step: error = ' + response.status);
                        }
                    });
            }
        }
    };

    const handleNextCallback = (currentUser, instanceUser) => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (currentUser === instanceUser) {
            netGet('/api/workflow/step/complete/' + instance.id)
                .then(response => {
                    if (!response.ok) {
                        console.log('Could not complete step: error = ' + response.status);
                    }
                });
        } else {
            netGet('/api/workflow/step/claim/' + instance.id)
                .then(response => {
                    if (!response.ok) {
                        console.log('Could not claim step: error = ' + response.status);
                    }
                });
        }
    };

    const debouncedHandleNext = useCallback(
        debounce(handleNext, DEBOUNCE_TIME)
      , [stepInstance]);
    
    
    const handleBack = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePopoverClick = (event, index) => {
        if ( index < activeStep ) {
            setAnchorEl(event.currentTarget);
            setPopoverIndex(index);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverIndex(-1);
    };

    const openPopOver = Boolean(anchorEl);

    const handleOpenDoc = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening link ' + info.link);
                // window.open('https://test-2014.uniscope.cloud/ucs/#content/editor', 'UCS');
                window.open(info.link, 'UCS');
            });
    };

    const handleCheckPdfDraft = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening PDF file ' + info.file);
                if ( Array.isArray(info.file) ) {
                    console.log('Opening PDF file array: ' + info.file.length);
                    if ( info.file.length == 1 ) {
                        
                        if (instance.properties.previousDrafts && instance.properties.previousDrafts.length > 0) {
                            const prevDraft = instance.properties.previousDrafts[instance.properties.previousDrafts.length - 1];
                            const ppath = prevDraft[0].path;
                            console.log('Found previous draft = ' + ppath);
                            const fpath = info.file[0].path;
                            if (fpath) {
                                /*
                                const { UI, Core } = pdftronInstance;
                                WorkflowModule.pageIndexChange(1);

                                const { annotationManager } = pdftronInstance.Core;
                                annotationManager.setCurrentUser(UserInfo.info.name);

                                UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                                    Core.getDocumentViewers()[0].loadDocument('/file/' + UserInfo.info.sessionId + ppath); // Load 'pdf_vers1.pdf' on first DocumentViewer
                                    Core.getDocumentViewers()[1].loadDocument('/file/' + UserInfo.info.sessionId + fpath); // Load 'pdf_vers2.pdf' on second DocumentViewer
                                })
                                UI.enableFeatures([UI.Feature.MultiViewerMode]);
                                */
                                const flabel = info.file[0].label;
                                openPdfDocument(fpath, flabel, instance.properties.previousDrafts);
                            }
                        } else {
                            
                            const fpath = info.file[0].path;
                            const flabel = info.file[0].label;
                            if (fpath) {
                                openPdfDocument(fpath, flabel);
                            }
                        }
                    } else if ( info.file.length > 1 ) {
                        setViewPdfs(info.file);
                        setPreviousDrafts(instance.properties.previousDrafts);
                        setViewPdfOpen(true);
                    }
                } else {
                    openPdfDocument(info.file);
                    /*
                    WorkflowModule.pageIndexChange(1);
                    const { annotationManager } = pdftronInstance.Core;
                    annotationManager.setCurrentUser(UserInfo.info.name);
                    pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + info.file);
                    */
                }
            });
        
    };

    const handleSelectPdfDraft = (sinst) => {
        /*
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('PDF Draft folders ' + info.draftFolders);
                // do something
            });
        */
       setSelectPdfOpen(true);
    };

    const saveSelectPdf = (target) => {
        setSelectPdfOpen(false);
        stepInstance.properties['pdfDraftFile'] = target;
        netPost('/api/workflow/step/update', stepInstance)
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete step: error = ' + response.status);
                }
            }).catch ( error => {
                console.log('Could update step' + error);
            });
    };

    const handleViewSelectPdfDraft = (sinst) => {
        if ( stepInstance.properties.pdfDraftFile ) {
            if ( stepInstance.properties.pdfDraftFile.length == 1 ) {
                /*
                if ( instance.properties.previousDrafts && instance.properties.previousDrafts.length > 0) {
                    const prevDraft = instance.properties.previousDrafts[instance.properties.previousDrafts.length-1];
                    const ppath = prevDraft[0].path;
                    console.log('Found previous draft = ' + ppath);
                    const fpath = stepInstance.properties.pdfDraftFile[0].path;
                    if ( ppath && fpath ) {
                        const { UI, Core } =  pdftronInstance;
                        WorkflowModule.pageIndexChange(1);
                        
                        const { annotationManager } = pdftronInstance.Core;
                        annotationManager.setCurrentUser(UserInfo.info.name);
                        
                        UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                            Core.getDocumentViewers()[0].loadDocument('/file/' + UserInfo.info.sessionId + ppath); // Load 'pdf_vers1.pdf' on first DocumentViewer
                            Core.getDocumentViewers()[1].loadDocument('/file/' + UserInfo.info.sessionId + fpath); // Load 'pdf_vers2.pdf' on second DocumentViewer
                        })
                        UI.enableFeatures([UI.Feature.MultiViewerMode]);
                    }
                } else {
                    console.log('Single PDF...');
                    */
                    const fpath = stepInstance.properties.pdfDraftFile[0].path;
                    if (fpath) {
                        /*
                        WorkflowModule.pageIndexChange(1);
                        const { UI, Core } =  pdftronInstance;
                        UI.disableFeatures([UI.Feature.MultiViewerMode]);

                        const { annotationManager } = pdftronInstance.Core;
                        annotationManager.setCurrentUser(UserInfo.info.name);
                        
                        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + fpath);
                        */
                       // openPdfDocument(fpath);
                       const flabel = stepInstance.properties.pdfDraftFile[0].label;
                       // console.log('VIEW: label=' + flabel + ', prev=' + JSON.stringify(instance.properties.previousDrafts));
                       openPdfDocument(fpath, flabel, instance.properties.previousDrafts);
                    }
                //}
            } else if ( stepInstance.properties.pdfDraftFile.length > 1 ) {
                setViewPdfs(stepInstance.properties.pdfDraftFile);
                setPreviousDrafts(instance.properties.previousDrafts);
                // console.log('VIEW:  prev=' + JSON.stringify(instance.properties.previousDrafts));
                setViewPdfOpen(true);
            }
        }
    };

    const getButtonRolesDisabled = () => {
        if ( ! stepInstance ) {
            return true;
        }
        const stepDef = workflowDef.steps[instance.stepId];
        // console.log('Current step def:' + workflowDef.id + ' : ' +  JSON.stringify(stepDef));
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if ( stepInstance.properties.stepUsers.includes(UserInfo.info.name) ) {
                return false;
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
                } else {
                    return false;
                }
            } else if ( stepInstance.properties.stepUsers.length > 0 ) {
                return true;
            } else if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                return !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
            } else {
                return false;
            }
        } else {
            // console.log("Active step: I don't have users");
            if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                const ican = !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
                // console.log('I can claim: ' + ican);
                return ican;
            } else {
                return false;
            }
        }
        return true;
        // return stepInstance && UserInfo.info.name === stepInstance.user ? false : !(stepInstance && !stepInstance.user);
    };

    const getButtonDisabled = () => {
        let rd = getButtonRolesDisabled();
        // console.log('STEP TYPE: ' + stepInstance?.properties?.name);
        if ( stepInstance?.properties?.name === 'FORM' && getButtonLabel() === 'Complete' ) {
            if ( Array.isArray(stepInstance.properties?.form?.fields) ) {
                const fields = stepInstance.properties.form.fields;
                for(let i=0; i<fields.length; i++) {
                    const field = fields[i];
                    if ( field.required ) {
                        if ( typeof field.value === 'undefined' ) {
                            return true;
                        }
                        if ( field.value === '' ) {
                            return true;
                        }
                    }
                }
            }
        }

        return rd;
    };

    const getButtonLabel = () => {
        if ( ! stepInstance ) {
            return 'Claim';
        }
        const stepDef = workflowDef.steps[instance.stepId];
        // console.log('Current step:' + stepDef.name);
        // console.log('Instance: ' + JSON.stringify(stepInstance));
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if ( stepInstance.properties.stepUsers.includes(UserInfo.info.name) ) {
                return 'Complete';
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return stepDef.roles.some((role) => UserInfo.info.roles.includes(role)) ? 'Claim' : 'Claim'; // Claim label anyway since it will be disabled if not relevant
                }
            }
        }
        return 'Claim';
    };

    const canComplete = () => {
        const r = getButtonLabel() === 'Complete' && !getButtonDisabled();
        // console.log('can complete: ' + r);
        return r;
    };

    const handleForm = (theStepInstance) => {
        const form = theStepInstance.properties?.form;
        if ( form ) {
            setFormInstance(form);
            setEvaluateFormOpen(true);
        }

    };

    const evaluateFormSave = (formValues) => {
        setEvaluateFormOpen(false);
        if ( stepInstance ) {
            const form = stepInstance.properties?.form;
            if (form && Array.isArray(form.fields)) {
                form.fields.forEach( (field,index) => {
                    field.value = formValues[field.name];
                });
                updateStepInstanceForm(stepInstance);
            }
        }
    };

    const updateStepInstanceForm = (stepInst) => {
        netPost('/api/workflow/step/update' , stepInst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step form: error = ' + response.status);
                    
                }
            });

    };



    const getActionButton = () => {
        let button;
        if ( stepInstance && (UserInfo.info.name === stepInstance.user || (Array.isArray(stepInstance.properties.stepUsers) && stepInstance.properties.stepUsers.includes(UserInfo.info.name)))) {
            switch ( stepInstance.properties.name ) {
                case 'EDIT_DOC':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleOpenDoc(stepInstance)}
                            sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                            disabled={getButtonDisabled()}
                        >
                            Open Document
                        </Button>
                    );
                    break;

                case 'CHECK_PDF_DRAFT':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleCheckPdfDraft(stepInstance)}
                            sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                            disabled={getButtonDisabled()}
                        >
                            Check PDF Draft
                        </Button>
                    );
                    break;
                case 'SELECT_PDF_DRAFT':
                    button = (
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                onClick={() => handleSelectPdfDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                                disabled={getButtonDisabled()}
                            >
                                Select PDF Draft
                            </Button>
                            { stepInstance.properties.pdfDraftFile && 
                            <Button
                                variant="outlined"
                                onClick={() => handleViewSelectPdfDraft(stepInstance)}
                                sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                                disabled={getButtonDisabled()}
                            >
                                View PDF Draft
                            </Button>
                            }
                        </React.Fragment>
                    );
                    break;
                case 'FORM':
                    button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleForm(stepInstance)}
                            sx={{ mt: 1, mr: 1, marginLeft: '2em' }}
                            disabled={getButtonRolesDisabled()}
                        >
                            {stepInstance.properties?.form ? stepInstance.properties.form.label : 'Fill Form'}
                        </Button>
                    );
                    break;

            }
        }
        return button;
    };

    const findTargetStep = (steps, sid) => {
        let target = undefined;
        for(const item of steps) {
            if ( sid === item.sid) {
                target = item;
                break;
            }
            if ( item.name === 'CALL') {
                if ( item.properties ) {
                    const substeps = item.properties.substeps;
                    if ( substeps ) {
                        target = findTargetStep(substeps, sid);
                        if ( target ) {
                            break;
                        }
                    }
                }
            }
        }
        return target;
    }

    const resolveStepDestinationLabel = (route, wdef, wlist) => {
        switch (route.name) {
            case ('STEP'):
                if ( route.targetSid ) {
                    const tstep = findTargetStep(wdef.steps, route.targetSid);
                    if ( tstep && tstep.properties) {
                        const p = tstep.properties;
                        route.label = `STEP ${p.hnum}: ${tstep.label}`;
                    }
                } else {
                    route.label = `STEP ${route.targetId+1}${route.targetId >=0 ? ': ' + wdef.steps[route.targetId].label : ''}`;
                }
                break;
            case ('WORKFLOW'):
                let dest = 'WORKFLOW:  ';
                if ( route.targetWorkflowId >= 0 ) {
                    const w = wlist.find( item => item.id === route.targetWorkflowId );
                    if ( w ) {
                        dest = dest + w.label ;
                    }
                    if ( route.targetId >= 0 && Array.isArray(w.steps)) {
                        dest = dest + ' / ' + w.steps[route.targetId].label;
                    }
                } 
                route.label = dest;
                break;
            default:
                route.label = 'NEXT STEP';
                break;
                
        }
    };

    const getGotoStepIndex = (stepInst) => {
        if ( stepInst && stepInst.properties && stepInst.properties.gotoStepIndex ) {
            return stepInst.properties.gotoStepIndex;
        }
        return 0;
    };

    const handleGotoStepChange = (event, stepInst) => {
        const gotoIndex = event.target.value;
        stepInst.properties.gotoStepIndex = gotoIndex;
        setDestStepIndex(gotoIndex);
        netPost('/api/workflow/step/update' , stepInst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete step: error = ' + response.status);
                    
                }
            });

    };

    const getStepRoutes = (stepDef, workflowDef, workflowList) => {
        if ( Array.isArray(stepDef.routes) && stepDef.routes.length > 0 ) {
            // console.log('Step routes: ' + JSON.stringify(stepDef.routes));
            let routes = [...stepDef.routes];
            if ( workflowDef && workflowList ) {
                routes.forEach( item => resolveStepDestinationLabel(item, workflowDef, workflowList));
            }
            return routes;
        }
        // default is NEXT
        return [{ id: 0, name: 'NEXT', label: 'NEXT STEP'}];
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    const getActiveStep = (astep) => {
        if ( levelSteps.includes(astep) ) {
            return astep;
        }
        const stepDef = findTargetStep(workflowDef.steps, astep);
        if ( stepDef && levelSteps.includes(stepDef.sid) ) {
            return stepDef.sid;
        } else {
            console.log('Step not found at this level: ' + astep);
        }
        if ( stepDef && stepDef.pid ) {
            if (levelSteps.includes(stepDef.pid) ) {
                return stepDef.pid;
            } else {
                return getActiveStep(stepDef.pid);
            }
        }
        return astep;
    };

    const getInstanceName = () => {
        if ( instance ) {
            if ( instance.properties && instance.properties.instanceName ) {
                return instance.properties.instanceName;
            }
            return instance.description;
        }
        return '';
    };

    const getInstanceDescription = () => {
        if ( instance ) {
            if ( instance.properties && instance.properties.instanceName ) {
                return instance.description;
            }
        }
        return '';
    };

    const getCurrentStep = (astep) => {
        const cid = getActiveStep(astep);
        // console.log('Active step: ' + astep + ' : levelActiveStep = ' + cid);
        return cid;
    };

    return (
        workflowDef && Array.isArray(workflowDef.steps) && workflowDef.steps.length > 0 ? (
            
            <Box sx={{ maxWidth: '100%', padding: '20px', height: '100%', overflow: 'auto' }}>
                <Typography component="div" variant="h5" sx={{paddingBottom: '1ex'}}>{getInstanceName()}</Typography>
                <Typography sx={{paddingBottom: '2ex'}}>{getInstanceDescription()}</Typography>
                <Stepper activeStep={getCurrentStep(activeStep)} orientation="vertical">
                    {
                        workflowDef.steps.map( (step,index) => (
                            <Step key={'WIS' + step.sid} index={step.sid}>
                                <StepLabel icon={step.properties && step.properties.hnum ? step.properties.hnum : `${index+1}`}>
                                    <Chip label={step.label} variant="outlined" clickable={step.sid < activeStep} onClick={(event) => handlePopoverClick(event, step.sid)} />
                                    <Popover
                                        id={openPopOver && popoverIndex === step.sid ? 'WISPO' + step.id : undefined}
                                        open={openPopOver && popoverIndex === step.sid}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <WorkflowStepInfo def={step} instId={instance.id} stepId={step.sid} history={true} />
                                    </Popover>
                                </StepLabel>
                                <StepContent>
                                    <Typography>{step.description ? step.description : desc }</Typography>
                                    { activeStep === step.sid &&
                                    <React.Fragment>
                                    <table style={{padding: 0, borderSpacing: 0, paddingTop: '1ex', paddingBottom: '1ex'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                <Typography component="span" sx={{ fontWeight: 'bold' }}>Stakeholders: </Typography>
                                            </td>
                                            <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                                                <Typography component="span" >{
                                                    stepInstance && stepInstance.properties && Array.isArray(stepInstance.properties.participants) &&
                                                    stepInstance.properties.participants.reduce((total, user, index) => {
                                                        if (index > 0) {
                                                            total = total + ', ';
                                                        }
                                                        total = total + user;
                                                        return total;
                                                    }, '')

                                                }</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            {stepInstance && stepInstance.user ?
                                                <React.Fragment>
                                                    <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                        <Typography component="span" sx={{ fontWeight: 'bold' }}>Assigned to: </Typography>
                                                    </td>
                                                    <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top' }}>
                                                        <Typography component="span">{
                                                            Array.isArray(stepInstance.properties.stepUsers) ?
                                                                stepInstance.properties.stepUsers.reduce((total, user, index) => {
                                                                    if (index > 0) {
                                                                        total = total + ',';
                                                                    }
                                                                    total = total + user;
                                                                    return total;
                                                                }, '')
                                                                : stepInstance.user
                                                        }</Typography>
                                                    </td>
                                                </React.Fragment> :
                                                <td colSpan="2" style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Not Assigned</Typography>
                                                </td>
                                            }
                                        </tr>
                                        {step.properties && step.properties.multiLock &&
                                            <tr>
                                                <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top'}}>
                                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Completed: </Typography>
                                                </td>
                                                <td style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top'}}>
                                                    <Typography component="span">{
                                                        stepInstance && stepInstance.properties && Array.isArray(stepInstance.properties.completedUsers) &&
                                                        stepInstance.properties.completedUsers.reduce((total, user, index) => {
                                                            if (index > 0) {
                                                                total = total + ', ';
                                                            }
                                                            total = total + user;
                                                            return total;
                                                        }, '')

                                                    }</Typography>
                                                </td>
                                            </tr>
                                        }
                                    
                                        <tr>
                                        { stepInstance && stepInstance.startDate ? 
                                                <React.Fragment>
                                                    <td style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top'}}>
                                                        <Typography component="span" sx={{ fontWeight: 'bold' }}>Started: </Typography>
                                                    </td>
                                                    <td  style={{ padding: '0.7ex', paddingLeft: '0.3ex', verticalAlign: 'top'}}>
                                                        <Typography component="span">{stepInstance.startDate.toLocaleString()}</Typography>
                                                    </td>
                                                </React.Fragment> : 
                                                <td  colSpan="2" style={{ padding: '0.7ex', paddingLeft: 0, verticalAlign: 'top' }}>
                                                    <Typography component="span" sx={{ fontWeight: 'bold' }}>Not Started</Typography>
                                                </td>
                                        }
                                        </tr>
                                        </tbody>
                                    </table>
                                    {stepInstance &&
                                                <React.Fragment>
                                                    <Box sx={{ mb: 1, width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '16em', whiteSpace: 'nowrap' }}>Destination after completion:</Typography>
                                                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                            <Select
                                                                value={destStepIndex}
                                                                onChange={(event) => handleGotoStepChange(event, stepInstance)}
                                                                sx={{ minWidth: '10em', maxWidth: '100%' }}
                                                                MenuProps={MenuProps}
                                                                readOnly={!canComplete() || getStepRoutes(step).length < 2}
                                                                disabled={!canComplete() || getStepRoutes(step).length < 2}
                                                            >
                                                                {getStepRoutes(step, workflowDef, workflowDefList).map((w, i) => (
                                                                    <MenuItem
                                                                        key={'gts' + i}
                                                                        value={i}
                                                                    >
                                                                        {w.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                            <Box sx={{ mb: 2 }}>

                                                <Button
                                                    variant="contained"
                                                    onClick={debouncedHandleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                    disabled={getButtonDisabled()}
                                                >
                                                    {getButtonLabel()}
                                                </Button>
                                                {getActionButton()}
                                                <CircularProgress
                                                    variant="indeterminate"
                                                    sx={{
                                                        color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                                                        animationDuration: '550ms',
                                                        [`& .${circularProgressClasses.circle}`]: {
                                                            strokeLinecap: 'round',
                                                        },
                                                        visibility: (actionButtonDisabled ? 'visible' : 'hidden'),
                                                        position: 'relative',
                                                        top: 10,
                                                        right: 10,
                                                        zIndex: 10,
                                                        padding: 0,
                                                        float: 'right'
                                                    }}
                                                    size={34}
                                                    thickness={4}
                                                />
                                            </Box>
                                        </React.Fragment>
                                    }
                                    { step.name === 'CALL' &&
                                        <SubWorkflowInstance workflowDef={workflowDef} instance={instance} workflowDefList={workflowDefList}
                                            sid={step.sid} stepInstance={stepInstance}/>
                                    }
                                </StepContent>
                            </Step>
                        ))
                    }
                </Stepper>
                <ChooseViewPdfDialog open={viewPdfOpen} onCancel={() => setViewPdfOpen(false)} files={viewPdfs} previousDrafts={previousDrafts}/>
                <SelectPdfDraftDialog open={selectPdfOpen} onCancel={ () => setSelectPdfOpen(false)} onSave={saveSelectPdf} workflowInstance={instance} stepInstance={stepInstance} />
                <EvaluateFormDialog open={evaluateFormOpen} onCancel={ () => setEvaluateFormOpen(false)} onSave={evaluateFormSave} form={formInstance} />
            </Box>
        ) : null
      );

}

function WorkflowList(props) {
    const {
        workflowList,
        selected,
        onClick: itemClickHandler,
        keyPrefix,
        ...other
    } = props;
     
    const handleItemClick = (index) => {
        if ( itemClickHandler ) {
            itemClickHandler(index);
        }
    };

    const lockIconColor = (index) => {
        let item = workflowList[index];
        if ( item.stepUsers ) {
            if ( Array.isArray(item.stepUsers) ) {
                if ( item.stepUsers.includes(UserInfo.info.name) ) {
                    return 'green';
                } else if ( item.stepUsers.length > 0 ) {
                    return '#cc0000';
                }
            }
        }
        return 'transparent';
    };

    const lockIconLabel = (index) => {
        let item = workflowList[index];
        if ( item.stepUsers ) {
            if ( Array.isArray(item.stepUsers) && item.stepUsers.length > 0 ) {
                return 'Assigned to ' + item.stepUsers[0];
            }
        }
        return '';
    };

    const getInstanceName = (wf) => {
        if ( wf ) {
            if ( wf.properties && wf.properties.instanceName ) {
                return wf.properties.instanceName;
            }
            return wf.description;
        }
        return '';
    }


    return (
        workflowList && Array.isArray(workflowList) && workflowList.length > 0 ? (
            <List sx={{padding: '4px', paddingLeft: '40px', paddingTop: 0 }}>
                {
                    workflowList.map( (witem, index) => (
                        <ListItem key={keyPrefix + witem.id} selected={selected === index} sx={{borderRadius: 1, padding: '8px', marginLeft: '8px', width: 'calc(100% - 8px)'}}> 
                            <Card sx={{ minWidth: '10px', padding: 0, width: '100%'}}>
                                <CardActionArea onClick={() => handleItemClick(index)}>
                                    <CardContent sx={{ padding: '8px', position: 'relative' }}>
                                        <Box sx={{float: 'right', color: lockIconColor(index)}}>
                                            <Tooltip title={lockIconLabel(index)}>
                                                <LockIcon fontSize="18px"/>
                                            </Tooltip>
                                        </Box>
                                        <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                                        {witem.definitionLabel}
                                        </Typography>
                                        <Typography component="div" sx={{ fontSize: 16 }}>
                                            {getInstanceName(witem)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </ListItem>
                    ))
                }
            </List>
        ) : null
    );
}


function WorkflowMenu(props) {

    const {
        reload,
        ...other
    } = props;

    const [myWorkflowList, setMyWorkflowList] = React.useState([]);
    const [myWorkflowIndex, setMyWorkflowIndex] = React.useState(-1);
    const [activeWorkflowList, setActiveWorkflowList] = React.useState([]);
    const [activeWorkflowIndex, setActiveWorkflowIndex] = React.useState(-1);
    const [workflowUpdate, setWorkflowUpdate ] = React.useState(0);
    const [instanceUpdate, setInstanceUpdate ] = React.useState(0);
    const [menuIcon, setMenuIcon] = React.useState('app');

    const theme = useTheme();
    const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const updateInstances = () => {
        // console.log('Fetching workflow instances... ' + reload);

        netGet('/api/workflow/inst/find/applicable')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Status: ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                // console.log(Array.isArray(data) + ' : ' + data.length);
                let activeIndex = activeWorkflowIndex;
                let activeId = -1;
                if ( activeWorkflowIndex >=0 ) {
                    activeId = activeWorkflowList[activeWorkflowIndex].id;
                }

                if (data && Array.isArray(data.active)) {
                    setActiveWorkflowList(data.active);
                    // console.log('Active workflow list size: ' + data.active.length);
                    if (activeWorkflowIndex >= 0) {
                        activeIndex = data.active.findIndex((value, index, array) => {
                            return activeId === value.id;
                        });
                    }
                } else {
                    setActiveWorkflowList([]);
                    activeIndex = -1;
                }
                

                let myIndex = myWorkflowIndex;
                let myId = -1;
                if ( myWorkflowIndex >= 0 ) {
                    myId = myWorkflowList[myWorkflowIndex].id;
                }
                if (data && Array.isArray(data.mytasks)) {
                    setMyWorkflowList(data.mytasks);
                    if (myWorkflowIndex >= 0) {
                        myIndex = data.mytasks.findIndex((value, index, array) => {
                            return myId === value.id;
                        });
                        if ( myIndex < 0 && Array.isArray(data.active) ) {
                            // selected workflow is no longer in my tasks
                            // but it should be now in active workflows
                            // so try to find it there
                            activeIndex = data.active.findIndex((value, index, array) => {
                                return myId === value.id;
                            });
                        }
                    }
                } else {
                    setMyWorkflowList([]);
                    myIndex = -1;
                }
                // console.log('myIndex=' + myIndex + ', activeIndex=' + activeIndex + ', activeId=' + activeId );
                if ( myIndex < 0 && activeIndex < 0 && activeId >= 0 && Array.isArray(data.mytasks) ) {
                    // selected workflow is no longer in active workflows
                    // but it should be now in my tasks, so try to find it there
                    myIndex = data.mytasks.findIndex( (value, index, array) => {
                        return activeId === value.id;
                    });
                }
                if ( activeIndex !== activeWorkflowIndex ) {
                    setActiveWorkflowIndex(activeIndex);
                }
                if ( myIndex !== myWorkflowIndex ) {
                    setMyWorkflowIndex(myIndex);
                }
                // console.log('instanceUpdate = ' + instanceUpdate);
                setInstanceUpdate(instanceUpdate => instanceUpdate + 1);
                // console.log('instanceUpdate = ' + instanceUpdate);
                let i = instanceUpdate + 1;
                if (myIndex >= 0) {
                    // console.log('Setting myWorkflow...');
                    WorkflowModule.pageChange(<WorkflowInstance instance={data.mytasks[myIndex]} update={i} />);
                } else if (activeIndex >= 0) {
                    // console.log('Setting activeWorkflow...');
                    WorkflowModule.pageChange(<WorkflowInstance instance={data.active[activeIndex]} update={i} />);
                } else {
                    // console.log('Setting no workflow...');
                    WorkflowModule.pageChange(<div />);
                }

            }).catch( error => {
                console.log('Error: ' + error);
            });
    };

    React.useEffect(() => {
        // console.log('Updating workflow menu...');
        updateInstances();
    }, [workflowUpdate, reload]);

    /*
    React.useEffect(() => {
        registerInitHook('WorkflowMenu', () => {
            wreload = wreload + 1;
            const nupt = workflowUpdate + 1;
            console.log('Changing workflowUpdate to ' + nupt);
            setWorkflowUpdate(nupt);
        });
    }, []);
    */
    
    const handleInboxRootAction = (event) => {
        // mySerial = mySerial + 1;
        // setMyWorkflowList(<MyWorkflowList serial={mySerial} />);
        setWorkflowUpdate(workflowUpdate + 1);
        // initWebViewer();
    };

    const handleActiveRootAction = (event) => {
        // activeSerial = activeSerial + 1;
        // setActiveWorkflowList(<ActiveWorkflowList serial={activeSerial} />);
        setWorkflowUpdate(workflowUpdate + 1);
        // initWebViewer();
    };

    const handleAvailableRootAction = (event) => {
        setMyWorkflowIndex(-1);
        setActiveWorkflowIndex(-1);
        WorkflowModule.pageChange( <AvailableWorkflows /> );
    };

    const handleCompletedRootAction = (event) => {
        setMyWorkflowIndex(-1);
        setActiveWorkflowIndex(-1);
        WorkflowModule.pageChange( <CompletedWorkflows /> );
        console.log("Completed Workflows...");
    };

    const handlePdfTronRootAction = (event) => {
        /*
        WorkflowModule.pageIndexChange( 1 );
        const {  annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + '/opt/UCS/output/DRAFT/TG_777_FCOM/210311124107/PDF/TG_777_FCOM_2021-03-05_draft_controlled_full.pdf');
        */
    };

    const handleInboxItemClick = (index) => {
        
        setMyWorkflowIndex(index);
        setActiveWorkflowIndex(-1);
        console.log('Opening workflow ' + index);
        WorkflowModule.pageChange( <WorkflowInstance instance={myWorkflowList[index]} update={instanceUpdate} /> );
        if ( !matchesDesktop ) {
            WorkflowModule.drawerOpen(false);
            WorkflowModule.menuIconChange('back');
            setMenuIcon('back');
        }
        // initWebViewer();
    };

    const handleActiveItemClick = (index) => {
        setMyWorkflowIndex(-1);
        setActiveWorkflowIndex(index);
        console.log('Opening workflow ' + index);
        WorkflowModule.pageChange( <WorkflowInstance instance={activeWorkflowList[index]} update={instanceUpdate} /> );
        if ( !matchesDesktop ) {
            WorkflowModule.drawerOpen(false);
            WorkflowModule.menuIconChange('back');
            setMenuIcon('back');
        }
        // initWebViewer();
    };

    const workflowInfo = (message) => {
        let wevent = message.data;
        console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowInstance') {
            setWorkflowUpdate(workflowUpdate => workflowUpdate + 1);
            wreload = wreload + 1;
            updateInstances();
        }
    };

    const handleMenuIconClick = (event) => {
        if ( menuIcon === 'back' ) {
            WorkflowModule.menuIconChange('app');
            setMenuIcon('app');
        } else {
            WorkflowModule.menuIconChange('back');
            setMenuIcon('back');
        }
    };
    
    
    MessageHooks["workflow"]["WorkflowMenu"] = workflowInfo;
    WorkflowModule.onMenuIconClick = handleMenuIconClick;

    return (
        <Box
            sx={{ width: '100%', fontSize: '1.3em', padding: 0, position: "relative" }}
            role="presentation"
        >
            <List  >
                <ListItem key="w-inbox" disablePadding >
                    <ListItemButton onClick={handleInboxRootAction} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Tasks" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="w-inbox-content" disablePadding>
                    <ListItemText>
                    <WorkflowList workflowList={myWorkflowList} reload={workflowUpdate} keyPrefix="WIM" onClick={handleInboxItemClick} selected={myWorkflowIndex} />
                    </ListItemText>
                </ListItem>
                <ListItem key="w-active" disablePadding >
                    <ListItemButton onClick={handleActiveRootAction} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <DirectionsRunIcon />
                        </ListItemIcon>
                        <ListItemText primary="Active Workflows" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="w-active-content" disablePadding>
                    <ListItemText>
                        <WorkflowList workflowList={activeWorkflowList} reload={workflowUpdate} keyPrefix="WIA" onClick={handleActiveItemClick} selected={activeWorkflowIndex} />
                    </ListItemText>
                </ListItem>
                <ListItem key="w-available" disablePadding >
                    <ListItemButton onClick={handleAvailableRootAction} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Available Workflows" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="w-completed" disablePadding >
                    <ListItemButton onClick={handleCompletedRootAction} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <FactCheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Completed Workflows" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
            </List>

        </Box>
    );
}

/*
const workflowInfo  = (message) => {
    let wevent = message.data;
    console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id );
    wreload = wreload + 1;
    console.log('Reload = ' + wreload );
    WorkflowModule.onDrawerChange( <WorkflowMenu reload={wreload}/> );
  };
*/


const WorkflowModule = {
    // label
    label: 'Workflow',
    barLabel: 'Uniscope Cloud Portal',
    barShortLabel: 'UCS',
    // icon
    icon: <SchemaIcon fontSize="inherit" />,
    // menu
    drawerContent: <WorkflowMenu reload={wreload}/>,
    // initial body page
    pageContent: null,
    // send new body page on menu actions
    pageChange: (page) => {},
    pageIndexChange: (index) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},

    hooks: {}, // { workflow: { top: workflowInfo } },
    route: 
        {
            path: 'workflow',
            element: <WorkflowMenu reload={wreload}/>,
        }
    ,
}


export default WorkflowModule;

