import React, { Component } from "react";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function Login() {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };
  
    return (
        <Box sx={{display: 'flex', textAlign: 'center', alignItems: 'center', height: '100%', justifyContent: 'center', backgroundImage: 'linear-gradient(to bottom right, white, rgb(102, 204, 255, 0.05) , rgb(204, 204, 255, 0.1))' }}>
            <Box sx={{textAlign: 'center' }}>
                <Typography sx={{ fontSize: '20px' }}><img src="/images/application.svg" width="40" style={{ paddingLeft: "0px", paddingRight: '2ex', position: 'relative', top: '3px'}}/>Loading UCS application. Please wait....</Typography>
                <CircularProgress color="inherit" sx={{ marginTop: '70px' }} />
            </Box>
        </Box>
    );
};

/*
<div>
        <Button onClick={handleOpen}>Show backdrop</Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>


*/