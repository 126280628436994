import { configureStore } from '@reduxjs/toolkit';

import {auth, user, websocket } from '../features/network/networkSlice';
import { fileUpload } from '../features/file-upload/fileUploadSlice';

export default configureStore({
  reducer: {
    auth: auth.reducer,
    user: user.reducer,
    websocket: websocket.reducer,
    fileUpload: fileUpload.reducer,
  },
});