import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import SecurityIcon from '@mui/icons-material/Security';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';


function SecurityMenu(props) {
    const [menuIcon, setMenuIcon] = React.useState('app');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const theme = useTheme();

    const handlePoolsAction = (event) => {
        // SecurityModule.pageChange(<EditTemplate/>)
        setSelectedIndex(0);
    };

    

    const handleMenuIconClick = (event) => {
        if ( menuIcon === 'back' ) {
            SecurityModule.menuIconChange('app');
            setMenuIcon('app');
        } else {
            SecurityModule.menuIconChange('back');
            setMenuIcon('back');
        }
    };

    SecurityModule.onMenuIconClick = handleMenuIconClick;

    return (
        <Box
            sx={{ width: '100%', fontSize: '1.3em', padding: 0, paddingTop: "1ex", position: "relative" }}
            role="presentation"
        >
            <List  sx={{paddingBottom: 0}} >
                <ListItem key="sec-groups" disablePadding >
                    <ListItemButton>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users & Groups" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List sx={{paddingTop: 0}}>
                <ListItem key="sec-pools" disablePadding selected={selectedIndex === 0}>
                    <ListItemButton onClick={handlePoolsAction}>
                        <ListItemIcon sx={{ paddingRight: 0,  paddingLeft: '40px', minWidth: '70px'}}>
                            <PeopleIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Pools" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
            </List>

        </Box>
    );
}


const SecurityModule = {
    // label
    label: 'Security',
    barLabel: 'Security',
    barShortLabel: 'Security',
    // icon
    icon: <SecurityIcon fontSize="inherit" />,
    // menu
    drawerContent: <SecurityMenu />,
    // initial body page
    pageContent: null,
    // send new body page on menu actions
    pageChange: (page) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},

    hooks: {}, // { workflow: workflowInfo },
}

export default SecurityModule;