import React, { Component } from "react";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ButtonProps, TypographyProps } from '@mui/material';
import { Box, Button, FormControl, FormHelperText, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import FileListItem from './file-list-item';
import { setUploadFile, setUploadStarted, setUploadLoaded, setUploadProgress, setErrorMessage, initFileUpload, setUploadStatus} from '../../features/file-upload/fileUploadSlice';
import store from '../../app/store';
import { getAuthHeaders } from "../network";


// network upload function

export const netUpload = (file, url, token) => new Promise( (resolve,reject) => {

    store.dispatch(initFileUpload(file.name));
    const xhr = new XMLHttpRequest();
    const reader = new FileReader();

    xhr.upload.addEventListener(
        "progress",
        (e) => {
            if (e.lengthComputable) {
                const percentage = Math.round((e.loaded * 100) / e.total);
                store.dispatch(setUploadProgress(percentage));
            }
        },
        false
    );
    xhr.upload.addEventListener(
        "loadstart",
        (e) => {
            store.dispatch(setUploadProgress(0));
            store.dispatch(setUploadStarted(true));
        },
        false
    );
    xhr.upload.addEventListener(
        "load",
        (e) => {
            store.dispatch(setUploadProgress(100));
            store.dispatch(setUploadLoaded(true));
        },
        false
    );

    xhr.upload.addEventListener(
        "error",
        (e) => {
            store.dispatch(setUploadStatus(800)); // client internal error
            store.dispatch(setUploadLoaded(true));
            const msg = `Error occurred uploading file: ${file.name}`;
            console.log(`Rejecting: ${msg}...`);
            reject(msg);
        },
        false
    );

    xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            const status = xhr.status;
            if ( status >= 400 ) {
                store.dispatch(setUploadStatus(status));
                store.dispatch(setErrorMessage(`${status}: ${xhr.responseText}`));
            }
            //setTimeout( () => { // to test
                console.log('Resolving with status: ' + status);
                // return the original token also, this helps the client
                resolve( { status: status, statusTex: xhr.responseText, token: token });
            //}, 5000);
            
        }
    };
    

    xhr.open("PUT", url);
    const auth = getAuthHeaders();
    if ( auth["Authorization"] ) {
        xhr.setRequestHeader("Authorization", auth["Authorization"]);
    }
    xhr.overrideMimeType("application/octet-stream");
    reader.onload = (evt) => {
        xhr.send(evt.target.result);
    };
    reader.addEventListener("error", () => {
        // console.error(`Error occurred reading file: ${file.name}`);
        const msg = `Error occurred reading file: ${file.name}`;
        store.dispatch(setUploadStatus(800));
        store.dispatch(setUploadLoaded(true));
        store.dispatch(setErrorMessage(msg));
        reject(msg);
    });
    reader.readAsArrayBuffer(file);

});

// component
export default function FileUpload(props) {
    const {
        value,
        onChange,
        sx,
        title = "Drag 'n' drop some files here, or click to select files",
        buttonText = 'Upload',
        typographyProps,
        buttonProps,
        disabled,
        maxSize,
        ...options
    } = props;

    const [ filesSelected, setFilesSelected] = React.useState([]);

    const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
        ...options,
        disabled,
        maxSize,
        onDropAccepted: onChange,
        noClick: true,
        noKeyboard: true,
      });

    
    
    const isFileTooLarge = maxSize !== undefined && fileRejections.length > 0 && fileRejections[0].file.size > maxSize;

    const remove = (index) => {
        const files = [...value];
        files.splice(index, 1);
        onChange(files);
    };

    const isFileSelected = (index) => {
        if ( Array.isArray(filesSelected) ) {
            return filesSelected.includes(index);
        }
        return false;
    };

    const toggleSelection = (index) => {
        if ( Array.isArray(filesSelected) ) {
            if ( filesSelected.includes(index) ) {
                const fs = [...filesSelected];
                const j = fs.indexOf(index);
                fs.splice(j, 1);
                setFilesSelected(fs);
            } else {
                const fs = [...filesSelected];
                fs.push(index);
                setFilesSelected(fs);
            }
        } else {
            const fs = [];
            fs.push(index);
            setFilesSelected(fs);
        }
    };

    // const files = value?.map((file, i) => <FileListItem key={file.name} name={file.name} selected={isFileSelected(i)} onDelete={() => remove(i)} onClick={() => toggleSelection(i)} />);
    const files = value?.map((file, i) => <FileListItem key={file.name} name={file.name} onDelete={() => remove(i)} />);

    return (
        <Box
            {...getRootProps()}
            sx={{
                border: 1,
                borderRadius: 1,
                borderColor: 'rgba(0, 0, 0, 0.23)',
                paddingY: 3,
                paddingX: 1,
                '&:hover': {
                    borderColor: disabled ? undefined : 'text.primary',
                },
                '&:focus-within': {
                    borderColor: 'primary.main',
                    borderWidth: 2,
                },
                ...sx,
            }}>
            <FormControl
                error={isFileTooLarge}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                <input {...getInputProps()} />
                <CloudUploadIcon sx={{ fontSize: 40 }} color={disabled ? 'disabled' : 'primary'} />
                <Typography variant="caption" textAlign="center" sx={{ paddingY: 1 }} {...typographyProps}>
                    {title}
                </Typography>
                <Button variant="outlined" onClick={open} disabled={disabled} sx={{ marginBottom: 1 }} {...buttonProps}>
                    {buttonText}
                </Button>
                <FormHelperText> {fileRejections[0]?.errors[0]?.message} </FormHelperText>
            </FormControl>
            <Box
                component="ul"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0,
                }}>
                {files}
            </Box>
        </Box>
      );

};