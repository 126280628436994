import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListIcon from '@mui/icons-material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DatasetIcon from '@mui/icons-material/Dataset';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RuleIcon from '@mui/icons-material/Rule';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SettingsIcon from '@mui/icons-material/Settings';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import OutputIcon from '@mui/icons-material/Output';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import InputIcon from '@mui/icons-material/Input';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ClearIcon from '@mui/icons-material/Clear';
import SchemaIcon from '@mui/icons-material/Schema';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


import { MessageHooks } from "../App";
import { InsertBeforeIcon, InsertAfterIcon } from "./icons";

import { EditTemplate } from "./workflow/management-workflow-template";
import { EditWorkflow } from "./workflow/management-workflow-attributes";
import { PublishedWorkflows } from "./workflow/management-workflow-published";
import { WorkflowForms } from "./workflow/management-workflow-forms";
import { WorkflowFormLists } from "./workflow/management-workflow-forms";
import { DocumentConfiguration } from "./document/management-config";


function ManagementMenu(props) {
    const [menuIcon, setMenuIcon] = React.useState('app');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const theme = useTheme();

    const handleTemplateAction = (event) => {
        ManagementModule.pageChange(<EditTemplate/>)
        setSelectedIndex(0);
    };

    const handleWorkflowAction = (event) => {
        ManagementModule.pageChange(<EditWorkflow/>);
        setSelectedIndex(1);
    };

    const handleFormsAction = (event) => {
        ManagementModule.pageChange(<WorkflowForms/>);
        setSelectedIndex(4);
    }

    const handleListsAction = (event) => {
        ManagementModule.pageChange(<WorkflowFormLists/>);
        setSelectedIndex(5);
    }

    const handlePublishedAction = (event) => {
        ManagementModule.pageChange(<PublishedWorkflows/>);
        setSelectedIndex(2);
    };

    const handleMenuIconClick = (event) => {
        if ( menuIcon === 'back' ) {
            ManagementModule.menuIconChange('app');
            setMenuIcon('app');
        } else {
            ManagementModule.menuIconChange('back');
            setMenuIcon('back');
        }
    };

    const handleDocsConfigAction = (event) => {
        ManagementModule.pageChange(<DocumentConfiguration/>);
        setSelectedIndex(3);
    };

    ManagementModule.onMenuIconClick = handleMenuIconClick;

    return (
        <Box
            sx={{ width: '100%', fontSize: '1.3em', padding: 0, paddingTop: "1ex", position: "relative" }}
            role="presentation"
        >
            <List  sx={{paddingBottom: 0}} >
                <ListItem key="mw-workflow" disablePadding >
                    <ListItemButton>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <SchemaIcon />
                        </ListItemIcon>
                        <ListItemText primary="Workflow" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List sx={{paddingTop: 0}}>
                <ListItem key="mw-templates" disablePadding >
                    <ListItemButton onClick={handleTemplateAction} selected={selectedIndex === 0}>
                        <ListItemIcon sx={{ paddingRight: 0,  paddingLeft: '40px', minWidth: '70px'}}>
                            <FormatListNumberedIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Templates" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="mw-archetypes" disablePadding >
                    <ListItemButton onClick={handleWorkflowAction} selected={selectedIndex === 1}>
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px' }}>
                            <RuleIcon  fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText primary="Profiles" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
                <ListItem key="mw-forms" disablePadding >
                    <ListItemButton onClick={handleFormsAction} selected={selectedIndex === 4}>
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px' }}>
                            <DatasetIcon  fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText primary="Forms" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                {/*
                <ListItem key="mw-lists" disablePadding >
                    <ListItemButton onClick={handleListsAction} selected={selectedIndex === 5}>
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px' }}>
                            <ListIcon  fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText primary="Selection Lists" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                    */}
                <ListItem key="mw-published" disablePadding >
                    <ListItemButton onClick={handlePublishedAction} selected={selectedIndex === 2}>
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px'}}>
                            <PublishedWithChangesIcon  fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Published" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List  sx={{paddingBottom: 0}} >
                <ListItem key="mw-docs" disablePadding >
                    <ListItemButton>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Documents" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List sx={{paddingTop: 0}}>
                <ListItem key="mw-docs-config" disablePadding >
                    <ListItemButton onClick={handleDocsConfigAction} selected={selectedIndex === 3}>
                        <ListItemIcon sx={{ paddingRight: 0,  paddingLeft: '40px', minWidth: '70px'}}>
                            <SettingsIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Configuration" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
        </Box>
    );
}


const ManagementModule = {
    // label
    label: 'Management',
    barLabel: 'Management',
    barShortLabel: 'Management',
    // icon
    icon: <ManageAccountsIcon fontSize="inherit" />,
    // menu
    drawerContent: <ManagementMenu />,
    // initial body page
    pageContent: null,
    // send new body page on menu actions
    pageChange: (page) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},

    hooks: {}, // { workflow: workflowInfo },
    route: {
        path: "management",
        element: <ManagementMenu />,
    },
}

export default ManagementModule;