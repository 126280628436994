import React, { useRef, useEffect } from 'react';

import Box from '@mui/material/Box';
import WebViewer from '@pdftron/webviewer';
import { uxLicense } from './webviewer_license';
import { MessageHooks } from "../App";
import { UserInfo, netGet, netFetch, keycloak, getAuthHeaders } from "./network";
import postal from 'postal';
import './webviewer.css';

// last component is Uniscope's version
// change also the setupProxy.js to match version
const viewerVersion = '10.2.2.1'; 
const isWebComponent = false;

var pdftronInstance;
var initializing = false;
var firstInstance = true;

var currentDocument = 'DUMMY';
var currentDocumentLabel = '';
var currentVersion;
var currentVersionNumber;
var currentPreviousVersions = [];
var multiviewerEnabled = false;
var changingVersions = false;

var scheduledDocument = [];
var scheduledVersion = [];

var menuParent;
var versionsMenu = document.createElement('div');
var versionsMenuBtn = document.createElement('button');
var isMenuOpen = false;
var isCurrentDocumentReadOnly = false;

let readOnlyWarningPara = document.createElement('p');
readOnlyWarningPara.innerHTML = 'This is a read-only document. You cannot add any annotations.';
readOnlyWarningPara.style = 'text-align: center';

const readOnlyWarningModalOptions = {
    dataElement: 'ucsReadOnlyWarningModal',
    header: {
        title: 'Warning',
        className: 'ucsReadOnlyWarningModal-header comments-counter',
        style: { 'padding-top': '0px', 'box-shadow': 'none'}
    },
    body: {
        className: 'ucsReadOnlyWarningModal-body note-text-preview preview-comment',
        style: { 'padding-top': '15px', 'box-shadow': 'none', 'font-size': '110%'},
        children: [readOnlyWarningPara],
    },
    footer: {
        className: 'ucsReadOnlyWarningModal-footer footer',
        style: { 'box-shadow': 'none'},
        children: [
            {
                title: 'Dismiss',
                button: true,
                style: {},
                className: 'modal-button confirm ok-btn',
                onClick: (e) => { if ( pdftronInstance ) {
                    pdftronInstance.UI.closeElements(['ucsReadOnlyWarningModal']);
                } }
            },
        ]
    }
};

const closeCustomMenu = () => {
    if ( isMenuOpen && menuParent) {
        menuParent.removeChild(versionsMenu);
        isMenuOpen = !isMenuOpen;
    }
};

const initWebViewer = () => {
    if (initializing) {
        return;
    }
    if (pdftronInstance) {
        return;
    }
    console.log('Initializing webviewer...');
    initializing = true;
    // sessionId most probably won't be ready at this time
    // console.log('My sessionId ' + UserInfo.info.sessionId);
    let container = document.getElementById('pdftron-viewer-container');
    container.innerHTML = '';
    let viewer = document.createElement('div');
    viewer.id = 'pdftron-viewer';
    viewer.classList.add('webviewer');
    container.appendChild(viewer);

    const webviewerPath = "/webviewer-" + viewerVersion;
    console.log('Opening webviewer at ' + webviewerPath);

    // and don't load any initial document 
    WebViewer (
        {
            licenseKey: uxLicense,
            path: webviewerPath,
            enableAnnotations: true,
            // fullAPI: true,
            disabledElements: ['annotationNoteConnectorLine'],
        },
        // document.getElementById('pdftron-viewer'),
        viewer,
    ).then((instance) => {

        console.log('On Webviewer...');
        // instance set here, it gets exported
        // should we use React's shared state instead
        pdftronInstance = instance;

        // instance.UI.enableNativeScrolling();

        instance.UI.disableElements(['toolbarGroup-Shapes', 'toolbarGroup-Edit', 'toolbarGroup-Insert']);
        // instance.UI.disableElements(['downloadButton', 'printButton', 'saveAsButton']);

        const { documentViewer, annotationManager, Annotations, Tools } = instance.Core;

        if (firstInstance) {

            instance.UI.addCustomModal(readOnlyWarningModalOptions);

            instance.UI.setHeaderItems(header => {
                header.push({
                    type: 'divider',
                    hidden: ['mobile'],
                    dataElement: 'previous-versions-menu-divider'
                });
                // menuParent = documentViewer.getScrollViewElement().parentElement;

                versionsMenu.classList.add('Overlay');
                versionsMenu.classList.add('FlyoutMenu');
                versionsMenu.style.padding = '0.8em';

                /*
                const viewerEle = document.getElementById('pdftron-viewer');
                // first child is the viewer's iframe
                const iframe = viewerEle.firstElementChild;
                */
                // use the iframe's body since it's stable, we have to position it absolutely anyway
                menuParent = isWebComponent? documentViewer.getScrollViewElement().parentElement : instance.UI.iframeWindow.document.body;
                // add a listener for outside clicks to dismiss menu
                menuParent.addEventListener('click', e => {
                    // console.log('Window click : ' + JSON.stringify(e.target));
                    if (isMenuOpen) {
                        if (!versionsMenu.contains(e.target) && !versionsMenuBtn.contains(e.target)) {
                            closeCustomMenu();
                        }
                    }
                });

                const renderCustomMenu = () => {

                    versionsMenuBtn.classList.add('Button');
                    versionsMenuBtn.classList.add('ActionButton');
                    versionsMenuBtn.setAttribute('type', 'button');
                    versionsMenuBtn.setAttribute('aria-label', 'Previous Drafts');
                    const iconDiv = document.createElement('div');
                    iconDiv.classList.add('Icon');
                    iconDiv.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5v2h2V1h-2v2zm0 15H5l5-6v6zm9-15h-5v2h5v13l-5-6v9h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>';
                    versionsMenuBtn.appendChild(iconDiv);

                    versionsMenuBtn.onclick = () => {
                        console.log('Version Button Click...');
                        if (Array.isArray(currentPreviousVersions) && currentPreviousVersions.length > 0) {
                            console.log('isMenuOpen = ' + isMenuOpen + ', menu = ' + versionsMenu);
                            if (isMenuOpen) {
                                menuParent.removeChild(versionsMenu);
                            } else {
                                if ( isWebComponent ) {
                                    // let xpos = `${menuParent.clientWidth - (versionsMenuBtn.clientWidth + 84)}px`;
                                    let xpos = `${viewer.clientWidth - (versionsMenuBtn.clientWidth + 84)}px`;
                                    console.log('Parent width = ' + viewer.clientWidth + ', menu width = ' + versionsMenuBtn.clientWidth);
                                    console.log('Opening menu at left = ' + xpos);
                                    versionsMenu.style.left = xpos;
                                    versionsMenu.style.right = 'auto';
                                    // versionsMenu.style.top = '40px';
                                    versionsMenu.style.top = '40px';
                                    // versionsMenu.style.zIndex = '100';
                                    menuParent.appendChild(versionsMenu);

                                } else {
                                    let xpos = `${menuParent.clientWidth - (versionsMenuBtn.clientWidth + 84)}px`;
                                    //console.log('Parent width = ' + viewer.clientWidth + ', menu width = ' + versionsMenuBtn.clientWidth);
                                    //console.log('Opening menu at left = ' + xpos);
                                    versionsMenu.style.left = xpos;
                                    versionsMenu.style.right = 'auto';
                                    versionsMenu.style.top = '40px';
                                    menuParent.appendChild(versionsMenu);
                                }
                            }
                            isMenuOpen = !isMenuOpen;
                        } else {
                            if (isMenuOpen) {
                                menuParent.removeChild(versionsMenu);
                                isMenuOpen = !isMenuOpen;
                            }
                            // else do nothing, we don't have versions
                        }
                    };
                    return versionsMenuBtn;
                };

                const newCustomElement = {
                    type: 'customElement',
                    render: renderCustomMenu,
                    title: 'Previous Drafts',
                    dataElement: 'previous-versions-menu',
                };

                header.push(newCustomElement);

                header.push({
                    type: 'divider',
                    hidden: ['mobile']
                });
                header.push({
                    type: 'actionButton',
                    img: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>',
                    title: 'Close PDF Viewer',
                    onClick: () => {
                        console.log('Closing PDF viewer...');
                        closePdfViewer();
                    }
                });
            });

        }
        firstInstance = false;
        /*
        const highlightMouseUp = Tools.TextHighlightCreateTool.prototype.mouseLeftUp;
        const highlightMouseDown = Tools.TextHighlightCreateTool.prototype.mouseLeftDown;

        Tools.TextHighlightCreateTool.prototype.mouseLeftDown = function () {
            
            highlightMouseDown.apply(this, arguments);
        };

        Tools.TextHighlightCreateTool.prototype.mouseLeftUp = function () {
            if (this.annotation) {
                if (Tools.TextHighlightCreateTool.getDocumentViewers()[0] === documentViewer) {
                    this.annotation.StrokeColor = new Annotations.Color(0, 255, 255);
                    annotationManager.redrawAnnotation(this.annotation);
                    console.log('Changing highlight color in main docViewer...');
                } else {
                    console.log('NOT in main docViewer');
                }
            }
            highlightMouseUp.apply(this, arguments);
        };
        */

        instance.UI.addEventListener(instance.UI.Events.MULTI_VIEWER_READY, () => {
            // console.log('Got multiviewer ready event...');
            instance.Core.getDocumentViewers()[1].addEventListener('documentLoaded', () => {
                instance.UI.closeElements(['notesPanel']);
                instance.UI.openElements(['leftPanel', 'outlinesPanel']);
                instance.UI.disableElements(['toggleNotesButton']);
                // instance.UI.setNotesPanelSortStrategy(instance.UI.NotesPanelSortStrategy.CREATED_DATE, true);
                
                instance.UI.setCustomPanel({
                    uniscope: {
                        type: 'notesInLeftPanel',
                        notesInLeftPanel: true,
                    }
                });

                const docViewer1 = instance.Core.getDocumentViewers()[1];
                
                instance.Core.getDocumentViewers()[1].enableReadOnlyMode();
                // instance.Core.getDocumentViewers()[1].disableAnnotations();
                changingVersions = false;

                registerSuppressTools(docViewer1);

                /*
                const tool = docViewer1.getTool('AnnotationCreateSticky');
                tool.addEventListener('annotationAdded', (annotation) => {
                    console.log('Adding NEW annotation and removing: ' + JSON.stringify(annotation));
                    docViewer1.getAnnotationManager().deleteAnnotation(annotation, { force: true});
                });
                const tool4 = docViewer1.getTool('AnnotationCreateTextHighlight4');
                tool4.addEventListener('annotationAdded', (annotation) => {
                    console.log('Adding NEW TextHightlight4 annotation and removing: ' + JSON.stringify(annotation));
                    docViewer1.getAnnotationManager().deleteAnnotation(annotation, { force: true});
                });
                */

                /*
                instance.Core.getDocumentViewers()[1].addEventListener('mouseLeftDown', evt => {
                    
                   console.log('onMouseLeftDown');
                    evt.preventDefault();
                  });
                */

                setTimeout(() => instance.UI.openElements(['notesPanel', 'multiRightPanel', 'multiNotesPanel']), 100);
                // setTimeout(() => instance.UI.openElements(['notesPanel']), 200);
                setTimeout(() => instance.UI.setActiveLeftPanel('notesPanel'), 200);

                let auth = getAuthHeaders();
                netFetch('/api/webviewer/xfdf/' + UserInfo.info.sessionId + '?doc=' + currentVersion, {
                    method: 'GET',
                    headers: auth,
                }).then(response => {
                    response.text().then(xfdfString => {
                        // <xfdf><annots><text subject="Comment" page="0" color="#FFE6A2" ... /></annots></xfdf>
                        instance.Core.getDocumentViewers()[1].getAnnotationManager().importAnnotations(xfdfString);
                        // instance.Core.getDocumentViewers()[1].getAnnotationManager().enableReadOnlyMode();
                    });
                    instance.UI.openElements(['notesPanelButton']);
                });
            });
            instance.Core.getDocumentViewers()[1].addEventListener('documentUnloaded', () => {
                // console.log('changingVersions = ' + changingVersions);
                if ( !changingVersions ) {
                    instance.UI.disableFeatures([instance.UI.Feature.MultiViewerMode]);
                    multiviewerEnabled = false;
                    instance.UI.setCustomPanel({
                        uniscope: {
                            type: 'notesInLeftPanel',
                            notesInLeftPanel: false,
                        }
                    });
                    instance.UI.enableElements(['toggleNotesButton']);
                    instance.UI.openElements(['leftPanel', 'outlinesPanel', 'notesPanel']);
                    instance.UI.closeElements(['multiRightPanel', 'multiNotesPanel']);
                }
                changingVersions = false;
            });
            instance.Core.getDocumentViewers()[0].getAnnotationManager().disableReadOnlyMode();

            if ( scheduledVersion.length > 0 ) {
                // console.log('Got schedule versions');
                const version = scheduledVersion.pop();
                if ( version ) {
                    loadPdfDocumentVersion(version.path, version.number);
                }
            }
        });


        documentViewer.addEventListener('documentLoaded', () => {

            instance.UI.setNotesPanelSortStrategy(instance.UI.NotesPanelSortStrategy.POSITION, false);
            if ( isCurrentDocumentReadOnly ) {
                documentViewer.enableReadOnlyMode();
            }
            instance.UI.enableElements(['toggleNotesButton']);
            // const notesPanel = isCurrentDocumentReadOnly ? 'multiNotesPanel' : 'notesPanel';
            instance.UI.openElements(['leftPanel', 'outlinesPanel', 'rightPanel', 'notesPanel', 'ribbons' ]);
            if ( isCurrentDocumentReadOnly ) {
                setTimeout(() => instance.UI.openElements(['notesPanel', 'multiNotesPanel']), 200);
            } else {
                instance.UI.enableElements(['ribbons', 'toolsHeader']);
                instance.UI.openElements(['toolsHeader']);
                instance.UI.setToolbarGroup('toolbarGroup-Annotate');
                // setTimeout(() => instance.UI.closeElements(["toolbarGroup-View"]), 100);
                // instance.UI.openElements(['ribbons', 'toolbarGroup-Annotate', 'toolsHeader']);
                setTimeout(() => instance.UI.openElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE]), 400);
                setTimeout(() => instance.UI.openElements(['toolsHeader']), 300);
            }
            // console.log("Open ReadOnly Mode:" + isCurrentDocumentReadOnly);
            
            // load the annotation data
            // document load was triggered by user so UserInfo should be set by now
            let auth = getAuthHeaders();
            netFetch('/api/webviewer/xfdf/' + UserInfo.info.sessionId + '?doc=' + currentDocument, {
                method: 'GET',
                headers: auth,
            })
                .then(response => {

                    response.text().then(xfdfString => {
                        // <xfdf><annots><text subject="Comment" page="0" color="#FFE6A2" ... /></annots></xfdf>
                        annotationManager.importAnnotations(xfdfString);
                    });

                });
        });

        documentViewer.addEventListener('documentUnloaded', () => {
            instance.UI.disableFeatures([instance.UI.Feature.MultiViewerMode]);
            multiviewerEnabled = false;
            changingVersions = false;
            closePdfViewer();
        });

        annotationManager.addEventListener('annotationChanged', function (a, b, e) {
            // If the event is triggered by importing then it can be ignored
            // This will happen when importing the initial annotations from the server or individual changes from other users
            if ( e.info && e.info.imported ) return;
            if (e.imported) return;

            annotationManager.exportAnnotationCommand().then(xfdfString => {
                // <xfdf>
                //    <add>
                //      <text subject="Comment" page="0" color="#FFE6A2" ... />
                //    </add>
                //    <modify />
                //    <delete />
                // </xfdf>
                const auth = getAuthHeaders();
                let myHeaders = { 'Content-Type': 'text/xml;charset=UTF-8', ...auth };
                // console.log('Annot update: ' + currentDocument + ' => ' + currentDocumentLabel);
                netFetch('/api/webviewer/annots/update/' + UserInfo.info.sessionId + '?doc=' + currentDocument, {
                    method: 'POST',
                    body: xfdfString,
                    headers: myHeaders,
                });
            });
        });

        postal.publish({
            topic: "webviewer.initialized",
            data: {
                instance: instance
            }
        });

        initializing = false;
    }).catch(error => {
        console.log('Error initializing PDFTron Webviewer: ' + error);
        initializing = false;
    });
}



const suppressAnnotation = (annotation) => {
    if (pdftronInstance ) {
        const docViewer = pdftronInstance.Core.getDocumentViewers()[1];
        if ( docViewer ) {
            const annotManager = docViewer.getAnnotationManager();
            if ( annotManager && annotManager.hasAnnotation(annotation) ) {
                // console.log('Removing newly added annotation: ' + annotation.toolName);
                annotManager.deleteAnnotation(annotation, { force: true});
                pdftronInstance.UI.openElements([readOnlyWarningModalOptions.dataElement]);
            }
        }
    }

};

const registerToolSuppress = (docViewer, toolName) => {
    const tool = docViewer.getTool(toolName);
    if (tool) {
        tool.addEventListener('annotationAdded', suppressAnnotation);
    }
};

const registerSuppressTools = (docViewer) => {
    if (pdftronInstance) {
        const { Tools } = pdftronInstance.Core;
        
        const toolNames = [
            Tools.ToolNames.ARROW, Tools.ToolNames.ARROW2, Tools.ToolNames.ARROW3, Tools.ToolNames.ARROW4,
            Tools.ToolNames.CALLOUT, Tools.ToolNames.CALLOUT2, Tools.ToolNames.CALLOUT3, Tools.ToolNames.CALLOUT4,
            Tools.ToolNames.ELLIPSE, Tools.ToolNames.ELLIPSE2, Tools.ToolNames.ELLIPSE3, Tools.ToolNames.ELLIPSE4,
            Tools.ToolNames.FREEHAND, Tools.ToolNames.FREEHAND2, Tools.ToolNames.FREEHAND3, Tools.ToolNames.FREEHAND4,
            Tools.ToolNames.FREEHAND_HIGHLIGHT, Tools.ToolNames.FREEHAND_HIGHLIGHT2, Tools.ToolNames.FREEHAND_HIGHLIGHT3, Tools.ToolNames.FREEHAND_HIGHLIGHT4,
            Tools.ToolNames.FREETEXT, Tools.ToolNames.FREETEXT2, Tools.ToolNames.FREETEXT3, Tools.ToolNames.FREETEXT4,
            Tools.ToolNames.MARK_INSERT_TEXT, Tools.ToolNames.MARK_INSERT_TEXT2, Tools.ToolNames.MARK_INSERT_TEXT3, Tools.ToolNames.MARK_INSERT_TEXT4,
            Tools.ToolNames.MARK_REPLACE_TEXT, Tools.ToolNames.MARK_REPLACE_TEXT2, Tools.ToolNames.MARK_REPLACE_TEXT3, Tools.ToolNames.MARK_REPLACE_TEXT4,
            Tools.ToolNames.STICKY, Tools.ToolNames.STICKY2, Tools.ToolNames.STICKY3, Tools.ToolNames.STICKY4,
            Tools.ToolNames.SQUIGGLY, Tools.ToolNames.SQUIGGLY2, Tools.ToolNames.SQUIGGLY3, Tools.ToolNames.SQUIGGLY4,
            Tools.ToolNames.STRIKEOUT, Tools.ToolNames.STRIKEOUT2, Tools.ToolNames.STRIKEOUT3, Tools.ToolNames.STRIKEOUT4,
            Tools.ToolNames.HIGHLIGHT, Tools.ToolNames.HIGHLIGHT2, Tools.ToolNames.HIGHLIGHT3, Tools.ToolNames.HIGHLIGHT4,
            Tools.ToolNames.UNDERLINE, Tools.ToolNames.UNDERLINE2, Tools.ToolNames.UNDERLINE3, Tools.ToolNames.UNDERLINE4,
            Tools.ToolNames.STAMP, Tools.ToolNames.SIGNATURE, Tools.ToolNames.FILEATTACHMENT, Tools.ToolNames.EDIT,
            Tools.ToolNames.FORM_FILL_CROSS, Tools.ToolNames.FORM_FILL_CHECKMARK, Tools.ToolNames.FORM_FILL_DOT,
            Tools.ToolNames.LINE, Tools.ToolNames.LINE2, Tools.ToolNames.LINE3, Tools.ToolNames.LINE4,
            Tools.ToolNames.POLYGON, Tools.ToolNames.POLYGON2, Tools.ToolNames.POLYGON3, Tools.ToolNames.POLYGON4,
            Tools.ToolNames.POLYGON_CLOUD, Tools.ToolNames.POLYGON_CLOUD2, Tools.ToolNames.POLYGON_CLOUD3, Tools.ToolNames.POLYGON_CLOUD4,
            Tools.ToolNames.POLYLINE, Tools.ToolNames.POLYLINE2, Tools.ToolNames.POLYLINE3, Tools.ToolNames.POLYLINE4,
            Tools.ToolNames.RECTANGLE, Tools.ToolNames.RECTANGLE2, Tools.ToolNames.RECTANGLE3, Tools.ToolNames.RECTANGLE4,
        ];

        toolNames.forEach( toolName => registerToolSuppress(docViewer, toolName));
        
        
        
    }
};

const openPdfDocument = (path, label, previousDrafts) => {
    isCurrentDocumentReadOnly = false;
    if ( pdftronInstance ) {
        loadPdfDocument(path, label, previousDrafts);
    } else {
        scheduledDocument.push({path: path, label: label, previousDrafts: previousDrafts});
        initWebViewer();
    }
};

const openReadOnlyPdfDocument = (path, label) => {
    isCurrentDocumentReadOnly = true;
    if ( pdftronInstance ) {
        viewPdfDocument(path, label);
    } else {
        scheduledDocument.push({path: path, label: label, readOnly: true});
        initWebViewer();
    }
};

const viewPdfDocument = (path, label) => {
    if (pdftronInstance) {
        const { Core } = pdftronInstance;
        currentDocument = path;
        if ( label ) {
            currentDocumentLabel = label;
        } else {
            currentDocumentLabel = '';
        }
        console.log('Opening webviewer in ReadOnly mode...');
        Core.getDocumentViewers()[0].enableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().enableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
    
        // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
        versionsMenuBtn.disabled = true;
        // pdftronInstance.UI.disableElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE]);
        pdftronInstance.UI.disableElements(['ribbons','toolsHeader','stickyToolButton',
            'highlightToolButton','freeHandToolButton','freeHandHighlightToolButton','freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton', 
            'annotationCommentButton', 'annotationStyleEditButton','annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton','textSquigglyToolButton','textStrikeoutToolButton',
            'linkButton']);
        // load the main document
        loadPdfDocumentTask(path, label);
    }
};


const loadPdfDocument = (path, label, previousDrafts) => {
    if (pdftronInstance) {
        const { Core } = pdftronInstance;
        currentDocument = path;
        if ( label ) {
            currentDocumentLabel = label;
        } else {
            currentDocumentLabel = '';
        }
        let flabel;
        if ( Array.isArray(previousDrafts) && previousDrafts.length > 0 ) {
            currentPreviousVersions = [ ...previousDrafts];
            currentPreviousVersions.reverse();
            const hasVersions = buildVersionsMenu();
            if (hasVersions) {
                versionsMenuBtn.disabled = false;
                if (label) {
                    flabel = label + ' (Latest Draft)';
                }
            } else {
                pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
                versionsMenuBtn.disabled = true;
                if (label) {
                    flabel = label;
                }
            }
        } else {
            pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
            // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
            versionsMenuBtn.disabled = true;
            if ( label ) {
                flabel = label;
            }
        }
        Core.getDocumentViewers()[0].disableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().disableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        pdftronInstance.UI.enableElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE, 'ribbons','toolsHeader',
            'stickyToolButton','highlightToolButton','freeHandToolButton','freeHandHighlightToolButton','freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton',
            'annotationCommentButton', 'annotationStyleEditButton','annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton','textSquigglyToolButton','textStrikeoutToolButton',
            'linkButton']);
        
        // load the main document
        loadPdfDocumentTask(path, flabel);
    }
};

const loadPdfDocumentVersion = (path, vernum) => {
    // set the display mode
    const { Core } = pdftronInstance;
    // console.log('documentViewers: ' + Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode());
    const dv = Core.getDocumentViewers();
    // console.log('documentViewers: ' + dv.length);
    const dm = Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode();
    Core.getDocumentViewers()[1].getDisplayModeManager().setDisplayMode(new Core.DisplayMode(Core.getDocumentViewers()[1], dm.getMode()));
    Core.getDocumentViewers()[1].enableReadOnlyMode();
    Core.getDocumentViewers()[1].getDisplayModeManager().disableVirtualDisplayMode();
    Core.getDocumentViewers()[0].disableReadOnlyMode();
    let furl = '/file/' + UserInfo.info.sessionId + path;
    if (typeof currentDocumentLabel === 'string' && currentDocumentLabel.length > 0 && vernum ) {
        furl = '/file/alias/' + UserInfo.info.sessionId + path + '/' + currentDocumentLabel.replaceAll(/\//g, '_') + ' (' + vernum + ')';
    }
    currentVersion = path;
    currentVersionNumber = vernum;
    changingVersions = true;
    Core.getDocumentViewers()[1].loadDocument(furl, {l: uxLicense});
};

const buildVersionsMenu = () => {
    const { UI, Core } = pdftronInstance;
    //console.log('Previous drafts: ' + JSON.stringify(currentPreviousVersions));
    versionsMenu.innerHTML = '';
    
    let v = 0;
    // count the unlabel versions
    currentPreviousVersions.forEach( (item) => {
        if  ( Array.isArray(item) ) {
            v++;
        }
    });
    let hasVersion = false;
    //handle the single draft case for now
    currentPreviousVersions.forEach( (item,index) => {
        const btn = document.createElement('button');
        btn.classList.add('Button');
        btn.classList.add('ActionButton');
        btn.classList.add('row');
        // const fpath = Array.isArray(item) ? item[0].path : item.draft[0].path;
        const fpath = Array.isArray(item) ? 
            findDraftFile(currentDocument, currentDocumentLabel, item) : 
            findDraftFile(currentDocument, currentDocumentLabel, item.draft);
        if (fpath) {
            hasVersion = true;
            const fversion = Array.isArray(item) || !item.label ? 'Draft ' + v : item.label;
            btn.textContent = fversion;
            // console.log('Adding ' + fversion);
            btn.onclick = () => {
                closeCustomMenu();
                // if ( currentVersion !== item.path ) {
                if (multiviewerEnabled) {
                    console.log('Multiviewer mode already enabled...');
                    loadPdfDocumentVersion(fpath, fversion);
                } else {
                    scheduledVersion.push({ path: fpath, number: fversion });
                    multiviewerEnabled = true;
                    console.log('Enabling multiviewer mode...');
                    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

                    // don't need to close it
                    //UI.closeDocument().then(() => {
                    //    console.log('Closed document [' + currentDocument + ']');
                    //    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    //});
                    //
                }
                // }

            };
            versionsMenu.appendChild(btn);
            if (Array.isArray(item)) {
                v--;
            }
        }
    });
    return hasVersion;
};

// try to find the file that matches current file
// first try matching the label, if not get the longest file name match
const findDraftFile = (path, label, drafts) => {
    if ( !Array.isArray(drafts) || !path) {
        return undefined;
    }
    // normalize
    if ( label ) {
        const nlabel = label.toLowerCase().replace(/\s+/g, ' ').trim();
        let fpath;
        for(let i=0; i<drafts.length; i++) {
            if ( !drafts[i].label ) {
                continue;
            }
            const ilabel = drafts[i].label.toLowerCase().replace(/\s+/g, ' ').trim();
            if ( nlabel === ilabel) {
                // found a matching label
                fpath = drafts[i].path;
                break;
            }
        }
        if ( fpath ) {
            return fpath;
        }
    }
    // no label match, try matching paths
    // disable for now
    /*
    let j = path.lastIndexOf('/');
    const name = j >= 0 ? path.substring(j+1) : path;
    let fpath;
    let max = -1;
    for(let i=0; i<drafts.length; i++) {
        if ( !drafts[i].path ) {
            continue;
        }
        j = drafts[i].path.lastIndexOf('/');
        const fname = j >= 0 ? drafts[i].path.substring(j+1) : drafts[i].path;
        const fmax = findMaxMatchingLength(name, fname);
        if ( fmax > max ) {
            fpath = drafts[i].path;
            max = fmax;
        }
    }
    return fpath;
    */
   return undefined;
};

const findMaxMatchingLength = (s1, s2) => {
    let i = 0;
    for(; i<s1.length && i<s2.length ; i++) {
        if ( s1.charAt(i) !== s2.charAt(i) ) {
            break;
        }
    }
    return i;
};

// Deprecated, open 2 documents in multiviewer mode
const openPdfDocumentCompare = (path1, path2) => {
    const { UI, Core } = pdftronInstance;
    const { annotationManager } = Core;
    annotationManager.setCurrentUser(UserInfo.info.name);

    UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
        const dm = Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().setDisplayMode(dm);

        Core.getDocumentViewers()[0].loadDocument('/file/' + UserInfo.info.sessionId + path1);
        Core.getDocumentViewers()[1].loadDocument('/file/' + UserInfo.info.sessionId + path2);
    })
    UI.enableFeatures([UI.Feature.MultiViewerMode]);
    // change the page index to display our webviewer
    // page 1 is reserved for the webviewer
    postal.publish({
        topic: "app.page.change.index",
        data: {
            index: 1
        }
    });
};

const closePdfViewer = () => {
    // this should close the document as well
    // since we're listening to the page index change to do so
    postal.publish({
        topic: "app.page.change.index",
        data: {
            index: 0
        }
    });

};

const loadPdfDocumentTask = (path, alias) => {
    if (pdftronInstance) {
        const { UI, Core } = pdftronInstance;
        

        const { annotationManager } = Core;
        annotationManager.setCurrentUser(UserInfo.info.name);

        UI.closeDocument().then(() => {
            currentDocument = path;
            // register the file first before opening, just in case
            netGet('/api/webviewer/document/' + UserInfo.info.sessionId + currentDocument)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Status: ' + response.status);
                    }
                    const furl = alias ?
                        '/file/alias/' +  UserInfo.info.sessionId + path + '/' + alias.replaceAll(/\//g, '_') :
                        '/file/' + UserInfo.info.sessionId + path;
                    console.log('Load PDF: ' + furl);
                    UI.loadDocument(furl,{l: uxLicense});
                }).catch ( error => {
                    console.log('Failed to register webviewer document: ' + error);
                    // open it anyway
                    const furl = alias ?
                        '/file/alias/' +  UserInfo.info.sessionId + path + '/' + alias :
                        '/file/' + UserInfo.info.sessionId + path;
                    UI.loadDocument(furl, {l: uxLicense});
                });
        });
        
        // change the page index to display our webviewer
        // page 1 is reserved for the webviewer
        postal.publish({
            topic: "app.page.change.index",
            data: {
                index: 1
            }
        });
    }
};


const PdftronWebViewer = (props) => {
    const viewer = useRef(null);

    React.useEffect(() => {

        const subscription = postal.subscribe({
            topic: "app.page.change.index.notify",
            callback: (data, envelope) => {
                // console.log('Got message: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
                handlePostalPageChangeIndex(data);
            }
        });

        return () => {
            subscription.unsubscribe();
        }

    }, []);

    React.useEffect(() => {

        const subscription = postal.subscribe({
            topic: "webviewer.initialized",
            callback: (data, envelope) => {
                // console.log('Got message: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
                handlePostalWebviewerInitialized(data);
            }
        });

        return () => {
            subscription.unsubscribe();
        }

    }, []);

    const handlePostalPageChangeIndex = (msg) => {
        if (msg.index !== 1 && pdftronInstance) {
            // close the current document if webviewer page is closed
            const { UI, Core } = pdftronInstance;
            if (multiviewerEnabled) {
                // UI.setNotesPanelSortStrategy(UI.NotesPanelSortStrategy.CREATED_DATE, false);
                UI.setCustomPanel({
                    uniscope: {
                        type: 'notesInLeftPanel',
                        notesInLeftPanel: false,
                    }
                });
                setTimeout( () => {
                    UI.enableElements(['toggleNotesButton']);
                    UI.openElements(['notesPanel']);
                    UI.closeElements(['multiRightPanel', 'multiNotesPanel']);
                    UI.closeDocument().then(() => {
                        console.log('Closed document [' + currentDocument + ']');
                        UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                        multiviewerEnabled = false;
                        removeViewer();
                    });
                }, 200);
                /*
                Core.getDocumentViewers()[1].closeDocument().then(() => {
                    console.log('Closed ' + currentVersionNumber + ' : ' + currentVersion);
                    UI.closeDocument().then(() => {
                        console.log('Closed document [' + currentDocument + ']');
                        UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                        multiviewerEnabled = false;
                    }).catch( error => {
                        console.log('Error closing document ' + currentDocument + ' : ' + error);
                        UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                        multiviewerEnabled = false;
                    });
                }).catch ( error => {
                    console.log('Error closing version ' + currentVersionNumber + ' : ' + currentVersion + ' : ' + error);
                    UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    multiviewerEnabled = false;
                });
                */
            } else {
                UI.closeDocument().then(() => {
                    console.log('Closed document [' + currentDocument + ']');
                    UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    multiviewerEnabled = false;
                    removeViewer();
                });
            }
        }
    };

    const removeViewer = () => {
        /*
        let viewerContainer = document.getElementById('pdftron-viewer-container');
        viewerContainer.innerHTML = '';
        pdftronInstance.
        pdftronInstance = undefined;
        */
    };

    const handlePostalWebviewerInitialized = (msg) => {
        if (scheduledDocument.length > 0) {
            const job = scheduledDocument.pop();
            if (job) {
                if ( job.readOnly ) {
                    viewPdfDocument(job.path, job.label);
                } else {
                    loadPdfDocument(job.path, job.label, job.previousDrafts);
                }
            }
        }
    }


    const annotMessage = (message) => {
        let wevent = message.data;
        // console.log('Received collab message: ' + JSON.stringify(wevent));
        const msg = wevent.data;
        if (pdftronInstance) {
            if (msg) {
                if (msg.docPath ) {
                    if ( msg.docPath === currentDocument ) {
                        processAnnotations(msg);
                    } else {
                        console.log('Skipping annotations for different document ' + msg.docPath);
                    }
                    
                } else {
                    console.log('No target document in annotation message, processing anyway...');
                    processAnnotations(msg);
                }
            }
        }
    };

    const processAnnotations = (msg) => {
        const { annotationManager } = pdftronInstance.Core;

        if (msg.add) {
            annotationManager.importAnnotationCommand(msg.add).then(function (annotations) {
                annotations.forEach(function (item, index) {
                    annotationManager.redrawAnnotation(item);
                });
            });

        }
        if (msg.modify) {
            annotationManager.importAnnotationCommand(msg.modify).then(function (annotations) {
                annotations.forEach(function (item, index) {
                    annotationManager.redrawAnnotation(item);
                });
            });
        }
        if (msg.remove) {
            annotationManager.importAnnotationCommand(msg.remove);
        }
    };

    MessageHooks["collab"]["webviewer"] = annotMessage;


    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', zIndex: 100 }}>
            { /* <div id="pdftron-viewer" className="webviewer" ref={viewer}></div> */ }
            <div id="pdftron-viewer-container" ref={viewer} style={{width: "100%", height: "100%"}}/>
        </Box>
    );


};

export { PdftronWebViewer, pdftronInstance, initWebViewer, openPdfDocument, openReadOnlyPdfDocument, openPdfDocumentCompare, closePdfViewer };
