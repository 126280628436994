import React, { Component } from "react";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { netGet } from '../network';

function WorkflowCheckinDialog(props) {
    const {
        title,
        open,
        workflowName,
        onSave,
        onCancel,
        workflowIndex,
        ...other
    } = props;
    const [message, setMessage] = React.useState('');


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(message, workflowIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const messageChanged = (event) => {
        setMessage(event.target.value);
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <TextField label="Checkin message" value={message} onChange={messageChanged} size="small" fullWidth autoFocus={true} 
                        multiline rows={3} />
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} >Checkin</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function WorkflowRevokeCheckoutDialog(props) {
    const {
        title,
        open,
        workflowName,
        onSave,
        onCancel,
        workflowIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(workflowIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography>All changes since last checkout will be lost.</Typography>
                    <Typography>Do you want to continue?</Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} color="error">Revoke Checkout</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function WorkflowDeleteDialog(props) {
    const {
        title,
        open,
        workflowName,
        onSave,
        onCancel,
        workflowIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(workflowIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography sx={{display: 'inline'}}>Delete the </Typography><Typography sx={{display: 'inline', fontWeight: 'bold'}}>{workflowName}</Typography><Typography  sx={{display: 'inline'}}> workflow?</Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} color="error">Delete</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}


function WorkflowSaveDialog(props) {
    const {
        title,
        open,
        workflowName,
        onSave,
        onDiscard,
        onCancel,
        workflowIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(workflowIndex);
        }
    }

    const onDiscardClick = () => {
        if ( typeof onDiscard === 'function' ) {
            onDiscard(workflowIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography sx={{display: 'inline'}}>Save the </Typography><Typography sx={{display: 'inline', fontWeight: 'bold'}}>{workflowName}</Typography><Typography  sx={{display: 'inline'}}> workflow?</Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}}>Save</Button>
                <Button variant="outlined" onClick={onDiscardClick} sx={{minWidth: '7em'}} color="error">Discard</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}




function StepDeleteDialog(props) {
    const {
        title,
        open,
        stepName,
        workflowName,
        onSave,
        onCancel,
        stepIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(stepIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography sx={{display: 'inline'}}>Delete the </Typography><Typography sx={{display: 'inline', fontWeight: 'bold'}}>{stepName}</Typography>
                    <Typography sx={{display: 'inline'}}> step from </Typography><Typography sx={{display: 'inline', fontWeight: 'bold'}}>{workflowName}</Typography><Typography  sx={{display: 'inline'}}>?</Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} color="error">Delete</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function CreateTemplateWorkflowDialog(props) {
    const {
        title,
        template,
        name,
        open,
        description,
        onSave,
        onCancel,
        templateList,
        editOnly = false,
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [templateValue, setTemplateValue] = React.useState(null);
    const [templateTypeValue, setTemplateTypeValue] = React.useState('MODEL');
    const [nameFocused, setNameFocused] = React.useState(false);
    const [okEnabled, setOkEnabled] = React.useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CreateMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    React.useEffect( () => {
        setNameValue(name);
        setDescriptionValue(description);
        setNameFocused(false);
        setOkEnabled(editOnly && name && typeof name == 'string' && name.length > 0);
        if ( Array.isArray(template) && template.length > 0) {
            setTemplateValue(template[0]);
        } else {
            setTemplateValue(template);
        }
    }, [name,description,open,template]);


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(nameValue, descriptionValue, templateValue, templateTypeValue);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        if ( event.target.value && typeof event.target.value === 'string' && event.target.value.length > 0 ) {
            setOkEnabled(true);
        }
        setNameValue(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    const handleTemplateChange = (event) => {
        if ( templateValue === nameValue ) {
            setNameValue(event.target.value);
        }
        setTemplateValue(event.target.value);
    };

    const handleTemplateTypeChange = (event) => {
        setTemplateTypeValue(event.target.value);
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent> 
                <Box sx={{paddingTop: '2ex'}}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth autoFocus={true} 
                        onFocus={event => {
                            event.target.select();
                          }} />
                </Box>
                <Box sx={{paddingTop: '3ex'}}>
                    <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                        multiline fullWidth
                        rows={3} />
                </Box>
                {!editOnly &&
                    <React.Fragment>
                        <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', width: '6.2em'}}>Type:</Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    value={templateTypeValue}
                                    onChange={handleTemplateTypeChange}
                                    sx={{ minWidth: '16em' }}
                                    MenuProps={CreateMenuProps}
                                >
                                    <MenuItem
                                        key="mstt-0"
                                        value="MODEL"
                                    >
                                        Worflow
                                    </MenuItem>
                                    <MenuItem
                                        key="mstt-1"
                                        value="MODEL_SUB"
                                    >
                                        Subworkflow
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', width: '6.2em' }}>Template:</Typography>
                            {Array.isArray(template) ?

                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Select
                                        value={templateValue}
                                        onChange={handleTemplateChange}
                                        sx={{ minWidth: '16em' }}
                                        MenuProps={CreateMenuProps}
                                    >
                                        {template.map((sitem, index) => (
                                            <MenuItem
                                                key={'msi-' + index}
                                                value={sitem}

                                            >
                                                {sitem}
                                            </MenuItem>
                                        )
                                        )}
                                    </Select>
                                </FormControl>

                                :

                                <Typography sx={{ paddingLeft: '1em' }}>{template}</Typography>
                            }
                        </Box>
                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function CreateStepWorkflowDialog(props) {
    const {
        title,
        template,
        name,
        subroutine,
        form,
        open,
        description,
        onSave,
        onCancel,
        stepList,
        step,
        editOnly = false,
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [templateValue, setTemplateValue] = React.useState(null);
    const [nameFocused, setNameFocused] = React.useState(false);
    const [subroutines, setSubroutines] = React.useState([]);
    const [subroutineValue, setSubroutineValue] = React.useState('');
    const [forms, setForms] = React.useState([]);
    const [formValue, setFormValue] = React.useState('');

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CreateMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    React.useEffect( () => {
        setNameValue(name);
        setDescriptionValue(description);
        setSubroutineValue(subroutine ? subroutine : '');
        setFormValue( form ? form : (subroutine ? subroutine : ''));
        const tv = Array.isArray(template) ? template[0] : template;
        setTemplateValue(tv);
        setNameFocused(false);
        // console.log('Subroutine: ' +subroutine);
    }, [name,description,open,template,subroutine,form]);

    React.useEffect(() => {
        netGet('/api/workflow/def/list?categ=MODEL_SUB')
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    console.log('Subroutine Definitions: ' + data.length);
                    setSubroutines(data);
                }
            }).catch(error => {
                console.log(`Error fetching subroutines: ${error}`);
            });
        loadForms();
    }, [open]);

    const loadForms = () => {
        netGet('/api/workflow/form/list')
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    // console.log('Forms: ' + data.length);
                    setForms(data);
                    
                }
            });
    };


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(nameValue, descriptionValue, templateValue, templateValue === 'FORM' ? formValue : subroutineValue);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        setNameValue(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    const handleTemplateChange = (event) => {
        if ( templateValue === nameValue ) {
            setNameValue(event.target.value);
        }
        setTemplateValue(event.target.value);
    };

    const handleSubroutineChange = (event) => {
        setSubroutineValue(event.target.value);
        const sub = subroutines.find( item => item.name === event.target.value);
        if ( sub ) {
            setNameValue(sub.label);
        }
    };

    const handleFormChange = (event) => {
        setFormValue(event.target.value);
        const f = forms.find( item => item.name === event.target.value);
        if ( f ) {
            setNameValue(f.label);
        }
    };

    const getTemplateLabel = (tvalue) => {
        const step = stepList.find( item => item.name === tvalue);
        if ( step ) {
            return step.label;
        }
        return tvalue;
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent> 
                <Box sx={{paddingTop: '2ex'}}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth autoFocus={true} 
                        onFocus={event => {
                            event.target.select();
                          }} />
                </Box>
                <Box sx={{paddingTop: '3ex'}}>
                    <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                        multiline fullWidth
                        rows={3} />
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '7em' }}>Step Type:</Typography>
                    {Array.isArray(stepList) && editOnly ?

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                value={templateValue}
                                onChange={handleTemplateChange}
                                sx={{ minWidth: '16em' }}
                                MenuProps={CreateMenuProps}
                            >
                                {stepList.map((sitem, index) => (
                                    <MenuItem
                                        key={'msi-' + index}
                                        value={sitem.name}

                                    >
                                        {sitem.label}
                                    </MenuItem>
                                )
                                )}
                            </Select>
                        </FormControl>

                        :

                        <Typography sx={{ paddingLeft: '1em', fontWeight: 'bold' }}>{getTemplateLabel(templateValue)}</Typography>
                    }
                </Box>
                {templateValue === 'CALL' &&
                    <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', width: '7em' }}>Subworkflows:</Typography>
                        {Array.isArray(subroutines) ?

                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    value={subroutineValue}
                                    onChange={handleSubroutineChange}
                                    sx={{ minWidth: '16em' }}
                                    MenuProps={CreateMenuProps}
                                >
                                    {subroutines.map((sitem, index) => (
                                        <MenuItem
                                            key={'msiv-' + index}
                                            value={sitem.name}

                                        >
                                            {sitem.label}
                                        </MenuItem>
                                    )
                                    )}
                                </Select>
                            </FormControl>

                            :

                            <Typography sx={{ paddingLeft: '1em' }}>{subroutines}</Typography>
                        }
                    </Box>
                }
                {templateValue === 'FORM' &&
                    <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', width: '7em' }}>Form:</Typography>
                        {Array.isArray(forms) ?

                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    value={formValue}
                                    onChange={handleFormChange}
                                    sx={{ minWidth: '16em' }}
                                    MenuProps={CreateMenuProps}
                                >
                                    {forms.map((sitem, index) => (
                                        <MenuItem
                                            key={'mfiv-' + index}
                                            value={sitem.name}

                                        >
                                            {sitem.label}
                                        </MenuItem>
                                    )
                                    )}
                                </Select>
                            </FormControl>

                            :

                            <Typography sx={{ paddingLeft: '1em' }}>{forms}</Typography>
                        }
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function CreateWorkflowDialog(props) {
    const {
        title,
        template,
        name,
        open,
        documentList,
        description,
        onSave,
        onCancel,
        editOnly = false,
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState('');
    const [descriptionValue, setDescriptionValue] = React.useState('');
    const [templateValue, setTemplateValue] = React.useState(null);
    const [typeValue, setTypeValue] = React.useState('custom');
    const [nameFocused, setNameFocused] = React.useState(false);
    const [docList, setDocList] = React.useState([]);
    const [docValue, setDocValue] = React.useState('');

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CreateMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const TypeMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 160,
            },
        },
    };

    const DocMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minWidth: 300,
            },
        },
    };

    React.useEffect( () => {
        setNameValue(name);
        setDescriptionValue(description);
        if ( Array.isArray(template) ) {
            setTemplateValue(template[0]);
        } else {
            setTemplateValue(template);
        }
        setNameFocused(false);
    }, [name,description,open,template]);

    React.useEffect( () => {
        /*
        netGet('/api/doc/roots?sort=alpha')
            .then(response => response.json())
            .then(docs => {
                if ( Array.isArray(docs) ) {
                    setDocList(docs);
                    setDocValue(docs.length > 0 ? docs[0].id : '');
                }
            }).catch ( error => {
                console.log('Error fetching profiles: ' + error);
            });
        */
       if ( Array.isArray(documentList) ) {
            setDocList(documentList);
            setDocValue(documentList.length > 0 ? documentList[0].id : '');
       }
    },[documentList]);

    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            const doc = docList.find ( item => item.id === docValue);
            onSave( {
                name: nameValue,
                description: descriptionValue,
                template: templateValue,
                type: typeValue,
                document: docValue,
                documentTitle: doc ? doc.title : null,
            });
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        setNameValue(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    const handleTemplateChange = (event) => {
        setTemplateValue(event.target.value);
    };

    const handleTypeChange = (event) => {
        setTypeValue(event.target.value);
    };

    const handleDocChange = (event) => {
        const docId = event.target.value;
        setDocValue(docId);

        const doc = docList.find ( item => item.id === docId);
        if ( doc && (typeof nameValue === 'undefined' || nameValue === '')) {
            setNameValue(doc.title);
        }
    }

    return (
        <Dialog
            maxWidth={'1200px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 800,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth
                        />
                </Box>
                <Box sx={{paddingTop: '3ex'}}>
                    <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                        multiline fullWidth
                        rows={3} />
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center', justifyContent: typeValue === 'doc' ? 'space-between' : 'left' }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography sx={{ fontWeight: 'bold', width: '6em' }}>Type:</Typography>
                    <FormControl sx={{ m: 1, minWidth: 120, marginLeft: 0 }} size="small">
                        <Select
                            value={typeValue}
                            onChange={handleTypeChange}
                            sx={{ minWidth: '160px' }}
                            MenuProps={TypeMenuProps}
                        >
                            <MenuItem key='custom'
                                value='custom'>
                                Custom
                            </MenuItem>
                            <MenuItem key='doc'
                                value='doc'>
                                Document
                            </MenuItem>
                        </Select>
                    </FormControl>
                    </Box>

                    { typeValue === 'doc' && 
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{ fontWeight: 'bold', minWidth: '6em' }}>Document:</Typography>
                            <FormControl sx={{ m: 1, width: '300px', minWidth: '260px', marginLeft: 0 }} size="small">
                            <Select
                                value={docValue}
                                onChange={handleDocChange}
                                sx={{ minWidth: '260px', width: '300px', }}
                                MenuProps={DocMenuProps}
                            >
                                {
                                    docList.map(doc =>
                                    (<MenuItem key={doc.id} value={doc.id}>{doc.title}</MenuItem>)
                                    )
                                }
                            </Select>
                        </FormControl>
                        </Box>
                    }
                    
                </Box>
                
                <Box sx={{display: 'flex', paddingTop: '2em', alignItems: 'center'}}>
                    <Typography sx={{fontWeight: 'bold', width: '5.5em' }}>Template:</Typography>
                    { Array.isArray(template) ? 

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                value={templateValue}
                                onChange={handleTemplateChange}
                                sx={{ minWidth: '16em' }}
                                MenuProps={CreateMenuProps}
                            >
                                {template.map((templateName) => (
                                    <MenuItem
                                        key={'cswd' + templateName}
                                        value={templateName}
                                    >
                                        {templateName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        :
                    
                        <Typography sx={{paddingLeft: '1em'}}>{template}</Typography>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

export { WorkflowCheckinDialog, WorkflowRevokeCheckoutDialog, WorkflowDeleteDialog, WorkflowSaveDialog, StepDeleteDialog, CreateStepWorkflowDialog, CreateTemplateWorkflowDialog, CreateWorkflowDialog };