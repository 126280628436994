import React, { Component } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Icon, Typography } from "@mui/material";

const EditedRowContext = React.createContext({
    row: '',
    setRow: (newrow) => {},
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, minHeight: '666.5px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function EventRow(props) {
    const { row } = props;
    const editedRow = React.useContext(EditedRowContext);
    // console.log('My row: ' + row.id + ', Editing row: ' + editedRow.row);
    const [ucs, setUcs] = React.useState( row.ucs );
    const [currentUcs, setCurrentUcs] = React.useState( row.ucs );
    const [messenger, setMessenger] = React.useState( row.messenger );
    const [currentMessenger, setCurrentMessenger] = React.useState( row.messenger );
    const [edit, setEdit] = React.useState(row.id === editedRow.row); // default value only evaluates first time, need to use it in children
    

    const handleEditClick = (event) => {
        setCurrentUcs(ucs);
        setCurrentMessenger(messenger);
        setEdit(true);
        editedRow.setRow(row.id);
    };
    
    const handleCancelClick = (event) => {
        setEdit(false);
        setUcs(currentUcs);
        setMessenger(currentMessenger);
    };

    const handleSaveClick = (event) => {
        setEdit(false);
    };

    const handleUcsChange = (event) => {
        setUcs(event.target.value);
    };

    const handleMessengerChange = (event) => {
        setMessenger(event.target.value);
    };

    return (
        <TableRow >
            <TableCell sx={{ width: '2.5em' }} />
            <TableCell sx={{ width: 'calc(100% - 33.5em)' }}>{row.name}</TableCell>
            <TableCell align="center" sx={{ width: '11.65em' }}>
                <Typography sx={{fontSize: '0.99em', display: edit && row.id === editedRow.row ? 'none' : 'inline'}} >{ucs}</Typography>
                <FormControl sx={{ m: 1, minWidth: 120,  display: edit && row.id === editedRow.row ? 'block' : 'none'}} size="small">
                    <Select
                        sx={{fontSize: '0.95em'}}
                        value={ucs}
                        onChange={handleUcsChange}
                    >
                        <MenuItem value="ERROR"  sx={{fontSize: '0.95em'}} >ERROR</MenuItem>
                        <MenuItem value="INFO"   sx={{fontSize: '0.95em'}} >INFO</MenuItem>
                        <MenuItem value="SILENT" sx={{fontSize: '0.95em'}}>SILENT</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center" sx={{ width: '11.65em' }}>
                <Typography sx={{fontSize: '0.99em', display: edit && row.id === editedRow.row ? 'none' : 'inline'}} >{messenger}</Typography>
                <FormControl sx={{ m: 1, minWidth: 120, display: edit && row.id === editedRow.row ? 'block' : 'none'}} size="small">
                    <Select
                        sx={{fontSize: '0.95em'}}
                        value={messenger}
                        onChange={handleMessengerChange}
                    >
                        <MenuItem value="ERROR"  sx={{fontSize: '0.95em'}}>ERROR</MenuItem>
                        <MenuItem value="INFO"   sx={{fontSize: '0.95em'}}>INFO</MenuItem>
                        <MenuItem value="SILENT" sx={{fontSize: '0.95em'}}>SILENT</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="left" sx={{ width: '7em' }}>
                <Button onClick={handleEditClick} sx={{display: edit && row.id === editedRow.row ? 'none' : 'inline'}} >Edit</Button>
                <Button onClick={handleSaveClick} sx={{display: edit && row.id === editedRow.row ? 'inline' : 'none'}} >Save</Button>
                <IconButton 
                    size="small"
                    color="error"
                    sx={{position: 'relative', top: 2, display: edit && row.id === editedRow.row ? 'inline' : 'none'}}
                    onClick={handleCancelClick} >
                        <CloseIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(true);
    
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.ucs}</TableCell>
                <TableCell align="right">{row.messenger}</TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit >
                            <Table size="small" aria-label="events">
                                <TableBody>
                                    {row.events.map((eventRow) => (
                                        <EventRow row={eventRow} />
                                    ))}
                                </TableBody>
                            </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
        ucs: PropTypes.string,
        messenger: PropTypes.string,
        events: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                ucs: PropTypes.string.isRequired,
                messenger: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

function createEventData(id, name, ucs, messenger) {
    return {
        id,
        name,
        ucs,
        messenger
    }
}

function createRowData(name, ucs, messenger, events) {
    return {
        name,
        ucs,
        messenger,
        events,
    };
}

const rows = [
    createRowData('Databases','','',
        [
            createEventData('E1', 'Airports: New Data (when a new airport is added)', 'INFO', 'ERROR'),
            createEventData('E2', 'Airports: Data Modified (when Modify is saved)', 'INFO', 'ERROR'),
            createEventData('E3', 'Airports: Data Deleted', 'INFO', 'ERROR'),
        ]
    ),
    createRowData('Digital Logistics','','',
        [
            createEventData('E4', 'Reception: Document Arrival', 'INFO', 'ERROR'),
        ]
    ),
    createRowData('Workflow','','',
        [
            createEventData('E5', 'Open Edition: Start', 'INFO', 'ERROR'),
            createEventData('E6', 'Open Edition: Completion/Failure', 'INFO', 'ERROR'),
            createEventData('E7', 'Draft Output: Start', 'INFO', 'ERROR'),
            createEventData('E8', 'Draft Output: Completion/Failure', 'INFO', 'ERROR'),
            createEventData('E9', 'Edition Output: Start', 'INFO', 'ERROR'),
            createEventData('E10', 'Edition Output: Completion/Failure', 'INFO', 'ERROR'),
        ]
    ),
];

function CollapsibleTable() {
    const [editedRow, setEditedRow] = React.useState('');

    
    return (
        <EditedRowContext.Provider value={{row: editedRow, setRow: setEditedRow}}>
        <TableContainer component={Paper} sx={{overflowY: 'auto', maxHeight: 600}}>
            <Table aria-label="collapsible table" stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: '1.5em'}}/>
                        <TableCell sx={{width: 'calc(100% - 28.5em)', fontWeight: 'bold', fontSize: '1.02em'}}>Events</TableCell>
                        <TableCell align="center" sx={{width: '10em', fontWeight: 'bold', fontSize: '1.02em'}}>UCS</TableCell>
                        <TableCell align="center" sx={{width: '10em', fontWeight: 'bold', fontSize: '1.02em'}}>Messenger</TableCell>
                        <TableCell align="right" sx={{width: '7em'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </EditedRowContext.Provider>
    );
}

function PreferencesDialog(props) {
    const { onClose, open, ...other } = props;
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [tabValue, setTabValue] = React.useState(0);
    

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  
    

    const handleMaxWidthChange = (event) => {
      setMaxWidth(
        // @ts-expect-error autofill of arbitrary value is not handled.
        event.target.value,
      );
    };
  
    const handleFullWidthChange = (event) => {
      setFullWidth(event.target.checked);
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
  
    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            
            maxWidth={maxWidth}
            minHeight={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  maxHeight: 900
                }
              }}
        >
            <DialogTitle>Preferences</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="preferences tabs">
                        <Tab label="Notifications" {...a11yProps(0)} />
                        <Tab label="Security" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    
                    <FormControlLabel control={<Switch defaultChecked />} label="Enable Notifications & Alerts" labelPlacement="start" sx={{paddingBottom: '1.4em',}}/>
                    
                    <Divider/>

                    <CollapsibleTable />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Typography>Secured</Typography>
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
  }

  export { PreferencesDialog } ;