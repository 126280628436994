import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';


import AddBoxIcon from '@mui/icons-material/AddBox';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import DatasetIcon from '@mui/icons-material/Dataset';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import OutputIcon from '@mui/icons-material/Output';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PreviewIcon from '@mui/icons-material/Preview';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";
import { fabClasses } from "@mui/material";
//import { WorkflowCheckinDialog, WorkflowRevokeCheckoutDialog, WorkflowDeleteDialog, 
//    StepDeleteDialog, CreateWorkflowDialog, WorkflowSaveDialog } from "./management-workflow-dialogs";

const TYPE_LABELS = {
    TEXT: 'Text Field',
    TEXTFIELD: 'Text Field',
    TEXTAREA: 'Text Box',
    CHECKBOX: 'Checkbox',
    CHECKBOX_GROUP: 'Checkbox Group',
    RADIOBUTTON_GROUP: 'Radio Button Group',
    SELECTION: 'Selection',
    PREDEFINED: 'Predefined Field',
};

const PREDEFINED_FIELDS = [
    { label: 'Document Selection', type: 'SELECTION', name: 'documentName'},
    { label: 'Revision Number', type: 'TEXTFIELD', name: 'revisionNumber'},
    // { label: 'Revision Date', type: 'DATE', name: 'revisionDate'},
];

const FIRST_REVISION = '19';

function EvaluateFormDialog(props) {
    const {
        form,
        open,
        onSave,
        onCancel,
        ...other
    } = props;

    const [fieldValues, setFieldValues] = React.useState([]);
    const [docList, setDocList] = React.useState([]);

    React.useEffect(() => {
        if (form) {
            let fv = [];
            form.fields.forEach((item, index) => {
                if (typeof item?.value !== 'undefined') {
                    if ( item?.type === 'CHECKBOX_GROUP' ) {
                        if ( item?.properties?.selectionData ) {
                            let cv = {};
                            const iv = item.value;
                            item.properties.selectionData.forEach( item => {
                                if ( iv[item.value] ) {
                                    cv[item.value] = iv[item.value];
                                } else {
                                    cv[item.value] = false;
                                }
                            });
                            fv.push(cv);
                        } else {
                            fv.push({});
                        }
                    } else {
                        fv.push(item.value);
                    }
                    // console.log(`FIELD ${item.name}: ${item.value}`);
                } else if ( item?.type === 'CHECKBOX' ) {
                    fv.push(false);
                } else if ( item?.type === 'CHECKBOX_GROUP' ) {
                    if ( item?.properties?.selectionData ) {
                        let cv = {};
                        item.properties.selectionData.forEach( item => {
                            cv[item.value] = false;
                        });
                        fv.push(cv);
                    } else {
                        fv.push({});
                    }
                } else {
                    fv.push('');
                }
                if ( item?.type === 'PREDEFINED' && item?.name === 'revisionNumber' && typeof item?.value === 'undefined') {
                        fv[index] = FIRST_REVISION;
                }
                if ( item?.type === 'PREDEFINED' && item?.name === 'documentName' && typeof item?.value === 'undefined') {
                    fv[index] = '';
                }
                if (item.properties?.selectionListType === 'DOCLIST' || item.properties?.selectionListType === 'documentName'
                    || item.properties?.variableListType === 'documentName' || (item?.type === 'PREDEFINED' && item?.name === 'documentName')) {
                    netGet('/api/doc/roots?sort=alpha')
                        .then(response => response.json())
                        .then(docs => {
                            if (Array.isArray(docs)) {
                                // console.log('Got ' + docs.length + ' documents.');
                                setDocList(docs);
                            }
                        }).catch(error => {
                            console.log('Error fetching document list: ' + error);
                        });
                }
                console.log('FIELD ' + item?.name + ' = ' + fv[index]);
            });
            setFieldValues(fv);
        } else {
            setFieldValues([]);
        }

    }, [form, open]);

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            let fv = {};
            form.fields.forEach((item, index) => {
                fv[item.name] = fieldValues[index];
                if ( item.type === 'CHECKBOX' ) {
                    if ( typeof fieldValues[index] === 'undefined') {
                        fv[item.name] = false;
                    }
                }
            });
            onSave(fv);
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const onValueChanged = (value, index) => {
        let fv = [...fieldValues];
        fv[index] = value;
        setFieldValues(fv);
    };

    const handleCheckboxGroupChange = (val,index,cval) => {
        let cv = fieldValues[index];
        cv[cval] = val;
        onValueChanged(cv, index);
    };


    return (
        <Dialog
            maxWidth={'1200px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 700,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{form?.label}</DialogTitle>
            <DialogContent sx={{ paddingBottom: '1ex' }}>
                {form && form.fields.map((field, index) =>
                    <Box sx={{ paddingTop: '2ex' }}>
                        {(field.type === 'TEXT' || field.type === 'TEXTFIELD' || (field.type === 'PREDEFINED' && (field.name === 'revisionNumber' || field.properties?.predefined?.type === 'TEXTFIELD'))) &&
                            <TextField label={field.label} value={typeof fieldValues[index] === 'undefined' ? '' : fieldValues[index]} onChange={(event) => onValueChanged(event.target.value, index)}
                                size="small" fullWidth required={field.required} />
                        }
                        {field.type === 'TEXTAREA' &&
                            <TextField label={field.label} value={typeof fieldValues[index] === 'undefined' ? '' : fieldValues[index]} onChange={(event) => onValueChanged(event.target.value, index)}
                                size="small" fullWidth multiline rows={3} required={field.required} />
                        }
                        {field.type === 'CHECKBOX' &&
                            <FormControlLabel control={<Checkbox checked={typeof fieldValues[index] === 'undefined' ? false : fieldValues[index]}
                                onChange={(event) => onValueChanged(event.target.checked, index)} />} label={field.label} required={field.required} />
                        }
                        {(field.type === 'SELECTION' || (field.type === 'PREDEFINED' && (field.name === 'documentName' || field.properties?.predefined?.type === 'SELECTION'))) &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <FormControl size="small" sx={{ width: '100%' }} required={field.required}>
                                    <InputLabel id={`field-select-label-${index}`} size="small">{field.label}</InputLabel>
                                    <Select
                                        labelId={`field-select-label-${index}`}
                                        id={`field-select-${index}`}
                                        value={fieldValues[index] ? fieldValues[index] : ''}
                                        label={field.required ? `${field.label} *` : field.label}
                                        onChange={(event) => onValueChanged(event.target.value, index)}
                                        required={field.required}
                                        size="small"
                                    >
                                        {field.type === 'SELECTION' && field.properties?.selectionData &&
                                            field.properties.selectionData.map((sitem, sindex) => (
                                                <MenuItem value={sitem.value} key={`${sitem.value}-${sindex}`}>{sitem.label ? sitem.label : sitem.value}</MenuItem>
                                            ))

                                        }
                                        {field.type === 'PREDEFINED' && (field.name === 'documentName' || field.properties?.selectionListType === 'DOCLIST' || field.properties?.selectionListType === 'documentName' ||
                                          field.properties?.variableListType === 'documentName') &&
                                            docList.map((doc, dindex) => (
                                                <MenuItem value={doc.id} key={doc.id}>{doc.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                        { field.type === 'RADIOBUTTON_GROUP' &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <FormControl>
                                    <FormLabel id={`form-radio-buttons-group-label-${field.name}`}>{field.required ? `${field.label} *` : field.label}</FormLabel>
                                    <RadioGroup
                                        row={field.properties?.orientation === 'H'}
                                        value={typeof fieldValues[index] === 'undefined' ? '' : fieldValues[index]}
                                        label={field.required ? `${field.label} *` : field.label}
                                        onChange={(event) => onValueChanged(event.target.value, index)}
                                        required={field.required}
                                        size="small"
                                    >
                                        {field.properties?.selectionData &&
                                            field.properties.selectionData.map((sitem, sindex) => (
                                                <FormControlLabel value={sitem.value} key={`${sitem.value}-${sindex}`} label={sitem.label ? sitem.label : sitem.value} 
                                                    control={<Radio />} />
                                            ))

                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        }
                        { field.type === 'CHECKBOX_GROUP' &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <FormControl>
                                    <FormLabel id={`form-checkbox-group-label-${field.name}`}>{field.required ? `${field.label} *` : field.label}</FormLabel>
                                    <FormGroup  sx={{flexDirection:  field.properties?.orientation === 'H' ? 'row' : 'column'}}>
                                        {field.properties?.selectionData &&
                                            field.properties.selectionData.map((sitem, sindex) => (
                                                <FormControlLabel key={`${sitem.value}-${sindex}`} label={sitem.label ? sitem.label : sitem.value} 
                                                    control={<Checkbox checked={fieldValues[index] ? fieldValues[index][sitem.value] : false} onChange={(event) => handleCheckboxGroupChange(event.target.checked, index, sitem.value)} 
                                                    name={`${field.name}-${sitem.value}`} />} 
                                                    />
                                            ))

                                        }
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        }
                    </Box>
                )
                }
                <Box sx={{ paddingTop: '3ex', paddingBottom: '0px' }}>
                    <Typography sx={{ fontStyle: 'italic', fontSize: '90%' }}>* indicates required input.</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} >OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );


}

function FormCheckinDialog(props) {
    const {
        title,
        open,
        formName,
        onSave,
        onCancel,
        formIndex,
        ...other
    } = props;
    const [message, setMessage] = React.useState('');


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(message, formIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const messageChanged = (event) => {
        setMessage(event.target.value);
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Checkin message" value={message} onChange={messageChanged} size="small" fullWidth autoFocus={true}
                        multiline rows={3} />
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} >Checkin</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function FormRevokeCheckoutDialog(props) {
    const {
        title,
        open,
        formName,
        onSave,
        onCancel,
        formIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(formIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography>All changes since last checkout will be lost.</Typography>
                    <Typography>Do you want to continue?</Typography>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="error">Revoke Checkout</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function CreateFormDialog(props) {
    const {
        title,
        name,
        open,
        description,
        onSave,
        onCancel,
        editOnly = false,
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [templateValue, setTemplateValue] = React.useState(null);
    const [templateTypeValue, setTemplateTypeValue] = React.useState('MODEL');
    const [nameFocused, setNameFocused] = React.useState(false);
    const [okEnabled, setOkEnabled] = React.useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CreateMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    React.useEffect(() => {
        setNameValue(name);
        setDescriptionValue(description);
        setNameFocused(false);
        setOkEnabled(editOnly && name && typeof name == 'string' && name.length > 0);

    }, [name, description, open]);


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(nameValue, descriptionValue);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        if (event.target.value && typeof event.target.value === 'string' && event.target.value.length > 0) {
            setOkEnabled(true);
        }
        setNameValue(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth autoFocus={true}
                        onFocus={event => {
                            event.target.select();
                        }} />
                </Box>
                <Box sx={{ paddingTop: '3ex' }}>
                    <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                        multiline fullWidth
                        rows={3} />
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function FormDeleteDialog(props) {
    const {
        title,
        open,
        formName,
        onSave,
        onCancel,
        formIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(formIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography sx={{ display: 'inline' }}>Delete the </Typography><Typography sx={{ display: 'inline', fontWeight: 'bold' }}>{formName}</Typography><Typography sx={{ display: 'inline' }}> form?</Typography>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="error">Delete</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

function AddSelectionValueDialog(props) {
    const {
        title,
        open,
        onSave,
        onCancel,
        ...other
    } = props;

    const [categValue, setCategValue] = React.useState('default');
    const [selectValue, setSelectValue] = React.useState('');
    const [labelValue, setLabelValue] = React.useState('');
    const [okEnabled, setOkEnabled] = React.useState(false);

    const sre = /\s+/g;

    React.useEffect(() => {
        setSelectValue('');
        setLabelValue('');

    }, [open]);


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(categValue, selectValue, labelValue);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const isValid = (val, label) => {
        return typeof val === 'string' && typeof label === 'string' && val !== '' && label !== '';
    };

    const categChanged = (event) => {
        setCategValue(event.target.value);
    };

    const selectChanged = (event) => {
        setSelectValue(event.target.value);
        setOkEnabled(isValid(event.target.value, labelValue));

    }

    const selectBlur = (event) => {
        if ((typeof labelValue === 'undefined' || labelValue === '') && typeof selectValue !== 'undefined') {
            setLabelValue(selectValue);
            setOkEnabled(isValid(selectValue, selectValue));
        }
    };


    const labelChanged = (event) => {
        const sl = event.target.value;
        const sv = sl.replace(sre, '_').toUpperCase();
        setLabelValue(sl);
        setSelectValue(sv);
        setOkEnabled(isValid(sv, sl));
    }

    const labelBlur = (event) => {
        if ((typeof selectValue === 'undefined' || selectValue === '') && typeof labelValue !== 'undefined') {
            const val = labelValue.replace(sre, '_').toUpperCase();
            setSelectValue(val);
            setOkEnabled(isValid(val, labelValue));
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Label" value={labelValue} onChange={labelChanged} size="small" fullWidth required={true} />
                </Box>
                {/* 
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Value" value={selectValue} onChange={selectChanged} size="small" fullWidth autoFocus={true}
                        onBlur={selectBlur} required={true} />
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Category" value={categValue} onChange={categChanged} size="small" fullWidth />
                </Box>
                */}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

function CreateFieldDialog(props) {
    const {
        title,
        field,
        open,
        onSave,
        onCancel,
        editOnly = false,
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState(null);
    const [nameDisabled, setNameDisabled] = React.useState(false);

    const [variableList, setVariableList] = React.useState(['']);
    const [variableListValue, setVariableListValue] = React.useState('');
    const [variableEnabled, setVariableEnabled] = React.useState(false);
    const [predefinedValue, setPredefinedValue] = React.useState({});
    const [variableLabelVisible, setVariableLabelVisible] = React.useState(true);
    
    const [labelValue, setLabelValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [placeholderValue, setPlaceholderValue] = React.useState(null);
    const [typeValue, setTypeValue] = React.useState(null);
    const [selectTypeVisible, setSelectTypeVisible] = React.useState(true);
    const [requiredValue, setRequiredValue] = React.useState(true);
    const [nameFocused, setNameFocused] = React.useState(false);
    const [okEnabled, setOkEnabled] = React.useState(false);
    const [placeholderEnabled, setPlaceholderEnabled] = React.useState(false);
    const [selectionData, setSelectionData] = React.useState([]);
    const [selectionIndex, setSelectionIndex] = React.useState(-1);
    const [deleteSelectionEnabled, setDeleteSelectionEnabled] = React.useState(false);
    const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);
    const [customSelectionEnabled, setCustomSelectionEnabled] = React.useState(true);
    const [orientationValue, setOrientationValue] = React.useState('H');
    const [orientationEnabled, setOrientationEnabled] = React.useState(false);

    const [moveUpItemEnabled, setMoveUpItemEnabled] = React.useState(false);
    const [moveDownItemEnabled, setMoveDownItemEnabled] = React.useState(false);

    const [addDialogTitle, setAddDialogTitle] = React.useState('Add Selection Item');
    const [addLabel, setAddLabel] = React.useState('Add Selection Item');
    const [deleteLabel, setDeleteLabel] = React.useState('Delete Selection Item');

    const [itemHeaderLabel, setItemHeaderLabel] = React.useState('Selection Item');


    const selectionDialogTitle = 'Add Selection Item';
    const selectionAddLabel = 'Add Selection Item';
    const selectionDeleteLabel = 'Delete Selection Item';
    const selectionHeaderLabel = 'Selection Item';

    const radioGroupTitle = 'Add Radio Button';
    const radioAddLabel = 'Add Radio Button';
    const radioDeleteLabel = 'Delete Radio Button';
    const radioHeaderLabel = 'Radio Button';

    const checkboxGroupTitle = 'Add Checkbox';
    const checkboxAddLabel = 'Add Checkbox';
    const checkboxDeleteLabel = 'Delete CheckBox';
    const checkboxHeaderLabel = 'Checkbox';

    const TEXT_VARIABLES = [ { label: 'Custom', value: 'CUSTOM' }, {label: 'Revision Number', value: 'revisionNumber'} ];
    const SELECTION_VARIABLES = [{label: 'Custom', value: 'CUSTOM'} , {label: 'Document List', value: 'documentName'}];
    const OTHER_VARIABLES = [{ label: 'Custom', value: 'CUSTOM' }];
    const GROUP_VARIABLES = [{ label: 'Custom', value: 'CUSTOM' }];


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CreateMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    React.useEffect(() => {
        if (field) {
            setNameValue(field.name);
            setLabelValue(field.label);
            setDescriptionValue(field.description);
            setPlaceholderValue(field.placeholder);
            setTypeValue(field.type === 'TEXT' ? 'TEXTFIELD' : field.type);
            loadVariableList(field.type);
            setRequiredValue(field.required);
            if (field.type == 'CHECKBOX') {
                setPlaceholderEnabled(false);
            }
            if ((field.type == 'SELECTION' || field.type === 'RADIOBUTTON_GROUP' || field.type === 'CHECKBOX_GROUP')
                && field.properties && Array.isArray(field.properties.selectionData)) {
                setSelectionData(field.properties.selectionData);
            } else {
                setSelectionData([]);
            }
            if ((field.type === 'RADIOBUTTON_GROUP' || field.type === 'CHECKBOX_GROUP')
                && field.properties) {
                if (field.properties.orientation === 'V') {
                    setOrientationValue('V');
                } else {
                    setOrientationValue('H');
                }
                setOrientationEnabled(true);
            } else {
                setOrientationValue('H');
                setOrientationEnabled(false);
            }
            if ( field.type === 'PREDEFINED' ) {
                if ( field.properties ) {
                    setPredefinedValue(field.properties.predefined);
                    if ( field.properties.predefined?.name ) {
                        setVariableListValue(field.properties.predefined.name);
                    } else {
                        setVariableListValue(field.name);
                    }
                } else {
                    setVariableListValue(field.name);
                }
                setSelectTypeVisible(false);
                setNameDisabled(true);
                setVariableEnabled(true);
            } else {
                setNameDisabled(true);
                setSelectTypeVisible(false);
                setVariableEnabled(false);
            }
            if (field.properties) {
                if (field.properties.variableListType || field.type === 'PREDEFINED') {
                    setVariableListValue(field.properties.variableListType);
                    setNameDisabled(field.properties.variableListType !== 'Custom' || field.type === 'PREDEFINED');
                    setCustomSelectionEnabled(field.type == 'SELECTION' || field.type === 'RADIOBUTTON_GROUP' || field.type === 'CHECKBOX_GROUP');
                } else {
                    setVariableListValue('');
                    setCustomSelectionEnabled(true);
                    setNameDisabled(false);
                }
            } else {
                setVariableListValue('');
                setCustomSelectionEnabled(true);
                setNameDisabled(false);
            }
        } else {
            setNameValue(null);
            setLabelValue(null);
            setDescriptionValue(null);
            setPlaceholderValue('');
            setTypeValue('');
            setSelectTypeVisible(true);
            setRequiredValue(true);
            setSelectionData([]);
            setVariableList(TEXT_VARIABLES);
            setVariableListValue('');
            setVariableEnabled(false);
            setNameDisabled(false);
            setCustomSelectionEnabled(true);
            setOrientationValue('H');
            setOrientationEnabled(false);
            setPredefinedValue({});
        }
        setNameFocused(false);
        setOkEnabled(editOnly && field);

    }, [field, open]);


    /*
    const useFocus = () => {
        const htmlElRef = React.useRef(null)
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    
        return [ htmlElRef, setFocus ] 
    }

    const [nameRef, setVariableFocus] = useFocus();
    */
    const removeSpacesRE = /\s+/g;

    const computeVariableName = (label) => {
        if (typeof label === 'string' && label !== '') {
            let v = label.replace(removeSpacesRE, '');
            v = v.substring(0, 1).toLowerCase() + v.substring(1);
            // console.log('ComputeVariableName: ' + v);
            setNameValue(v);
        } else {
            setNameValue('');
        }
    };

    const evalVariableName = (label) => {
        if (typeof label === 'string' && label !== '') {
            let v = label.replace(removeSpacesRE, '');
            v = v.substring(0, 1).toLowerCase() + v.substring(1);
            return v;
        }
        return '';
    }

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            let nfield = {
                name: nameValue,
                label: labelValue,
                description: descriptionValue,
                placeholder: placeholderValue,
                type: typeValue,
                required: requiredValue,
                properties: {
                    variableListType: variableListValue,
                }
            }
            if (typeValue === 'SELECTION' || typeValue === 'RADIOBUTTON_GROUP' || typeValue === 'CHECKBOX_GROUP') {
                const sd = [...selectionData];
                nfield['placeholder'] = '';
                nfield['properties'] = {
                    selectionData: sd,
                    selectionListType: variableListValue, // compatibility
                    variableListType: variableListValue,
                    orientation: orientationValue,
                };
            }
            if ( typeValue === 'PREDEFINED' ) {
                const p = PREDEFINED_FIELDS.find( item => item.name === variableListValue);
                if ( p ) {
                    nfield['properties']['predefined'] = p;
                }
            }
            console.log('Save FIELD: ' + JSON.stringify(nfield));
            onSave(nfield);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const isValid = (value) => {
        return value && typeof value === 'string' && value.length > 0;
    };

    const isFormValid = (label, varName, fieldType, varType, selData) => {
        let v = isValid(label) && isValid(varName);
        if ( !v ) {
            return false;
        }
        if ( (varType === 'Custom' || varType === '') && (fieldType === 'SELECTION' || fieldType === 'RADIOBUTTON_GROUP' || fieldType === 'CHECKBOX_GROUP')) {
            return Array.isArray(selData) && selData.length > 0;
        }
        return true;
    };

    const nameChanged = (event) => {
        setNameValue(event.target.value);
        setOkEnabled(isFormValid(labelValue, event.target.value, typeValue, variableListValue, selectionData));
    };

    const labelChanged = (event) => {
        setLabelValue(event.target.value);
        computeVariableName(event.target.value);
        const n = evalVariableName(event.target.value);
        const v = isFormValid(event.target.value, n, typeValue, variableListValue, selectionData);
        setOkEnabled(v);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    const placeholderChanged = (event) => {
        setPlaceholderValue(event.target.value);
    };

    const loadVariableList = (type) => {
        switch (type) {
            case 'TEXTFIELD':
            case 'TEXTAREA':
                setVariableList(TEXT_VARIABLES);
                break;
            case 'SELECTION':
                setVariableList(SELECTION_VARIABLES);
                setAddDialogTitle(selectionDialogTitle);
                setAddLabel(selectionAddLabel);
                setDeleteLabel(selectionDeleteLabel);
                setItemHeaderLabel(selectionHeaderLabel);
                break;
            case 'RADIOBUTTON_GROUP':
                setVariableList(GROUP_VARIABLES);
                setAddDialogTitle(radioGroupTitle);
                setAddLabel(radioAddLabel);
                setDeleteLabel(radioDeleteLabel);
                setItemHeaderLabel(radioHeaderLabel);
                break;
            case 'CHECKBOX_GROUP':
                setVariableList(GROUP_VARIABLES);
                setAddDialogTitle(checkboxGroupTitle);
                setAddLabel(checkboxAddLabel);
                setDeleteLabel(checkboxDeleteLabel);
                setItemHeaderLabel(checkboxHeaderLabel);
                break;
            default:
                setVariableList(OTHER_VARIABLES);
                break;
        }
    };

    const typeChanged = (event) => {
        const v = event.target.value;
        setTypeValue(v);
        // setPlaceholderEnabled(event.target.value === 'TEXT' || event.target.value === 'TEXTAREA');
        setPlaceholderEnabled(false);
        loadVariableList(v);
        setVariableListValue(v === 'PREDEFINED' ? null : '');
        setCustomSelectionEnabled(true);
        setNameDisabled(false);
        computeVariableName(labelValue);
        setOrientationEnabled(v === 'CHECKBOX_GROUP' || v === 'RADIOBUTTON_GROUP');
        const fv = isFormValid(labelValue, evalVariableName(labelValue), v, '', selectionData);
        setOkEnabled(fv);
        setVariableEnabled(v === 'PREDEFINED');
        setVariableLabelVisible(v === 'PREDEFINED');
    }

    const requiredChanged = (event) => {
        setRequiredValue(event.target.checked);
    }

    const handleSelectionClicked = (event, index) => {
        setSelectionIndex(index);
        setDeleteSelectionEnabled(index >= 0);
        setMoveDownItemEnabled(index >= 0 && index < selectionData.length - 1);
        setMoveUpItemEnabled(index > 0 );
    };

    const handleAddSelectionClick = () => {
        setSelectionDialogOpen(true);
    };

    const handleDeleteSelectionClick = () => {
        if (selectionIndex >= 0) {
            let sd = [...selectionData];
            sd.splice(selectionIndex, 1);
            setSelectionData(sd);
            const v = isFormValid(labelValue, nameValue,  typeValue, variableListValue, sd);
            setOkEnabled(v);
            setDeleteSelectionEnabled(false);
            setMoveDownItemEnabled(false);
            setMoveUpItemEnabled(false);
        }
    };

    const moveUpSelectionHandler = () => {
        if ( selectionIndex > 0 ) {
            let sd = [...selectionData];
            const p = sd[selectionIndex - 1];
            const n = selectionIndex - 1;
            sd[selectionIndex - 1] = sd[selectionIndex];
            sd[selectionIndex] = p;
            setSelectionData(sd);
            setSelectionIndex(n);
            setMoveUpItemEnabled( n > 0 ) ;
            setMoveDownItemEnabled( n >= 0 && n < sd.length - 1);
        }
    };

    const moveDownSelectionHandler = () => {
        if ( selectionIndex >= 0  && selectionIndex < selectionData.length - 1) {
            let sd = [...selectionData];
            const p = sd[selectionIndex + 1];
            const n = selectionIndex + 1;
            sd[selectionIndex + 1] = sd[selectionIndex];
            sd[selectionIndex] = p;
            setSelectionData(sd);
            setSelectionIndex(n);
            setMoveUpItemEnabled( n > 0 ) ;
            setMoveDownItemEnabled( n >= 0 && n < sd.length - 1);
        }
    };

    const dialogSaveHandler = (categ, value, label) => {
        setSelectionDialogOpen(false);
        const target = selectionData.find(item => item.categ === categ && item.value === value);
        let tval = target ? value + '2' : value;
        const entry = { categ: categ, value: tval, label: label };
        let sd = [...selectionData];
        sd.push(entry);
        setSelectionData(sd);
        const v = isFormValid(labelValue, nameValue, labelValue, variableListValue, sd);
        setOkEnabled(v);
        console.log('New entry: ' + JSON.stringify(entry));
    };

    const dialogCancelHandler = () => {
        setSelectionDialogOpen(false);
    };

    const variableListChanged = (event) => {
        setVariableListValue(event.target.value);
        // console.log('variableListValue: ' + event.target.value);
        let nv = nameValue;
        let sd = selectionData;
        if (event.target.value !== 'Custom') {
            setNameValue(event.target.value);
            setSelectionData([]);
            sd = [];
        } else {
            // console.log('labelValue: ' + labelValue);
            computeVariableName(labelValue);
            nv = evalVariableName(labelValue);
        }
        setNameDisabled(event.target.value !== 'Custom');
        setCustomSelectionEnabled(event.target.value === 'Custom');
        const v = isFormValid(labelValue, nv, typeValue, event.target.value, sd);
        setOkEnabled(v);
    };

    const orientationChanged = (event) => {
        setOrientationValue(event.target.value);
    };

    return (
        <Dialog
            maxWidth={'1200px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 800,
                    maxHeight: 800
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>

                    <Box sx={{ paddingTop: '2ex', paddingBottom: '2ex', paddingRight: '1em' }}>
                            <FormControl size="small"  fullWidth>
                                <InputLabel id="form-type-label" sx={{visibility: !selectTypeVisible ? 'visible' : 'hidden'}}>Type *</InputLabel>
                                <Select
                                    labelId="form-type-label"
                                    id="type-select"
                                    value={typeValue}
                                    label="Type *"
                                    onChange={typeChanged}
                                    displayEmpty={true}
                                    renderValue={(selected) => {
                                        if ( !selected ) {
                                            if ( selectTypeVisible ) {
                                                return 'Select type';
                                            } else {
                                                return 'Select';
                                            }
                                        }
                                        return TYPE_LABELS[selected];
                                    }}
                                    onOpen={() => setSelectTypeVisible(false)}
                                    required={true}
                                >
                                    <MenuItem value={'CHECKBOX'}>{TYPE_LABELS['CHECKBOX']}</MenuItem>
                                    <MenuItem value={'CHECKBOX_GROUP'}>{TYPE_LABELS['CHECKBOX_GROUP']}</MenuItem>
                                    <MenuItem value={'PREDEFINED'}>{TYPE_LABELS['PREDEFINED']}</MenuItem>
                                    <MenuItem value={'RADIOBUTTON_GROUP'}>{TYPE_LABELS['RADIOBUTTON_GROUP']}</MenuItem>
                                    <MenuItem value={'SELECTION'}>{TYPE_LABELS['SELECTION']}</MenuItem>
                                    <MenuItem value={'TEXTAREA'}>{TYPE_LABELS['TEXTAREA']}</MenuItem>
                                    <MenuItem value={'TEXTFIELD'}>{TYPE_LABELS['TEXTFIELD']}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ paddingTop: '2ex', paddingLeft: '1em', gridColumnStart: '2', visibility: variableEnabled ? 'visible' : 'hidden' }}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="var-list-label" sx={{visibility: !variableLabelVisible && variableEnabled ? 'visible' : 'hidden'}}>Category *</InputLabel>
                                <Select
                                    labelId="var-list-label"
                                    id="var-list-select"
                                    value={variableListValue ? variableListValue : ''}
                                    label="Category *"
                                    onChange={variableListChanged}
                                    required={true}
                                    displayEmpty={true}
                                    renderValue={(selected) => {
                                        if ( !selected ) {
                                            if ( variableLabelVisible ) {
                                                return 'Select category';
                                            } else {
                                                return 'Select';
                                            }
                                        }
                                        const p = PREDEFINED_FIELDS.find( item => item.name === selected);
                                        return p?.label;
                                    }}
                                    onOpen={() => setVariableLabelVisible(false)}
                                >
                                    {PREDEFINED_FIELDS.map((pf, pindex) => <MenuItem value={pf.name} key={`${pf.name}-${pindex}`} >{pf.label}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        {/* 
                        <Box sx={{ paddingTop: '2ex', gridColumnEnd: 'span 2' }}>
                            <TextField label="Variable Name" value={nameValue} onChange={nameChanged} size="small" InputLabelProps={{ shrink: nameValue && nameValue !== '' }}
                                required={true} fullWidth sx={{ visibility: nameDisabled ? 'hidden' : 'visible' }} />
                        </Box>
                        */}
                        <Box sx={{ paddingTop: '2ex', gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                            <TextField label="Label" value={labelValue} onChange={labelChanged} size="small"
                                fullWidth required={true} />
                        </Box>

                        <Box sx={{ paddingTop: '3ex', gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                            <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                                multiline fullWidth
                                rows={3} />
                        </Box>
                        {placeholderEnabled &&
                            <Box sx={{ paddingTop: '3ex', gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                                <TextField label="Placeholder" value={placeholderValue} onChange={placeholderChanged} size="small"
                                    fullWidth />
                            </Box>
                        }
                        {(typeValue === 'SELECTION' || typeValue === 'RADIOBUTTON_GROUP' || typeValue === 'CHECKBOX_GROUP') && customSelectionEnabled &&
                            <Box sx={{ gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                                <TableContainer component={Paper} sx={{ marginTop: '3ex', height: '11.5em' }}>
                                    <Table sx={{ minWidth: 500 }} aria-label="selection table" >
                                        <TableHead>
                                            <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>{itemHeaderLabel}</TableCell>
                                                {/* 
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Selection Value</TableCell>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Category</TableCell>
                                                */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectionData && selectionData.map((row, index) => (
                                                <TableRow
                                                    key={`FS${index}`}
                                                    onClick={(event) => handleSelectionClicked(event, index)}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    selected={selectionIndex == index}
                                                >
                                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{row.label}</TableCell>
                                                    {/* 
                                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{row.value}</TableCell>
                                                    <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                                        {row.categ}
                                                    </TableCell>
                                                    */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ paddingTop: '0px', paddingLeft: '0px' }}>
                                    <Tooltip title={addLabel} >
                                        <IconButton color="primary" onClick={handleAddSelectionClick} disabled={!customSelectionEnabled}>
                                            <AddBoxIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={deleteLabel}>
                                        <IconButton disabled={!deleteSelectionEnabled} onClick={handleDeleteSelectionClick} >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Up">
                                        <IconButton color="inherit" disabled={!moveUpItemEnabled} sx={{ marginLeft: '1em' }} onClick={moveUpSelectionHandler}>
                                            <MoveUpIcon color="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Down">
                                        <IconButton color="inherit" disabled={!moveDownItemEnabled} onClick={moveDownSelectionHandler}>
                                            <MoveDownIcon color="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <AddSelectionValueDialog title={addDialogTitle} onSave={dialogSaveHandler} onCancel={dialogCancelHandler}
                                        open={selectionDialogOpen} />
                                </Box>
                            </Box>
                        }
                        <Box sx={{ paddingTop: '2ex' }}>
                            <FormControlLabel control={<Checkbox checked={requiredValue} onChange={requiredChanged} />} label="Required" />
                        </Box>

                        <Box sx={{ paddingTop: '3ex', visibility: orientationEnabled ? 'visible' : 'hidden' }}>
                            <FormControl>
                                <FormLabel id="direction-radio-buttons-group-label">Orientation</FormLabel>
                                <RadioGroup
                                    row
                                    name="direction-radio-buttons-group"
                                    aria-labelledby="direction-radio-buttons-group-label"
                                    value={orientationValue}
                                    onChange={orientationChanged}
                                >
                                    <FormControlLabel value="H" control={<Radio />} label="Horizontal" />
                                    <FormControlLabel value="V" control={<Radio />} label="Vertical" />
                                </RadioGroup>
                            </FormControl>
                        </Box>

                    </Box>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function FormFields(props) {
    const {
        formIndex,
        formsList,
        saveEnabled,
        onChange,
        updateFields,
        ...other
    } = props;

    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [form, setForm] = React.useState(null);


    React.useEffect(() => {
        if (formsList && formIndex >= 0) {
            setForm(formsList[formIndex]);
        }
    }, [formIndex, formsList, updateFields]);

    const handleRowClick = (index) => {
        setSelectedIndex(index);
    };

    return (
        <Box sx={{ padding: '1ex' }}>
            <Box sx={{ paddingTop: '2ex' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{form?.label}</Typography>
            </Box>
            <Box sx={{ paddingTop: '2ex', paddingBottom: '3ex' }}>
                <Typography sx={{ fontSize: '15px' }}>{form?.description}</Typography>
            </Box>
            {form &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Field Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Label</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Placeholder</TableCell>
                                <TableCell>Required</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {form && Array.isArray(form.fields) &&
                                form.fields.map((field, index) => (
                                    <TableRow selected={selectedIndex == index}
                                        onClick={handleRowClick(index)}
                                        key={`${field.name}${index}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{field.name}</TableCell>
                                        <TableCell>{field.type}</TableCell>
                                        <TableCell>{field.label}</TableCell>
                                        <TableCell>{field.description}</TableCell>
                                        <TableCell>{field.placeholder}</TableCell>
                                        <TableCell>{field.required && <CheckIcon />}</TableCell>
                                    </TableRow>
                                ))

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>

    );


}

function WorkflowForms(props) {

    const [formsList, setFormsList] = React.useState([]);
    const [updateFormsList, setUpdateFormsList] = React.useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedForm, setSelectedForm] = React.useState(null);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
    const [saveFormEnabled, setSaveFormEnabled] = React.useState(false);
    const [checkinEnabled, setCheckinEnabled] = React.useState(false);
    const [addFormEnabled, setAddFormEnabled] = React.useState(true);
    const [deleteFormEnabled, setDeleteFormEnabled] = React.useState(false);
    const [modified, setModified] = React.useState([]);
    const [updateForm, setUpdateForm] = React.useState(0);
    const [updateFormId, setUpdateFormId] = React.useState(-1);

    const [formDialogOpen, setFormDialogOpen] = React.useState(false);
    const [formDialogTemplate, setFormDialogTemplate] = React.useState([]);
    const [formDialogName, setFormDialogName] = React.useState('');
    const [formDialogDescription, setFormDialogDescription] = React.useState('');
    const [formDialogTitle, setFormDialogTitle] = React.useState('Create Form');

    const [attributesEditEnabled, setAttributesEditEnabled] = React.useState(false);
    const [attributesEditDialogOpen, setAttributesEditDialogOpen] = React.useState(false);
    const [attributesEditDialogName, setAttributesEditDialogName] = React.useState('');
    const [attributesEditDialogDescription, setAttributesEditDialogDescription] = React.useState('');
    const [attributesEditDialogTitle, setAttributesEditDialogTitle] = React.useState('Edit Form Properties');


    const [checkinDialogOpen, setCheckinDialogOpen] = React.useState(false);
    const [checkinDialogTitle, setCheckinDialogTitle] = React.useState('Checkin Form');
    const [checkinDialogFormName, setCheckinDialogFormName] = React.useState('');

    const [revokeDialogOpen, setRevokeDialogOpen] = React.useState(false);
    const [revokeDialogTitle, setRevokeDialogTitle] = React.useState('Revoke Checkout');

    const [formDeleteOpen, setFormDeleteOpen] = React.useState(false);
    const [formDeleteName, setFormDeleteName] = React.useState('');
    const [formSaveIndex, setFormSaveIndex] = React.useState(-1);

    const [formSaveOpen, setFormSaveOpen] = React.useState(false);
    const [formSaveName, setFormSaveName] = React.useState('');

    const [addFieldEnabled, setAddFieldEnabled] = React.useState(false);
    const [editFieldEnabled, setEditFieldEnabled] = React.useState(false);
    const [deleteFieldEnabled, setDeleteFieldEnabled] = React.useState(false);

    const [fieldCreateDialogOpen, setFieldCreateDialogOpen] = React.useState(false);
    const [fieldCreateDialogTitle, setFieldCreateDialogTitle] = React.useState('Add Field');
    const [fieldCreateDialogField, setFieldCreateDialogField] = React.useState(null);

    const [fieldEditDialogOpen, setFieldEditDialogOpen] = React.useState(false);
    const [fieldEditDialogTitle, setFieldEditDialogTitle] = React.useState('Edit Field');
    const [fieldEditDialogField, setFieldEditDialogField] = React.useState(null);

    const [fieldContextMenu, setFieldContextMenu] = React.useState(null);
    const [updateFields, setUpdateFields] = React.useState(0);
    const [selectedFieldIndex, setSelectedFieldIndex] = React.useState(1);
    const [selectedField, setSelectedField] = React.useState(null);
    const [moveUpFieldEnabled, setMoveUpFieldEnabled] = React.useState(false);
    const [moveDownFieldEnabled, setMoveDownFieldEnabled] = React.useState(false);
    const [previewEnabled, setPreviewEnabled] = React.useState(false);

    const [previewFormOpen, setPreviewFormOpen] = React.useState(false);

    const theme = useTheme();

    React.useEffect(() => {
        loadInstances();
    }, [updateFormsList]);

    React.useEffect(() => {
        if (updateFormId >= 0) {
            netGet('/api/workflow/form/' + updateFormId)
                .then(response => response.json())
                .then(form => {
                    if (form && form.id) {
                        // console.log('Updating Workflow Definition: ' + def.id);
                        let slist = formsList.map((r) => {
                            if (r.id === form.id) {
                                return form;
                            }
                            return r;
                        });
                        setFormsList(slist);
                        // loadWorkflowDialogTemplates(slist);
                        const cform = slist.find((item) => item.id === form.id);
                        if (cform) {
                            const i = slist.indexOf(cform);
                            // console.log('Found form to update ' + cform + ' at index ' + i);
                            updateSelectedItem(cform, i);
                        }
                    }
                }).catch(error => {
                    console.log('Error fetching definition: ' + error);
                });
        }
    }, [updateForm, updateFormId]);

    

    const workflowMessage = (message) => {
        let wevent = message.data;
        console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'Form') {
            if (wevent.action === 'DELETE') {
                let dindex = -1;
                const form = formsList.find((item, index) => {
                    if (item.id === wevent.id) {
                        dindex = index;
                        return true;
                    }
                });
                if (form && dindex >= 0) {
                    let wlist = [...formsList];
                    wlist.splice(dindex, 1);
                    setFormsList(wlist);
                    let mods = []; 
                    modified.forEach( mindex => {
                        if ( mindex < dindex ) {
                            mods.push(mindex);
                        } else {
                            mods.push(mindex-1);
                        }
                    });
                    setModified(mods);
                    // loadWorkflowDialogTemplates(wlist);
                    if ( dindex < wlist.length ) {
                        const nform = wlist[dindex];
                        selectTheForm(nform, dindex, mods);
                    } else {
                        setSelectedField(-1);
                        setSelectedForm(null);
                        setSelectedIndex(-1);
                        setPreviewEnabled(false);
                        setCheckoutEnabled(false);
                        setDeleteFormEnabled(false);
                        setCheckinEnabled(false);
                        setAttributesEditEnabled(false);
                        setSaveFormEnabled(false);
                    }
                }
            } else if (wevent.action === 'NEW') {
                loadInstances(wevent.id);
                // setUpdateWorkflowList(updateWorkflowList => updateWorkflowList + 1);
            } else if (wevent.action === 'CHECKOUT' || wevent.action === 'REVOKE_CHECKOUT') {

                let cform = formsList.find((item) => item.id === wevent.previousId);
                console.log('Updating form from server: ' + cform?.name);
                if (cform) {
                    cform.id = wevent.id;
                    setUpdateForm(updateForm => updateForm + 1);
                    setUpdateFormId(wevent.id);

                }
            } else {
                const form = formsList.find((item) => item.id === wevent.id);
                const findex = formsList.findIndex( item => item.id === wevent.id);
                if (form && !modified.includes(findex) ) {
                    // try not to overwrite modifications
                    setUpdateForm(updateForm => updateForm + 1);
                    setUpdateFormId(wevent.id);
                }
            }
        }
    };

    MessageHooks["workflow"]["Forms"] = workflowMessage;


    const loadInstances = (selectId) => {
        netGet('/api/workflow/form/list')
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    console.log('New Forms: ' + data.length);
                    const ofm = Array.isArray(formsList) ? [...formsList] : [];
                    let fm = [];
                    let mods = [];
                    data.forEach( (item,index) => {
                        const oindex = ofm.findIndex( oitem => oitem.id === item.id);
                        if ( modified.includes(oindex) ) {
                            fm.push(ofm[oindex]);
                            mods.push(index);
                        } else {
                            fm.push(item);
                        }
                    });
                    setFormsList(fm);
                    setModified(mods);
                    if (selectId) {
                        const sindex = fm.findIndex( item => item.id === selectId);
                        /*
                        let sindex = -1;
                        let sdef = data.find((item, i) => {
                            if (item.id === selectId) {
                                sindex = i;
                                return true;
                            }
                            return false;
                        });
                        */
                        if (sindex >= 0) {
                            const sform = fm[sindex];
                            selectTheForm(sform, sindex, mods);
                        }
                    }
                }
            });
    };


    const handleContextMenu = (event, index) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if (index >= 0) {
            handleItemClick(index);
        }
    };

    const handleFieldContextMenu = (event, index) => {
        event.preventDefault();
        setFieldContextMenu(
            fieldContextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if (index >= 0) {
            handleFieldClick(index);
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleFieldContextMenuClose = () => {
        setFieldContextMenu(null);
    };

    const selectTheForm = (form,index,mods) => {
        const canModify = typeof form.user === 'string' && form.user.length > 0 && form.user === UserInfo.info.name;
        setSelectedIndex(index);
        setSelectedFieldIndex(-1);
        if (index >= 0) {
            if (form) {
                setSelectedForm(form);
                setAddFieldEnabled(canModify);
                setPreviewEnabled(true);
                setSaveFormEnabled(mods.includes(index));
            } else {
                setSelectedForm(null);
                setPreviewEnabled(false);
                setSaveFormEnabled(false);
            }
        } else {
            setSelectedForm(null);
            setPreviewEnabled(false);
            setSaveFormEnabled(false);
        }
        const haslock = typeof form.user === 'string' && form.user.length > 0;
        if (haslock) {
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(canModify);
            setAttributesEditEnabled(canModify);
            setSaveFormEnabled(mods.includes(index));
        } else {
            setCheckoutEnabled(true);
            setDeleteFormEnabled(true);
            setCheckinEnabled(false);
            setSaveFormEnabled(false);
            setAttributesEditEnabled(false);
        }
    };

    const handleItemClick = (index) => {
        /*
        if ( saveRequired(selectedIndex) ) {
            const def = workflowList[selectedIndex];
            if (def) {
                setWorkflowSaveName(def.label);
                setWorkflowSaveIndex(selectedIndex);
                setWorkflowSaveOpen(true);
            }
        }
        */
        const canModify = isLockedByMe(index);
        setSelectedIndex(index);
        setSelectedFieldIndex(-1);
        if (index >= 0) {
            const form = formsList[index];
            if (form) {
                setSelectedForm(form);
                setAddFieldEnabled(canModify);
                setPreviewEnabled(true);
                
            } else {
                setSelectedForm(null);
                setPreviewEnabled(false);
            }
        } else {
            setSelectedForm(null);
            setPreviewEnabled(false);
        }

        if (isLocked(index)) {
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(canModify);
            setAttributesEditEnabled(canModify);
            setSaveFormEnabled(canModify && modified.includes(index));
        } else {
            setCheckoutEnabled(true);
            setDeleteFormEnabled(true);
            setCheckinEnabled(false);
            setSaveFormEnabled(false);
            setAttributesEditEnabled(false);
        }
    };

    const updateSelectedItem = (form, index) => {
        const locked = typeof form.user === 'string' && form.user.length > 0;
        const lockedByMe = typeof form.user === 'string' && form.user.length > 0 && form.user === UserInfo.info.name;
        setSelectedIndex(index);
        setSelectedForm(form);
        if (locked) {
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(lockedByMe /* && !saveRequired(index) */);
            // etSaveWorkflowEnabled(saveRequired(index));
            // setUndoSaveEnabled(saveRequired(index));
            setAttributesEditEnabled(lockedByMe);
            setAddFieldEnabled(lockedByMe);
        } else {
            setCheckoutEnabled(true);
            setDeleteFormEnabled(true);
            setCheckinEnabled(false);
            // setSaveWorkflowEnabled(false);
            // setUndoSaveEnabled(false);
            setAttributesEditEnabled(false);
            setAddFieldEnabled(false);
        }
    };

    const saveFormHandler = () => {
        unmarkModified();
        updateServerForm(formsList[selectedIndex]);
    };

    const formChangeHandler = (modified, index) => {
        if (modified) {
            markModified();
            setSaveFormEnabled(true);
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(false);
        } else {
            setSaveFormEnabled(false);
            handleItemClick(selectedIndex);
            unmarkModified();
        }
    };

    const handleCheckinAction = () => {
        setCheckinDialogOpen(true);
    };

    const handleCheckinSave = (message) => {
        setCheckinDialogOpen(false);
        if (selectedIndex >= 0) {
            serverCheckin(selectedIndex, message);
        }
    };

    const handleCheckinCancel = () => {
        setCheckinDialogOpen(false);
    };

    const handleEditFormPropertiesAction = () => {
        const form = formsList[selectedIndex];
        if (form) {
            setAttributesEditDialogName(form.label);
            setAttributesEditDialogDescription(form.description);
            setAttributesEditDialogOpen(true);
        }
    };

    const attributesEditDialogCancelHandler = () => {
        handleContextMenuClose();
        setAttributesEditDialogOpen(false);
    };

    const attributesEditDialogCreateHandler = (name, description) => {
        handleContextMenuClose();
        setAttributesEditDialogOpen(false);
        if (typeof name === 'string' && name.length > 0) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime() / 100);
            let form = formsList[selectedIndex];
            if (form) {
                form.label = name;
                form.description = description;
                updateServerForm(form);
            }
            /*
            const suffix = timestamp.toString(16);
            const id = `${name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            console.log('name: ' + name + ',id: ' + id);
            let newForm = {
                name: id,
                label: name,
                description: description,
                flag: 0,
                category: 'STD',
                fields: [],
            }
            addServerForm(newForm);
            */
        }
    };

    const handleCreateFormAction = () => {
        setFormDialogName('');
        setFormDialogDescription('');
        setFormDialogOpen(true);
        /*
        netGet('/api/doc/roots?sort=alpha')
            .then(response => response.json())
            .then(docs => {
                if (Array.isArray(docs)) {
                    console.log('Got ' + docs.length + ' documents.');
                    setDocList(docs);
                    setWorkflowDialogOpen(true);
                }
            }).catch(error => {
                console.log('Error fetching profiles: ' + error);
            });
        */
    };

    const formDialogCancelHandler = () => {
        setFormDialogOpen(false);
    };

    const formDialogCreateHandler = (name, description) => {
        handleContextMenuClose();
        setFormDialogOpen(false);
        if (typeof name === 'string' && name.length > 0) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime() / 100);
            const suffix = timestamp.toString(16);
            const id = `${name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            console.log('name: ' + name + ',id: ' + id);
            let newForm = {
                name: id,
                label: name,
                description: description,
                flag: 0,
                category: 'STD',
                fields: [],
            }
            addServerForm(newForm);
        }
    };

    const addServerForm = (form) => {
        netPost('/api/workflow/form', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not create form: status = ' + resp.status);
                } 
                /*
                else {
                    unmarkModified();
                }
                */
            });
    };

    const updateServerForm = (form) => {
        netPost('/api/workflow/form/update', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not update form: status = ' + resp.status);
                } else {
                    unmarkModified();
                }
            });
    };

    const serverCheckout = (index) => {
        const form = formsList[index];
        netPost('/api/workflow/form/checkout', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkout form: status = ' + resp.status);
                }
            });
    };

    const serverCheckin = (index, msg) => {
        const form = formsList[index];
        const message = msg ? msg : '';
        const payload = { form: form, message: message };
        netPost('/api/workflow/form/checkin', payload)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkin form: status = ' + resp.status);
                }
            });
    };

    const serverRevokeCheckout = (index) => {
        const form = formsList[index];
        netPost('/api/workflow/form/revoke_checkout', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not revoke checkout form: status = ' + resp.status);
                }
            });
    };

    const serverDelete = (form) => {
        netFetch('/api/workflow/form/' + form.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not delete form: status = ' + resp.status);
                }
            });
    };

    const handleMenuCheckin = () => {
        handleContextMenuClose();
        handleCheckinAction();
    };

    const handleMenuDeleteForm = () => {
        handleContextMenuClose();
        handleDeleteFormAction();
    };

    const handleDeleteFormAction = () => {
        const form = formsList[selectedIndex];
        setFormDeleteName(form.label);
        setFormDeleteOpen(true);
    }

    const deleteFormSave = () => {
        setFormDeleteOpen(false);
        const form = formsList[selectedIndex];
        serverDelete(form);
    };

    const deleteFormCancel = () => {

        setFormDeleteOpen(false);

    };


    const markModified = () => {
        const m = [...modified];
        if (!m.includes(selectedIndex)) {
            m.push(selectedIndex);
        }
        setModified(m);

        setSaveFormEnabled(true);
        // setUndoSaveEnabled(true);
        // modified inhibites checkin
        setCheckinEnabled(false);
    };

    const unmarkModified = () => {
        const m = modified.filter(item => item !== selectedIndex);
        console.log('Modified: ' + JSON.stringify(m));
        setModified(m);
        setCheckinEnabled(isLockedByMe(selectedIndex));
        setSaveFormEnabled(false);
        // setUndoSaveEnabled(false);
    };

    const lockIconColor = (index) => {
        const form = formsList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if (form.user && form.user !== '') {
            if (form.user === UserInfo.info.name) {
                return 'green';
            } else {
                return '#cc0000';
            }
        }
        return 'transparent';
    };

    const lockIconLabel = (index) => {
        const form = formsList[index];
        if (form && form.user && form.user !== '') {
            return 'Locked by ' + form.user;
        }
        return '';
    };

    const isLockedByMe = (index) => {
        const form = formsList[index];
        return typeof form.user === 'string' && form.user.length > 0 && form.user === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let form = formsList[index];
        return typeof form.user === 'string' && form.user.length > 0;
    };

    const handleCheckoutAction = () => {
        if (selectedIndex >= 0) {
            serverCheckout(selectedIndex);
        }
    };

    const handleMenuCheckout = () => {
        handleContextMenuClose();
        handleCheckoutAction();
    };

    const handleMenuRevokeCheckout = () => {
        handleContextMenuClose();
        handleRevokeCheckoutAction();
    };

    const handleRevokeCheckoutAction = () => {
        setRevokeDialogOpen(true);

    };

    const handleRevokeCheckoutSave = () => {
        setRevokeDialogOpen(false);
        if (selectedIndex >= 0) {
            unmarkModified();
            serverRevokeCheckout(selectedIndex);
        }
    };

    const handleRevokeCheckoutCancel = () => {
        setRevokeDialogOpen(false);
    }

    const doUnmarkModified = (index) => {
        const m = modified.filter(item => item !== index);
        setModified(m);
        // setCheckinEnabled(isLockedByMe(index));
    };

    const modifiedColor = (index) => {
        return saveRequired(index) ? 'black' : 'transparent';
    };

    const modifiedLabel = (index) => {
        return saveRequired(index) ? 'Modified' : null;
    }

    const saveRequired = (index) => {
        return modified.includes(index);
    };

    const saveFormCancel = () => {
        setFormSaveOpen(false);
        // just keep the current state it may work out or may loose changes later
    };

    const saveFormSave = (index) => {
        setFormSaveOpen(false);
        doUnmarkModified(index);
        // saveToServer(formsList[index]);
    };

    const saveFormDiscard = (index) => {
        setFormSaveOpen(false);
        const form = formsList[index];
        if (form && typeof form.saveSteps !== 'undefined') {
            // put back the steps
            form.fields = [...form.saveFields];
            delete form.saveFields;
            // report we have no changes
            formChangeHandler(false, index);
        }
    }

    const handleAddFieldAction = () => {
        handleFieldContextMenuClose();
        setFieldCreateDialogOpen(true);
    };

    const handleEditFieldAction = () => {
        handleFieldContextMenuClose();
        setFieldEditDialogOpen(true);
    };

    const deleteFieldHandler = () => {
        handleFieldContextMenuClose();
        if (selectedFieldIndex >= 0) {
            let form = formsList[selectedIndex];
            let sd = [...form.fields];
            sd.splice(selectedFieldIndex, 1);
            form.fields = sd;
            handleItemClick(selectedIndex);
            markModified();
        }

    };

    const fieldCreateDialogSaveHandler = (nfield) => {
        setFieldCreateDialogOpen(false);
        let form = formsList[selectedIndex];
        if (form) {
            if (!Array.isArray(form.fields)) {
                form.fields = [];
            }
            // console.log('New field: ' + JSON.stringify(nfield));
            form.fields.push(nfield);
            // formsList[selectedIndex] = nform;
            // setFormsList(formsList);
            handleItemClick(selectedIndex);
            markModified();
        }

    };

    const fieldCreateDialogCancelHandler = () => {
        setFieldCreateDialogOpen(false);
    };

    const fieldEditDialogSaveHandler = (efield) => {
        setFieldEditDialogOpen(false);
        const form = formsList[selectedIndex];
        if (form) {
            if (Array.isArray(form.fields) && selectedFieldIndex < form.fields.length) {
                form.fields[selectedFieldIndex] = efield;
                handleItemClick(selectedIndex);
                markModified();
            }
        }
    }

    const fieldEditDialogCancelHandler = () => {
        setFieldEditDialogOpen(false);
    };

    const moveUpFieldHandler = () => {
        handleFieldContextMenuClose();
        const form = formsList[selectedIndex];
        const canEdit = isLockedByMe(selectedIndex);
        if (canEdit && form && Array.isArray(form.fields)) {
            const p = form.fields[selectedFieldIndex - 1];
            const n = selectedFieldIndex - 1;
            form.fields[selectedFieldIndex - 1] = form.fields[selectedFieldIndex];
            form.fields[selectedFieldIndex] = p;
            handleItemClick(selectedIndex);
            markModified();
            setSelectedFieldIndex(n);
            setMoveUpFieldEnabled(canEdit && n > 0);
            setMoveDownFieldEnabled(canEdit && n < form.fields.length - 1);
        }
    };

    const moveDownFieldHandler = () => {
        handleFieldContextMenuClose();
        const form = formsList[selectedIndex];
        const canEdit = isLockedByMe(selectedIndex);
        if (canEdit && form && Array.isArray(form.fields)) {
            const p = form.fields[selectedFieldIndex + 1];
            const n = selectedFieldIndex + 1;
            form.fields[selectedFieldIndex + 1] = form.fields[selectedFieldIndex];
            form.fields[selectedFieldIndex] = p;
            handleItemClick(selectedIndex);
            markModified();
            setSelectedFieldIndex(n);
            setMoveUpFieldEnabled(canEdit && n > 0);
            setMoveDownFieldEnabled(canEdit && n < form.fields.length - 1);
        }
    };

    const handleFieldClick = (index) => {
        const canEdit = isLockedByMe(selectedIndex);
        if (index >= 0) {
            // console.log('field index = ' + index);
            setSelectedFieldIndex(index);

            if (index >= 0 && selectedIndex >= 0 && canEdit) {
                setEditFieldEnabled(true);
                setDeleteFieldEnabled(true);
                setAddFieldEnabled(true);
            } else {
                setEditFieldEnabled(false);
                setDeleteFieldEnabled(false);
                setAddFieldEnabled(false);
            }
            const form = formsList[selectedIndex];
            if (form && Array.isArray(form.fields)) {
                setMoveUpFieldEnabled(canEdit && index > 0);
                setMoveDownFieldEnabled(canEdit && index < form.fields.length - 1);
                const field = form.fields[index];
                setSelectedField(field);
            } else {
                setSelectedField(null);
            }

        } else {
            setEditFieldEnabled(false);
            setDeleteFieldEnabled(false);
            setAddFieldEnabled(canEdit);
        }
    };

    const previewHandler = () => {
        setPreviewFormOpen(true);
    };

    const previewSave = (result) => {
        setPreviewFormOpen(false);
        console.log('Preview Result: ' + JSON.stringify(result));
    };

    


    return (
        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '25% 3px 1fr', gridTemplateRows: '100%' }}>
            <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content' }}>
                <Typography align="center" sx={{ paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Forms</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <Box
                    sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }}
                    onContextMenu={(event) => handleContextMenu(event, -1)}
                >

                    <List sx={{ minHeight: '2em' }}>
                        {
                            formsList.map((form, index) => (
                                <ListItem
                                    key={'mwwa-' + index}
                                    sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                    onContextMenu={(event) => handleContextMenu(event, index)}
                                >
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: lockIconColor(index), fontSize: '12px' }}>
                                        <Tooltip title={lockIconLabel(index)}>
                                            <span>
                                                <LockIcon fontSize="inherit" />
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: modifiedColor(index), fontSize: '12px', paddingTop: '0.5ex' }}>
                                        <Tooltip title={modifiedLabel(index)}>
                                            <Typography sx={{ paddingLeft: '4px' }}>*</Typography>
                                        </Tooltip>
                                    </Box>
                                    <ListItemButton selected={selectedIndex === index} onClick={(event) => handleItemClick(index)}
                                        sx={{ paddingLeft: '4px', fontStyle: 'italic' }}>
                                        <ListItemText>{form.label}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                        }
                    </List>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleMenuCheckout} disabled={!checkoutEnabled}>Checkout</MenuItem>
                        <MenuItem onClick={handleMenuRevokeCheckout} disabled={!checkinEnabled}>Revoke Checkout</MenuItem>
                        <MenuItem onClick={saveFormHandler} disabled={!saveFormEnabled}>Save</MenuItem>
                        <MenuItem onClick={handleMenuCheckin} disabled={!checkinEnabled}>Checkin</MenuItem>
                        <MenuItem onClick={handleCreateFormAction} disabled={!addFormEnabled}>Add</MenuItem>
                        <MenuItem onClick={handleEditFormPropertiesAction} disabled={!attributesEditEnabled}>Properties</MenuItem>
                        <MenuItem onClick={handleMenuDeleteForm} disabled={!deleteFormEnabled}>Delete</MenuItem>
                    </Menu>

                    <FormCheckinDialog open={checkinDialogOpen} onSave={handleCheckinSave} onCancel={handleCheckinCancel}
                        title={checkinDialogTitle} formName={checkinDialogFormName} />

                    <FormRevokeCheckoutDialog open={revokeDialogOpen} onSave={handleRevokeCheckoutSave} title={revokeDialogTitle}
                        onCancel={handleRevokeCheckoutCancel} workflowName={checkinDialogFormName} />

                </Box>
                <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Checkout Form">
                        <IconButton color="inherit" disabled={!checkoutEnabled} onClick={handleCheckoutAction} sx={{ paddingLeft: '2px', paddingRight: '6px' }}>
                            <OutputIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Revoke Checkout Form">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleRevokeCheckoutAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <ReplayIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Form">
                        <IconButton color="inherit" disabled={!saveFormEnabled} sx={{ paddingLeft: '6px', paddingRight: '6px' }} onClick={saveFormHandler}>
                            <SaveAltIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Checkin Form">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleCheckinAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <ExitToAppIcon color="inherit" sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Create a Form">
                        <IconButton color="inherit" sx={{ marginLeft: '1em', paddingLeft: '6px', paddingRight: '6px' }} disabled={!addFormEnabled} onClick={handleCreateFormAction} >
                            <AddIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Form Properties">
                        <IconButton color="inherit" sx={{ paddingLeft: '2px', paddingRight: '6px' }} disabled={!attributesEditEnabled} onClick={handleEditFormPropertiesAction}>
                            <EditAttributesIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Form">
                        <IconButton color="inherit" disabled={!deleteFormEnabled} onClick={handleDeleteFormAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>

                    <CreateFormDialog open={formDialogOpen} onSave={formDialogCreateHandler} onCancel={formDialogCancelHandler}
                        name={formDialogName} description={formDialogDescription}
                        title={formDialogTitle} />

                    <FormDeleteDialog title="Delete Form" open={formDeleteOpen} formName={formDeleteName}
                        onSave={deleteFormSave} onCancel={deleteFormCancel} />
                    {/*
                    <WorkflowSaveDialog title="Save Form" open={formSaveOpen} workflowName={formSaveName} workflowIndex={formSaveIndex}
                        onSave={saveFormSave} onCancel={saveFormCancel} onDiscard={saveFormDiscard} />
                    */
                    }
                    <CreateFormDialog editOnly={true} open={attributesEditDialogOpen} onSave={attributesEditDialogCreateHandler} onCancel={attributesEditDialogCancelHandler}
                        name={attributesEditDialogName} description={attributesEditDialogDescription}
                        title={attributesEditDialogTitle} />
                </Box>
            </Box>

            <Divider orientation="vertical" />


            <Box sx={{ position: 'relative', width: '100%', height: '100%', paddingBottom: '1ex', display: 'grid', gridTemplateRows: 'minmax(10px,1fr) min-content' }}>

                <Box sx={{ overflowY: 'auto', height: '100%', width: '100%' }}>
                    {
                        selectedForm &&
                        <Box sx={{ padding: '1ex' }}>
                            <Box sx={{ paddingTop: '2ex' }}>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{selectedForm?.label}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '2ex', paddingBottom: '3ex' }}>
                                <Typography sx={{ fontSize: '15px' }}>{selectedForm?.description}</Typography>
                            </Box>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a form table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Label</TableCell>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Type</TableCell>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Required</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(selectedForm.fields) &&
                                            selectedForm.fields.map((field, index) => (
                                                <TableRow selected={selectedFieldIndex === index}
                                                    onClick={() => handleFieldClick(index)}
                                                    key={`${field.name}${index}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    onContextMenu={(event) => handleFieldContextMenu(event, index)}
                                                >
                                                    <TableCell>{field.label}</TableCell>
                                                    <TableCell>{field.description}</TableCell>
                                                    <TableCell>{TYPE_LABELS[field.type]}</TableCell>
                                                    <TableCell>{field.required && <CheckIcon />}</TableCell>
                                                </TableRow>
                                            ))

                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Box>


                    }
                    <Menu
                        open={fieldContextMenu !== null}
                        onClose={handleFieldContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            fieldContextMenu !== null
                                ? { top: fieldContextMenu.mouseY, left: fieldContextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleAddFieldAction} disabled={!addFieldEnabled}>Add Field</MenuItem>
                        <MenuItem onClick={handleEditFieldAction} disabled={!editFieldEnabled}>Edit Field</MenuItem>
                        <MenuItem onClick={deleteFieldHandler} disabled={!deleteFieldEnabled}>Delete Field</MenuItem>
                        <MenuItem onClick={moveUpFieldHandler} disabled={!moveUpFieldEnabled}>Move Up Field</MenuItem>
                        <MenuItem onClick={moveDownFieldHandler} disabled={!moveDownFieldEnabled}>Move Down Field</MenuItem>
                    </Menu>
                </Box>



                <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Add Field">
                        <IconButton color="inherit" disabled={!addFieldEnabled} onClick={handleAddFieldAction}>
                            <AddIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Field">
                        <IconButton color="inherit" disabled={!editFieldEnabled} onClick={handleEditFieldAction}>
                            <ModeEditIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Field">
                        <IconButton color="inherit" disabled={!deleteFieldEnabled} sx={{ marginLeft: '1em' }} onClick={deleteFieldHandler}>
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Move Up Field">
                        <IconButton color="inherit" disabled={!moveUpFieldEnabled} sx={{ marginLeft: '1em' }} onClick={moveUpFieldHandler}>
                            <MoveUpIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Move Down Field">
                        <IconButton color="inherit" disabled={!moveDownFieldEnabled} onClick={moveDownFieldHandler}>
                            <MoveDownIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Preview">
                        <IconButton color="inherit" disabled={!previewEnabled} sx={{ marginLeft: '1em' }} onClick={previewHandler}>
                            <PreviewIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>

                <CreateFieldDialog open={fieldCreateDialogOpen} onSave={fieldCreateDialogSaveHandler} onCancel={fieldCreateDialogCancelHandler}
                    title={fieldCreateDialogTitle} />
                <CreateFieldDialog open={fieldEditDialogOpen} onSave={fieldEditDialogSaveHandler} onCancel={fieldEditDialogCancelHandler}
                    title={fieldEditDialogTitle} editOnly={true} field={selectedField} />

                <EvaluateFormDialog open={previewFormOpen} form={selectedForm} onSave={previewSave} onCancel={() => setPreviewFormOpen(false)} />
            </Box>

        </Box>
    );
}

function WorkflowFormLists(props) {

    const theme = useTheme();

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '25% 3px 1fr', gridTemplateRows: '100%' }}>
            <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content' }}>
                <Typography align="center" sx={{ paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Selection Lists</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );

}

export { WorkflowForms, WorkflowFormLists, EvaluateFormDialog };