import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RuleIcon from '@mui/icons-material/Rule';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import OutputIcon from '@mui/icons-material/Output';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import InputIcon from '@mui/icons-material/Input';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ClearIcon from '@mui/icons-material/Clear';
import SchemaIcon from '@mui/icons-material/Schema';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
/*
import { DataGrid } from '@mui/x-data-grid';
*/
import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";
import { InsertBeforeIcon, InsertAfterIcon } from "../icons";
import { WorkflowCheckinDialog, WorkflowRevokeCheckoutDialog, WorkflowDeleteDialog, 
    StepDeleteDialog, CreateWorkflowDialog, WorkflowSaveDialog } from "./management-workflow-dialogs";
import { EditIndicator } from "./management-workflow-common";

function WorkflowPublishDialog(props) {
    const {
        title,
        open,
        workflowName,
        onSave,
        onCancel,
        ...other
    } = props;


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave();
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography sx={{display: 'inline'}}>Publish workflow </Typography><Typography sx={{display: 'inline', fontWeight: 'bold'}}>{workflowName}</Typography><Typography  sx={{display: 'inline'}}>?</Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} color="success">Publish</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function AddRouteDialog(props) {
    const {
        title = 'Add Route',
        step,
        stepIndex, // current step
        stepList,
        workflowList,
        workflowIndex,
        open,
        onSave,
        onCancel,
        routes,
        omitStart=false,
        endLabel='END',
        ...other
    } = props;

    const [routeTypeList, setRouteTypeList] = React.useState(['NEXT', 'STEP', 'WORKFLOW']);
    const [routeType, setRouteType] = React.useState('NEXT');
    const [selectedWorkflow, setSelectedWorkflow] = React.useState(-1);
    const [workflowEnabled, setWorkflowEnabled] = React.useState(true);
    const [gotoStepIndex, setGotoStepIndex] = React.useState(-1);
    const [stepEnabled, setStepEnabled] = React.useState(true);

    React.useEffect( () => {
        let hasNext = Array.isArray(routes) ? routes.find( item => item.name === 'NEXT') : false;
        if ( hasNext ) {
            // omit next
            setRouteTypeList(['STEP', 'WORKFLOW']);
            setSelectedWorkflow(workflowIndex);
            setRouteType('STEP');
            let next = stepIndex + 1;
            if ( next >= stepList.length ) {
                next = stepIndex;
            }
            setGotoStepIndex(next);
            setStepEnabled(true);
            setWorkflowEnabled(false);
        } else {
            setRouteTypeList(['NEXT', 'STEP', 'WORKFLOW']);
            setSelectedWorkflow(workflowIndex);
            setRouteType('NEXT');
            let next = stepIndex + 1;
            if ( next >= stepList.length ) {
                next = stepIndex;
            }
            setGotoStepIndex(next);
            setStepEnabled(false);
            setWorkflowEnabled(false);
        }

    }, [routes, stepList, stepIndex, workflowList, workflowIndex]);


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave({
                name: routeType, 
                targetId: gotoStepIndex, 
                targetWorkflowId: selectedWorkflow >= 0 ? workflowList[selectedWorkflow].id : -1,
            });
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleRouteTypeChange = (event) => {
        setRouteType(event.target.value);
        setWorkflowEnabled(event.target.value === 'WORKFLOW');
        if ( event.target.value !==  'WORKFLOW' ) {
            setSelectedWorkflow(workflowIndex);
        }
        setStepEnabled(event.target.value !== 'NEXT');
        if ( event.target.value === 'NEXT') {
            setSelectedWorkflow(workflowIndex);
            let next = stepIndex + 1;
            if ( next >= stepList.length ) {
                next = stepIndex;
            }
            setGotoStepIndex(next);
        }
    };

    const handleWorkflowChange = (event) => {
        setSelectedWorkflow(event.target.value);
    }

    const handleStepChange = (event) => {
        setGotoStepIndex(event.target.value);
    }

    const getStepLabel = (step) => {
        if ( step && step.name === 'END' ) {
            return endLabel;
        }
        return step.label;
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    return (
        <Dialog 
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: '500px',
                    width: '700px',
                    maxWidth: '700px',
                    maxHeight: '500px',
                    height: 500
                }
            }}>
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Type:</Typography>
                    {Array.isArray(routeTypeList) &&

                        <FormControl sx={{ m: 1, minWidth: 40, width: '11em' }} size="small">
                            <Select
                                value={routeType}
                                onChange={handleRouteTypeChange}
                                sx={{ minWidth: '11em', maxWidth: '100%' }}
                                MenuProps={MenuProps}
                                
                            >
                                {routeTypeList.map((rt) => (
                                    <MenuItem
                                        key={rt}
                                        value={rt}
                                    >
                                        {rt}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Target Workflow:</Typography>
                    {Array.isArray(routeTypeList) &&

                        <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 9em)' }} size="small">
                            <Select
                                value={selectedWorkflow}
                                onChange={handleWorkflowChange}
                                sx={{ minWidth: '10em', maxWidth: '100%' }}
                                MenuProps={MenuProps}
                                readOnly={!workflowEnabled}
                                disabled={!workflowEnabled}
                            >
                                {workflowList.map((w, i) => (
                                    <MenuItem
                                        key={'w' + i}
                                        value={i}
                                    >
                                        {w.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Target Step:</Typography>
                    {Array.isArray(stepList) &&

                        <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 9em)' }} size="small">
                            <Select
                                value={gotoStepIndex}
                                onChange={handleStepChange}
                                sx={{ minWidth: '10em', maxWidth: '100%' }}
                                MenuProps={MenuProps}
                                readOnly={!stepEnabled}
                            >
                                {stepList.map((w, i) => {

                                    return (omitStart && i > 0 ) || !omitStart ?
                                    <MenuItem
                                        key={'s' + i}
                                        value={i}
                                    >
                                        {`${omitStart ? i : i+1}: ${getStepLabel(w)}`}
                                    </MenuItem> : null
                                })}
                            </Select>
                        </FormControl>
                    }
                </Box>
            </DialogContent>
            <DialogActions sx={{paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)'}}>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
};


function EditStepDialog(props) {
    const {
        title,
        step,
        stepIndex,
        allSteps,
        allRoles,
        workflowList,
        workflowIndex,
        open,
        onSave,
        onCancel,
        omitStart=false,
        endLabel='END',
        ...other
    } = props;

    const [typeValue, setTypeValue] = React.useState(null);
    const [nameValue, setNameValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [rolesValue, setRolesValue] = React.useState([]);
    const [locksMultiple, setLocksMultiple] = React.useState(false);
    const [routeData, setRouteData] = React.useState([]);
    const [addRouteOpen, setAddRouteOpen] = React.useState(false);
    const [selectedRoute, setSelectedRoute] = React.useState(-1);
    const [deleteRouteEnabled, setDeleteRouteEnabled] = React.useState(false);

    const theme = useTheme();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    /*
    const routeColumns = [
        { field: 'id', headerName: 'ID', width: 60, sortable: false},
        { field: 'name', headerName: 'Type', width: 100, sortable: false },
        { field: 'dest', headerName: 'Destination', resizable: false, sortable: false}
    ];
    */

    React.useEffect( () => {
        if (step) {
            setNameValue(step.label);
            setDescriptionValue(step.description);
            if (Array.isArray(step.roles)) {
                setRolesValue(step.roles);
            } else {
                setRolesValue([]);
            }
            if ( step.properties && step.properties.multiLock ) {
                setLocksMultiple(step.properties.multiLock);
            } else {
                setLocksMultiple(false);
            }
            netGet('/api/workflow/step/def/list?name=' + step.name)
                .then(response => response.json() )
                .then( (data) => {
                    if ( Array.isArray(data) && data.length > 0 ) {
                        const sdef = data[0];
                        setTypeValue(sdef.label);
                    }
                });
            if ( step.routes ) {
                //console.log('Routes: ' + JSON.stringify(step.routes));
                setRouteData(step.routes.map( (item, index) => {
                    let r = {
                        id: index,
                        name: item.name,
                        targetId: item.targetId,
                        targetWorkflowId: item.targetWorkflowId,
                    };
                    resolveDestination(r);

                    return r;
                }))
            } else {
                const r = [
                    { id: 0, name: 'NEXT'}
                ];
                console.log('Setting routes to: ' + JSON.stringify(r));
                setRouteData(r);
            }
        } else {
            setTypeValue('');
            setNameValue('');
            setDescriptionValue('');
            setRolesValue([]);
            setLocksMultiple(false);
        }
    }, [step,open]);


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(nameValue, descriptionValue, rolesValue, locksMultiple, routeData);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        setNameValue(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    const handleRolesChange = (event) => {
        const {
            target: { value },
        } = event;
        let nval = typeof value === 'string' ? value.split(',') : value;
        setRolesValue(nval);
    };

    const handleClearClick = (event) => {
        setRolesValue([]);
    };

    const getStyles = (role) => {
        return {
            fontWeight:
              rolesValue.includes(role) 
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular,
          };
    };

    const handleLocksChange = (event) => {
        setLocksMultiple(event.target.checked);
    };

    const handleAddRouteClick = (event) => {
        setAddRouteOpen(true);
    };

    const handleAddRouteCancel = () => {
        setAddRouteOpen(false);
    }

    const handleAddRouteSave = (route) => {
        setAddRouteOpen(false);
        const r = routeData.find( item => {
            if ( route.name === item.name && route.name === 'NEXT') {
                return true;
            }
            if ( route.name === item.name && route.name === 'STEP') {
                return route.targetId === item.targetId;
            }
            if ( route.name === item.name && route.name === 'WORKFLOW') {
                return route.targetId === item.targetId && route.targetWorkflowId === item.targetWorkflowId;
            }
        });
        if ( ! r ) {
            resolveDestination(route);
            const rd = [ ...routeData];
            rd.push(route);
            rd.forEach( (item,index) => {
                item.id = index;
            });
            // console.log('Added route: ' + JSON.stringify(rd));
            setRouteData(rd);
        }

    };

    const handleDeleteRouteClick = () => {
        if ( selectedRoute >= 0 ) {
            let rd = [ ...routeData];
            rd.splice(selectedRoute, 1);
            rd.forEach( (item,index) => {
                item.id = index;
            });
            setRouteData(rd);
        }
    };

    const getStepLabel = (step) => {
        if ( step && step.name === 'END') {
            return endLabel;
        }
        return step.label;
    };

    const resolveDestination = (route) => {
        switch (route.name) {
            case ('STEP'):
                route.dest = `${omitStart ? route.targetId : route.targetId+1}: ${route.targetId >=0 ? getStepLabel(allSteps[route.targetId]) : ''}`;
                break;
            case ('WORKFLOW'):
                let dest = '';
                if ( route.targetWorkflowId >= 0 ) {
                    const w = workflowList.find( item => item.id === route.targetWorkflowId );
                    if ( w ) {
                        dest = `${dest}${w.label}` ;
                        if ( route.targetId >= 0 && Array.isArray(w.steps)) {
                            dest = `${dest} / ${route.targetId+1}: ${getStepLabel(w.steps[route.targetId])}`;
                        }
                    } else {
                        dest = 'MISSING DESTINATION';
                    }
                } 
                route.dest = dest;
                break;
            default:
                route.dest = '';
                break;
                
        }
    };

    const handleRouteClicked = (event, routeId) => {
        if ( selectedRoute === routeId ) {
            setSelectedRoute(-1);
            setDeleteRouteEnabled(false);
        } else {
            setSelectedRoute(routeId);
            setDeleteRouteEnabled(true);
        }
    };

    const isRouteSelected = (routeId) => {
        return selectedRoute === routeId;
    }

    

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: '500px',
                  width: '900px',
                  maxWidth: '900px',
                  maxHeight: '900px',
                  height: 800
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{paddingTop: '1ex', whiteSpace: 'nowrap'}}>
                    <Typography sx={{display: 'inline-block', width: '4em', fontWeight: 'bold'}}>Type: </Typography>
                    <Typography sx={{display: 'inline-block'}}>{typeValue}</Typography>
                </Box>
                <Box sx={{paddingTop: '3ex'}}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth autoFocus={true} readOnly={true}
                        onFocus={event => {
                            event.target.select();
                          }} />
                </Box>
                <Box sx={{paddingTop: '3ex'}}>
                    <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                        multiline fullWidth
                        rows={3} />
                </Box>
                <Box sx={{display: 'flex', paddingTop: '2em', alignItems: 'center'}}>
                    <Typography sx={{fontWeight: 'bold', width: '4em'}}>Roles:</Typography>
                    {Array.isArray(rolesValue) ?

                        <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 3em)' }} size="small">
                            <Select
                                multiple
                                value={rolesValue}
                                onChange={handleRolesChange}
                                sx={{ minWidth: '20em', maxWidth: '100%'}}
                                MenuProps={MenuProps}
                                endAdornment={
                                    <React.Fragment>
                                        <IconButton
                                            size="small"
                                            sx={{ display: rolesValue.length > 0 ? "" : "none" }}
                                            onClick={handleClearClick}>
                                            <ClearIcon fontSize="inherit" />
                                        </IconButton>
                                        <Box sx={{ width: '1em' }}>{' '}</Box>
                                    </React.Fragment>
                                }
                            >
                                {allRoles.map((role) => (
                                    <MenuItem
                                        key={role}
                                        value={role}
                                        style={getStyles(role)}
                                    >
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        :
                    
                        <Typography sx={{paddingLeft: '1em'}}>{rolesValue}</Typography>
                    }
                </Box>
                <Box sx={{paddingTop: '3ex', display: 'flex', alignItems: 'center'}}>
                    <Typography sx={{fontWeight: 'bold', width: '7em', whiteSpace: 'nowrap'}}>Locking:</Typography>
                    <FormControl >
                        <FormControlLabel control={<Switch checked={locksMultiple} onChange={handleLocksChange} />} label={locksMultiple ? 'Multi-user' : 'Single user'} />
                    </FormControl>
                </Box>
                <Box sx={{paddingTop: '3ex'}}>
                    <Typography sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>Routing:</Typography>
                    <Box sx={{ height: '12em', width: '100%', paddingTop: '1ex' }}>
                        { /*
                        <DataGrid sx={{height: '14em'}}
                            rows={routeData}
                            columns={routeColumns}
                            pageSize={4}
                            rowsPerPageOptions={[4]}
                            checkboxSelection
                        />
                        */}
                        <TableContainer component={Paper} sx={{height: '11.5em'}}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{paddingTop: 0, paddingBotton: 0}}>
                                        <TableCell sx={{paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold'}}>Type</TableCell>
                                        <TableCell sx={{paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold'}}>Destination</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {routeData.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            onClick = {(event) => handleRouteClicked(event, row.id)}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            selected={isRouteSelected(row.id)}
                                        >
                                            <TableCell component="th" scope="row" sx={{width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem'}}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={{paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem'}}>{row.dest}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box sx={{paddingTop: '0px', paddingLeft: '0px'}}>
                    <Tooltip title="Add Route">
                        <IconButton color="primary" onClick={handleAddRouteClick}>
                            <AddBoxIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Route">
                        <IconButton disabled={!deleteRouteEnabled} onClick={handleDeleteRouteClick} >
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>    
                    <AddRouteDialog step={step} stepList={allSteps} open={addRouteOpen} onCancel={handleAddRouteCancel} onSave={handleAddRouteSave}
                        stepIndex={stepIndex} workflowList={workflowList} workflowIndex={workflowIndex} routes={routeData} 
                        omitStart={omitStart} endLabel={endLabel} />    
                </Box>
                
            </DialogContent>
            <DialogActions sx={{paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)'}}>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function SubAttributesEdit(props) {
    const {
        def,
        allRoles,
        readOnly,
        onChange,
        onSelection,
        saveEnabled,
        refresh,
        update,
        workflowIndex,
        workflowList,
        stepIndex,
        pindex, // parent index label '1' or '1.2' 
        parentSelectionIndex,
        ...other
    } = props;

    const [callStep, setCallStep] = React.useState(null);
    const [subdef, setSubdef] = React.useState({});
    const [substeps, setSubsteps] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState(-1);
    // const [allRoles, setAllRoles] = React.useState([]);
    const [stepRoles, setStepRoles] = React.useState([]); // items are also arrays
    const [contextMenu, setContextMenu] = React.useState(null);
    const [stepEditEnabled, setStepEditEnabled] = React.useState(false);
    const [undoSaveEnabled, setUndoSaveEnabled] = React.useState(false);

    const [dialogTitle, setDialogTitle] = React.useState('Edit Step');
    const [dialogStep, setDialogStep] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [stepDefLabels, setStepDefLabels] = React.useState({});

    // expanded subroutines
    const [expanded, setExpanded] = React.useState([]);

    const endLabel = 'EXIT';

    React.useEffect(() => {
        
        setEditIndex(-1);
        setStepEditEnabled(false);
        if (def) {
            // setPublishWorkflow(def.label);
            
            if (Array.isArray(def.steps)) {
                // this is the call step
                const cstep = def.steps[stepIndex];
                setCallStep(cstep);
                if (cstep.properties) {
                    const ssteps = cstep.properties.substeps;
                    setSubsteps(ssteps);

                    let sroles = [];
                    ssteps.forEach(step => {
                        if (Array.isArray(step.roles)) {
                            sroles.push(step.roles);
                        } else {
                            sroles.push([]);
                        }
                    });
                    setStepRoles(sroles);

                    const targetDef = cstep.properties.target;
                    netGet('/api/workflow/def/list?categ=MODEL_SUB')
                        .then(response => response.json())
                        .then( data => {
                            if ( data && Array.isArray(data) ) {
                                const cdef = data.find( item => item.name === targetDef);
                                console.log('Found def ' + targetDef + ' : ' + cdef.steps.length);
                                if ( cdef ) {
                                    setSubdef(cdef);
                                }
                            }
                        });
                }
            }
        }
    }, [def] );

    /*
    React.useEffect( () => {
        setUndoSaveEnabled(saveEnabled);
    }, [saveEnabled]);
    */
    

    React.useEffect(() => {
        netGet('/api/workflow/step/def/list?mask=0')
            .then(response => response.json())
            .then(steps => {
                if ( Array.isArray(steps) ) {
                    let labels = {};
                    steps.forEach( item => {
                        if ( item.name === 'END' ) {
                            labels[item.name] = endLabel;
                        } else {
                            labels[item.name] = item.label;
                        }
                    });
                    setStepDefLabels(labels);
                }
            }).catch( error => {
                console.log('Error fetching step definition list: ' + error);
            });

    }, []);

    const isLockedByMe = () => {
        return def && typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const handleStepEditAction = () => {
        handleContextMenuClose();
        setDialogOpen(true);
        console.log('Substeps editing...');
    };

    const handleEditClick = (index) => {
        if ( isLockedByMe() ) {
            setEditIndex(index);
            setDialogStep(substeps[index]);
            setStepEditEnabled(true);
        } else {
            setEditIndex(-1);
            setDialogStep(null);
            setStepEditEnabled(false);
        }
    };

    const handleContextMenu = (event,index) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
        if ( index >= 0 ) {
            handleEditClick(index);
        }
    };


    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleDialogSave = (name, description, roles, multiLock, routes) => {
        setDialogOpen(false);
        if ( editIndex >= 0 ) {
            if ( !def.saveSteps ) {
                // save a copy just in case
                def.saveSteps = [ ...def.steps];
            }
            let step = { ...substeps[editIndex]};
            step.label = name;
            step.description = description;
            step.roles = roles;
            step.routes = routes;
            stepRoles[editIndex] = roles;
            if ( ! step.properties ) {
                step.properties = {};
            }
            step.properties.multiLock = multiLock;
            substeps[editIndex] = step;
            // setUndoSaveEnabled(true); // not necessary to enable, an event should come back in response to onChange
            // saveToServer(def); // don't save here, hold it 
            
            if ( typeof onChange === 'function') {
                onChange(stepIndex);
            }
            
        }
    };

    const handleDialogCancel = () => {
        setDialogOpen(false);
    };

    const undoSaveHandler = () => {
        handleContextMenuClose();
        if ( typeof def.saveSteps !== 'undefined') {
            // put back the steps
            def.steps = [ ...def.saveSteps];
            delete def.saveSteps;
            // report we have no changes
            if ( typeof onChange === 'function') {
                onChange(false, workflowIndex);
            }
        }
    };

    const getStepLabel = (step) => {
        if ( step && step.name === 'END' ) {
            return endLabel;
        }
        return step.label;
    };

    const resolveDestination = (route) => {
        
        let r = route ? { ...route} : { dest: 'NEXT STEP'};
        if (route) {
            // console.log(JSON.stringify(r) + ' : ' + JSON.stringify(def.steps[r.targetId]));
            r.dest = 'NEXT STEP';
            switch (r.name) {
                case ('STEP'):
                    r.dest = `STEP ${pindex}.${r.targetId}: ${r.targetId >= 0 ? (substeps && substeps[r.targetId] ? getStepLabel(substeps[r.targetId]) : '') : ''}`;
                    break;
                case ('WORKFLOW'):
                    let dest = '';
                    if (route.targetWorkflowId >= 0) {
                        const w = workflowList.find(item => item.id === r.targetWorkflowId);
                        if (w) {
                            dest = `${dest}${w.label}`;

                            if (r.targetId >= 0 && Array.isArray(w.steps)) {
                                dest = `${dest} / ${r.targetId + 1}: ${getStepLabel(w.steps[r.targetId])}`;
                            }
                        } else {
                            dest = 'MISSING DESTINATION';
                        }
                    }
                    r.dest = dest;
                    break;
                default:
                    if (substeps && substeps[r.targetId] && substeps[r.targetId].name !== 'END') {
                        r.dest = 'NEXT STEP';
                        // console.log('NEXT: ' + JSON.stringify(r));
                    }
                    break;

            }
        }
        return r;
    };

    const handleItemClick = (index) => {
        if ( !readOnly ) {
            setEditIndex(index);
            setDialogStep(substeps[index]);
            setStepEditEnabled(true);
            if ( typeof onSelection === 'function') {
                onSelection(stepIndex, index);
            }
        } else {
            setEditIndex(-1);
            setDialogStep(null);
            setStepEditEnabled(false);
        }
    };



    return (
        <List sx={{ paddingLeft: '50px' }}>
            {
                substeps.map((substep, subindex) => 
                    { return substep.name !== 'START' ?
                    <ListItem key={`WISS-${pindex}-${substep.id}`} alignItems="flex-start" sx={{display: 'block'}}
                                           >
                        <ListItemButton alignItems="flex-start" onClick={() => handleItemClick(subindex)}
                            selected={subindex === editIndex && !readOnly && parentSelectionIndex === -1}
                            onContextMenu={(event) => handleContextMenu(event, subindex)} >
                            <ListItemAvatar>
                                <Avatar sx={{ width: 40, height: 24 }}>
                                    <Typography>{pindex}.{subindex}</Typography>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText disableTypography={true}
                                primary={
                                    <Typography sx={{ fontWeight: 'bold' }} >{getStepLabel(substep)}</Typography>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex', paddingBottom: '1ex' }}>{stepDefLabels[substep.name] ? stepDefLabels[substep.name] : substep.name}</Typography>
                                        <Typography sx={{ fontSize: '14px' }}>{substep.description}</Typography>
                                        <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                            <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Roles: </Typography>
                                            <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                {Array.isArray(substep.roles) && substep.roles.length > 0 ?
                                                    substep.roles.sort().reduce((total, role) => total.length > 0 ? total + ', ' + role : role) :
                                                    ''
                                                }
                                            </Typography>
                                        </Box>
                                        <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                            <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Locking: </Typography>
                                            <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                {substep.properties && substep.properties.multiLock ? 'Multi-user' : 'Single user'
                                                }
                                            </Typography>
                                        </Box>
                                        <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                            <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Routes: </Typography>
                                            <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                {substep && Array.isArray(substep.routes) && substep.routes.length > 0 ?
                                                    substep.routes.map(r => resolveDestination(r)).reduce((total, route) => typeof total === 'string' && total.length > 0 ? total + ',\u00a0 ' + route.dest : route.dest, '') :
                                                    (substep && substep.name !== 'END' ? 'NEXT STEP' : '')
                                                }
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                } />
                        </ListItemButton>
                    </ListItem> : null }
                )
            }
            <Menu
                open={contextMenu !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={handleStepEditAction} disabled={readOnly /* !stepEditEnabled */}>Edit...</MenuItem>
                <MenuItem onClick={undoSaveHandler} disabled={!undoSaveEnabled}>Undo</MenuItem>
            </Menu>
            <EditStepDialog open={dialogOpen} title={dialogTitle} step={dialogStep} stepIndex={editIndex} 
                allSteps={substeps} allRoles={allRoles} omitStart={true} endLabel={endLabel}
                onSave={handleDialogSave} onCancel={handleDialogCancel}
                workflowList={workflowList} workflowIndex={workflowIndex} />
        </List>
    );
}

function WorkflowDefinitionAttributesEdit(props) {
    const {
        def,
        onChange,
        saveEnabled,
        refresh,
        update,
        workflowIndex,
        workflowList,
        ...other
     } = props;
    // const [ workflowUpdate, setWorkflowUpdate] = React.useState(0); // increment to update
    const [editIndex, setEditIndex] = React.useState(-1);
    const [allRoles, setAllRoles] = React.useState([]);
    const [stepRoles, setStepRoles] = React.useState([]); // items are also arrays
    const [stepEditEnabled, setStepEditEnabled] = React.useState(false);
    const [undoSaveEnabled, setUndoSaveEnabled] = React.useState(false);
    const [publishEnabled, setPublishEnabled] = React.useState(false);
    const [contextMenu, setContextMenu] = React.useState(null);

    const [dialogTitle, setDialogTitle] = React.useState('Edit Step');
    const [dialogStep, setDialogStep] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [publishDialogTitle, setPublishDialogTitle] = React.useState('Publish Workflow');
    const [publishWorkflow, setPublishWorkflow] = React.useState('');
    const [publishOpen, setPublishOpen] = React.useState(false);
    const [stepDefLabels, setStepDefLabels] = React.useState({});
    // expanded subroutines
    const [expanded, setExpanded] = React.useState([]);

    const [modified, setModified] = React.useState(false);


    const theme = useTheme();

    /*
    React.useEffect(() => {
        if (instance) {
            console.log('Fetching workflow definition ' + instance.definitionId + '...');

            netGet('/api/workflow/def/' + instance.definitionId)
                .then(response => response.json())
                .then(data => {
                    // console.log(Array.isArray(data.steps) + ' : ' + data.steps.length);
                    setWorkflowDef(data);
                    /
                });
        }
        
    }, [workflowUpdate, instance, update]); 
      */
    
    React.useEffect(() => {
        netGet('/api/auth/roles')
            .then(response => response.json())
            .then(data => {
                if ( data && Array.isArray(data) ) {
                    console.log('Roles: ' + data.length);
                    setAllRoles(data);
                }
            });
        setEditIndex(-1);
        setStepEditEnabled(false);
        setPublishEnabled(canPublish());
        if (def) {
            setPublishWorkflow(def.label);
            if (Array.isArray(def.steps)) {
                let sroles = [];
                def.steps.forEach(step => {
                    if (Array.isArray(step.roles)) {
                        sroles.push(step.roles);
                    } else {
                        sroles.push([]);
                    }
                });
                setStepRoles(sroles);
            }
        }
    }, [def] );

    React.useEffect( () => {
        setUndoSaveEnabled(saveEnabled);
    }, [saveEnabled]);

    React.useEffect(() => {
        netGet('/api/workflow/step/def/list?mask=0')
            .then(response => response.json())
            .then(steps => {
                if ( Array.isArray(steps) ) {
                    let labels = {};
                    steps.forEach( item => {
                        labels[item.name] = item.label;
                    });
                    setStepDefLabels(labels);
                }
            }).catch( error => {
                console.log('Error fetching step definition list: ' + error);
            });

    }, []);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const canPublish = () => {
        if ( !def ) {
            return false;
        }
        return ( ((def.flag & 6) == 0) ); // not locked or published
    };

    const handleEditClick = (index) => {
        if ( isLockedByMe() ) {
            setEditIndex(index);
            setDialogStep(def.steps[index]);
            setStepEditEnabled(true);
        } else {
            setEditIndex(-1);
            setDialogStep(null);
            setStepEditEnabled(false);
        }
    };

    const publishToServer = (def) => {
        netPost('/api/workflow/def/publish', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not publish workflow definition: status = ' + resp.status);
                } else {
                    if ( refresh ) {
                        refresh(def);
                    }
                }
            });
    };

    const handleSaveClick = (index) => {
        let sroles = stepRoles.map((x) => x.map( (r) => r ));
        if ( typeof def.steps[index] !== 'undefined' ) {
            def.steps[index].roles = sroles[index];
            saveToServer(def);
            if ( onChange ) {
                onChange(def);
            }
        }
        setEditIndex(-1);
    };

   

    const isLockedByMe = () => {
        return def && typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const handleStepEditAction = () => {
        handleContextMenuClose();
        setDialogOpen(true);
    };

    const handlePublishAction = () => {
        handleContextMenuClose();
        setPublishOpen(true);
    };

    const handlePublishSave = () => {
        setPublishOpen(false);
        publishToServer(def);
    };

    const handlePublishCancel = () => {
        setPublishOpen(false);
    };

    const saveToServer = (def) => {
        netPost('/api/workflow/def/update', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save workflow definition: status = ' + resp.status);
                } else {
                    if ( refresh ) {
                        refresh(def);
                    }
                }
            });
    };

    const handleContextMenu = (event,index) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
        if ( index >= 0 ) {
            handleEditClick(index);
        }
    };


    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleDialogSave = (name, description, roles, multiLock, routes) => {
        setDialogOpen(false);
        if ( editIndex >= 0 ) {
            if ( !def.saveSteps ) {
                // save a copy just in case
                def.saveSteps = [ ...def.steps];
            }
            let step = { ...def.steps[editIndex]};
            step.label = name;
            step.description = description;
            step.roles = roles;
            step.routes = routes;
            stepRoles[editIndex] = roles;
            if ( ! step.properties ) {
                step.properties = {};
            }
            step.properties.multiLock = multiLock;
            def.steps[editIndex] = step;
            // setUndoSaveEnabled(true); // not necessary to enable, an event should come back in response to onChange
            // saveToServer(def); // don't save here, hold it 
            if ( typeof onChange === 'function') {
                onChange(true, workflowIndex);
            }
        }
    };

    const handleDialogCancel = () => {
        setDialogOpen(false);
    };

    const undoSaveHandler = () => {
        handleContextMenuClose();
        if ( typeof def.saveSteps !== 'undefined') {
            // put back the steps
            def.steps = [ ...def.saveSteps];
            delete def.saveSteps;
            // report we have no changes
            if ( typeof onChange === 'function') {
                onChange(false, workflowIndex);
            }
        }
    };

    const resolveDestination = (route) => {
        
        let r = route ? { ...route} : { dest: 'NEXT STEP'};
        if (route) {
            // console.log(JSON.stringify(r) + ' : ' + JSON.stringify(def.steps[r.targetId]));
            r.dest = 'NEXT STEP';
            switch (r.name) {
                case ('STEP'):
                    r.dest = `STEP ${r.targetId + 1}: ${r.targetId >= 0 ? (def && def.steps && def.steps[r.targetId] ? def.steps[r.targetId].label : '') : ''}`;
                    break;
                case ('WORKFLOW'):
                    let dest = '';
                    if (route.targetWorkflowId >= 0) {
                        const w = workflowList.find(item => item.id === r.targetWorkflowId);
                        if (w) {
                            dest = `${dest}${w.label}`;

                            if (r.targetId >= 0 && Array.isArray(w.steps)) {
                                dest = `${dest} / ${r.targetId + 1}: ${w.steps[r.targetId].label}`;
                            }
                        } else {
                            dest = 'MISSING DESTINATION';
                        }
                    }
                    r.dest = dest;
                    break;
                default:
                    if (def && def.steps && def.steps[r.targetId] && def.steps[r.targetId].name !== 'END') {
                        r.dest = 'NEXT STEP';
                        // console.log('NEXT: ' + JSON.stringify(r));
                    }
                    break;

            }
        }
        return r;
    };

    const toggleCallItem = (index) => {
        // TODO: handle more than one index
        let ee = [ ...expanded];
        if ( ee.includes(index) ) {
            ee = [];
        } else {
            ee.push(index);
        }
        setExpanded(ee);
    };

    const handleSubstepsSelection = (index, subindex) => {
        setEditIndex(-1);
    };

    const handleSubstepChange = (index) => {
        setDialogOpen(false);
        if ( typeof onChange === 'function') {
            onChange(true, workflowIndex);
        }
    };

    return (
        def && Array.isArray(def.steps) && def.steps.length > 0 ? (
            <Box sx={{ display: 'grid', gridTemplateRows: '1fr min-content', width: '100%', height: '100%', padding: '1ex'}}>
                <Box sx={{ width: '100%', height: '100%', position: 'relative', overflowY: 'auto', padding: '1ex', paddingTop: '2ex', minHeight: '3em' }}
                    >
                    <EditIndicator readOnly={!isLockedByMe()} />
                    <Typography sx={{ fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>{def.label}</Typography>
                    <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex'}}>WORKFLOW</Typography>
                    <Typography sx={{ paddingTop: '1ex', paddingBottom: '2ex' }}>{def.description}</Typography>
                    <List >
                        {
                            def.steps.map((step, index) => (
                                <ListItem key={'WIS' + step.id} alignItems="flex-start" sx={{display: 'block'}}
                                           >
                                    <ListItemButton onClick={() => handleEditClick(index)} alignItems="flex-start" selected={editIndex === index && isLockedByMe()}
                                        onContextMenu={(event) => handleContextMenu(event, index)} >
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 24, height: 24 }}>
                                                <Typography>{index + 1}</Typography>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText disableTypography={true}
                                            primary={
                                                <Typography sx={{ fontWeight: 'bold' }} >{step.label}</Typography>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex', paddingBottom: '1ex'}}>{stepDefLabels[step.name] ? stepDefLabels[step.name] : step.name}</Typography>
                                                    <Typography sx={{ fontSize: '14px' }}>{step.description}</Typography>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Roles: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {Array.isArray(step.roles) && step.roles.length > 0 ?
                                                                step.roles.sort().reduce((total, role) => total.length > 0 ? total + ', ' + role : role) :
                                                                ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Locking: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {step.properties && step.properties.multiLock ? 'Multi-user' : 'Single user'
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Routes: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {step && Array.isArray(step.routes) && step.routes.length > 0 ?
                                                                step.routes.map(r => resolveDestination(r)).reduce((total, route) => typeof total === 'string' && total.length > 0 ? total + ',\u00a0 ' + route.dest : route.dest, '') :
                                                                ( step && step.name !== 'END' ? 'NEXT STEP' : '')
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        />
                                        {step.name === 'CALL' && 
                                            <IconButton onClick={() => toggleCallItem(index)}>
                                                {expanded.includes(index) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                            </IconButton> }
                                    </ListItemButton>
                                    {step.name === 'CALL' &&

                                       <Collapse in={expanded.includes(index)} timeout="auto" unmountOnExit >
                                            <SubAttributesEdit def={def} workflowList={workflowList} workflowIndex={workflowIndex}
                                                stepIndex={index} readOnly={!isLockedByMe()} pindex={`${index+1}`} allRoles={allRoles} 
                                                parentSelectionIndex={editIndex} onSelection={handleSubstepsSelection} onChange={handleSubstepChange} />
                                        </Collapse>
                                    }
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
                <Box sx={{ padding: '1ex', paddingRight: '2ex', whiteSpace: 'nowrap', overflowX: 'hidden', display: 'flex', justifyContent: 'space-between', width: '100%' }} color={theme.palette.primary.main}>
                    <Box>
                        <Tooltip title="Edit Step">
                            <IconButton color="inherit" disabled={!stepEditEnabled} onClick={handleStepEditAction}>
                                <AutoFixNormalIcon color="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Undo Modifications">
                            <IconButton color="inherit" disabled={!undoSaveEnabled} onClick={undoSaveHandler}>
                                <UndoIcon color="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Tooltip title="Publish This Workflow">
                        <Button variant="outlined" startIcon={<PublishedWithChangesIcon fontSize="inherit" />} disabled={!publishEnabled} onClick={handlePublishAction}>
                            Publish
                        </Button>
                    </Tooltip>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleStepEditAction} disabled={!stepEditEnabled}>Edit...</MenuItem>
                        <MenuItem onClick={undoSaveHandler} disabled={!undoSaveEnabled}>Undo</MenuItem>
                    </Menu>
                    <EditStepDialog open={dialogOpen} title={dialogTitle} step={dialogStep} stepIndex={editIndex} allSteps={def.steps} allRoles={allRoles} onSave={handleDialogSave} onCancel={handleDialogCancel}
                        workflowList={workflowList} workflowIndex={workflowIndex} />
                    <WorkflowPublishDialog open={publishOpen} title={publishDialogTitle} workflowName={publishWorkflow} onSave={handlePublishSave} onCancel={handlePublishCancel} />
                </Box>
            </Box>
        ) : null
      );

}


function EditWorkflow(props) {

    const [updateDef, setUpdateDef] = React.useState(0);
    const [updateDefId, setUpdateDefId] = React.useState(-1);

    const [workflowList, setWorkflowList] = React.useState([]);
    const [modelList, setModelList] = React.useState([]);
    const [subroutineList, setSubroutineList] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
    const [saveWorkflowEnabled, setSaveWorkflowEnabled] = React.useState(false);
    const [checkinEnabled, setCheckinEnabled] = React.useState(false);
    const [addWorkflowEnabled, setAddWorkflowEnabled] = React.useState(true);
    const [deleteWorkflowEnabled, setDeleteWorkflowEnabled] = React.useState(false);
    const [publishEnabled, setPublishEnabled] = React.useState(false);

    const [workflowDialogOpen, setWorkflowDialogOpen] = React.useState(false);
    const [workflowDialogTemplate, setWorkflowDialogTemplate] = React.useState([]);
    const [workflowDialogName, setWorkflowDialogName] = React.useState('');
    const [workflowDialogDescription, setWorkflowDialogDescription] = React.useState('');
    const [workflowDialogTitle, setWorkflowDialogTitle] = React.useState('Create Workflow');

    const [checkinDialogOpen, setCheckinDialogOpen] = React.useState(false);
    const [checkinDialogTitle, setCheckinDialogTitle] = React.useState('Checkin Workflow');
    const [checkinDialogWorkflowName, setCheckinDialogWorkflowName] = React.useState('');

    const [revokeDialogOpen, setRevokeDialogOpen] = React.useState(false);
    const [revokeDialogTitle, setRevokeDialogTitle] = React.useState('Revoke Checkout');

    const [workflowDeleteOpen, setWorkflowDeleteOpen] = React.useState(false);
    const [workflowDeleteName, setWorkflowDeleteName] = React.useState('');
    const [workflowSaveIndex, setWorkflowSaveIndex] = React.useState(-1);

    const [workflowSaveOpen, setWorkflowSaveOpen] = React.useState(false);
    const [workflowSaveName, setWorkflowSaveName] = React.useState('');

    const [docList, setDocList] = React.useState([]);
    const [modified, setModified] = React.useState([]);

    const theme = useTheme();

    React.useEffect(() => {
        loadInstances();
    }, []);

    React.useEffect(() => {
        if (updateDefId >= 0) {
            netGet('/api/workflow/def/' + updateDefId)
                .then(response => response.json())
                .then(def => {
                    if (def && def.id) {
                        if (def.category.startsWith('MODEL')) {
                            let mlist = modelList.map((r) => {
                                if (r.id === def.id) {
                                    return def;
                                }
                                return r;
                            });
                            setModelList(mlist);
                            loadWorkflowDialogTemplateList(workflowList, mlist);
                        } else {
                            // console.log('Updating Workflow Definition: ' + def.id);
                            let slist = workflowList.map((r) => {
                                if (r.id === def.id) {
                                    return def;
                                }
                                return r;
                            });
                            setWorkflowList(slist);
                            const cdef = slist.find((item) => item.id === def.id);
                            if (cdef) {
                                const i = slist.indexOf(cdef);
                                // console.log('Found def to update ' + cdef + ' at index ' + i);
                                if (i === selectedIndex) {
                                    updateSelectedItem(cdef, i);
                                }
                            }
                            loadWorkflowDialogTemplateList(slist, modelList);
                        }
                    }
                }).catch(error => {
                    console.log(`Error fetching def for ${updateDefId}: ${error}`);
                });
        }
    }, [updateDef,updateDefId]);


    const workflowMessage = (message) => {
        let wevent = message.data;
        // console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowDefinition') {
            if ( wevent.action === 'DELETE' ) {
                let dindex = -1;
                const def = workflowList.find( (item, index) => {
                    if ( item.id === wevent.id ) {
                        dindex = index;
                        return true;
                    }
                });
                if ( def && dindex >= 0 ) {
                    let wlist = [ ...workflowList];
                    wlist.splice(dindex, 1);
                    setWorkflowList(wlist);
                    loadWorkflowDialogTemplateList(wlist, modelList);
                } else {
                    let mindex = -1;
                    const model = modelList.find( (item,index) => {
                        if ( item.id === wevent.id) {
                            mindex = index;
                            return true;
                        }
                    });
                    if ( model && mindex >= 0 ) {
                        let mlist = [ ...modelList];
                        mlist.splice(mindex, 1);
                        setModelList(mlist);
                        loadWorkflowDialogTemplateList(workflowList, mlist);
                    }
                }
            } else if ( wevent.action === 'NEW' ) {
                loadInstances(wevent.id);
                // setUpdateWorkflowList(updateWorkflowList => updateWorkflowList + 1);
            } else if ( wevent.action === 'CHECKOUT' || wevent.action === 'REVOKE_CHECKOUT') {
                let cdef = workflowList.find( (item) => item.id === wevent.previousId );
                if ( cdef ) {
                    cdef.id = wevent.id;
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                }
            } else {
                const def = workflowList.find( (item) => item.id === wevent.id);
                if ( def ) {
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                }
            }
        }
    };

    MessageHooks["workflow"]["WorkflowAttributesEdit"] = workflowMessage;


    const loadInstances = () => {
        /*
           Do not pull subroutines as they are not useful for top level workflows.
        */
        netGet('/api/workflow/def/list?categ=DEF')
            .then(response => response.json())
            .then(defs => {
                if ( defs && Array.isArray(defs) ) {
                    console.log('Workflow Definitions: ' + defs.length);
                    setWorkflowList(defs);
                }

                netGet('/api/workflow/def/list?categ=MODELS')
                    .then(response2 => response2.json())
                    .then(models => {
                        if (models && Array.isArray(models)) {
                            let mlist = [];
                            let slist = [];
                            models.forEach( item => {
                                if ( item.category.endsWith('SUB') ) {
                                    slist.push(item);
                                } else {
                                    mlist.push(item);
                                }
                            });
                            console.log('Workflow Models: ' + mlist.length);
                            console.log('Subroutines: ' + slist.length);
                            setModelList(mlist);
                            setSubroutineList(slist);

                            loadWorkflowDialogTemplateList(defs, mlist);
                        }
                        
                    }).catch( error => {
                        console.log('Error fetching models: ' + error);
                    });
            }).catch ( error => {
                console.log('Error fetching profiles: ' + error);
            });
    };

    const loadWorkflowDialogTemplateList = (defs, models) => {
        let temps = models.concat(defs);
        temps.sort();
        let tnames = temps.map((def) => def.label);
        setWorkflowDialogTemplate(tnames);
    };

    /*
    const loadModels = () => {
        netGet('/api/workflow/def/list?categ=MODEL')
            .then(response => response.json())
            .then(data => {
                if ( data && Array.isArray(data) ) {
                    console.log('Workflow Models: ' + data.length);
                    setModelList(data);

                    let temps = data.concat(workflowList);
                    temps.sort();
                    let tnames = temps.map( (def) => def.label);
                    setWorkflowDialogTemplate(tnames);
                }
            });
    }

    */


    const serverDelete = (def) => {
        netFetch('/api/workflow/def/' + def.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not delete workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverCheckout = (index) => {
        const def = workflowList[index];
        netPost('/api/workflow/def/checkout', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkout workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverCheckin = (index, msg) => {
        const def = workflowList[index];
        const message = msg ? msg : '';
        const payload = { definition: def, message: message};
        netPost('/api/workflow/def/checkin', payload)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkin workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverRevokeCheckout = (index) => {
        const def = workflowList[index];
        netPost('/api/workflow/def/revoke_checkout', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not revoke checkout workflow definition: status = ' + resp.status);
                }
            });
    };

    const handleContextMenu = (event,index) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
        if ( index >= 0 ) {
            handleItemClick(index);
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleItemClick = (index) => {
        /*
        if ( saveRequired(selectedIndex) ) {
            const def = workflowList[selectedIndex];
            if (def) {
                setWorkflowSaveName(def.label);
                setWorkflowSaveIndex(selectedIndex);
                setWorkflowSaveOpen(true);
            }
        }
        */
        setSelectedIndex(index);
        if ( isLocked(index) ) {
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            setCheckinEnabled(isLockedByMe(index));
        } else {
            setCheckoutEnabled(true);
            setDeleteWorkflowEnabled(true);
            setCheckinEnabled(false);
        }
        setPublishEnabled(canPublish(index));
    };

    const updateSelectedItem = (def, index) => {
        const locked = typeof def.user === 'string' && def.user.length > 0;
        const lockedByMe = typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
        const canPublish =  (def.flag & 2) == 0;
        setSelectedIndex(index);
        if ( locked ) {
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            setCheckinEnabled(lockedByMe /* && !saveRequired(index) */);
        } else {
            setCheckoutEnabled(true);
            setDeleteWorkflowEnabled(true);
            setCheckinEnabled(false);
        }
        setPublishEnabled(canPublish);
    };

    const lockIconColor = (index) => {
        const def = workflowList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if (def.user && def.user !== '') {
            if (def.user === UserInfo.info.name) {
                return 'green';
            } else {
                return '#cc0000';
            }
        }
        return 'transparent';
    };

    const publishedIconColor = (index) => {
        const def = workflowList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if ( (def.flag & 2) != 0) {
            return 'black';
        }
        return 'transparent';
    };

    const canPublish = (index) => {
        const def = workflowList[index];
        return ( (def.flag & 2) == 0);
    };

    const lockIconLabel = (index) => {
        const def = workflowList[index];
        if ( def.user && def.user !== '' ) {
            return 'Locked by ' + def.user;
        }
        return '';
    };

    

    const isLockedByMe = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0;
    };

    const handleCheckoutAction = () => {
        if (selectedIndex >= 0) {
            serverCheckout(selectedIndex);
        }
    };

    const handleMenuCheckout = () => {
        handleContextMenuClose();
        handleCheckoutAction();
    };

    const handleMenuRevokeCheckout = () => {
        handleContextMenuClose();
        handleRevokeCheckoutAction();
    };

    const handleRevokeCheckoutAction = () => {
        setRevokeDialogOpen(true);

    };

    const handleRevokeCheckoutSave = (index) => {
        setRevokeDialogOpen(false);
        if (selectedIndex >= 0) {
            serverRevokeCheckout(selectedIndex);
        }
    };

    const handleRevokeCheckoutCancel = () => {
        handleContextMenuClose();
        setRevokeDialogOpen(false);
    };

    const handleCheckinAction = () => {
       setCheckinDialogOpen(true);
    };

    const handleCheckinSave = (message) => {
        setCheckinDialogOpen(false);
        if (selectedIndex >= 0) {
            serverCheckin(selectedIndex, message);
        }
    };

    const handleCheckinCancel = () => {
        setCheckinDialogOpen(false);
    };

    const handleMenuCheckin = () => {
        handleContextMenuClose();
        handleCheckinAction();
    };

    const handleMenuDeleteWorkflow = () => {
        handleContextMenuClose();
        handleDeleteWorkflowAction();
    };

    const handleDeleteWorkflowAction = () => {
        const workflow = workflowList[selectedIndex];
        setWorkflowDeleteName(workflow.label);
        setWorkflowDeleteOpen(true);
    }

    const deleteWorkflowSave = () => {
        setWorkflowDeleteOpen(false);
        const workflow = workflowList[selectedIndex];
        serverDelete(workflow);
    };

    const deleteWorkflowCancel = () => {
        
        setWorkflowDeleteOpen(false);

    };

    const handleCreateWorkflowAction = () => {
        netGet('/api/doc/roots?sort=alpha')
            .then(response => response.json())
            .then(docs => {
                if ( Array.isArray(docs) ) {
                    console.log('Got ' + docs.length + ' documents.');
                    setDocList(docs);
                    setWorkflowDialogOpen(true);
                }
            }).catch ( error => {
                console.log('Error fetching profiles: ' + error);
            });
        
    };

    const workflowDialogCreateHandler = (props) => {
        handleContextMenuClose();
        setWorkflowDialogOpen(false);
        if ( typeof props.name === 'string' && props.name.length > 0 ) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime()/100);
            const suffix = timestamp.toString(16);
            const id = `${props.name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            // console.log('name: ' + props.name + ',id: ' + id);
            let newWorkflow = {
                name: id,
                label: props.name,
                description: props.description,
                flag: 0,
                category: 'DEF',
                steps: [],
                attributes: { type: props.type }
            }
            if ( props.type === 'doc' ) {
                newWorkflow.attributes['document'] = props.document;
                newWorkflow.attributes['documentTitle'] = props.documentTitle;
            }
            if ( typeof props.template === 'string' && props.template.length > 0 && props.template !== 'None') {
                const templates = workflowList.concat(modelList);
                let def = templates.find( (item) => item.label === props.template);
                if ( def ) {
                    def.steps.forEach( (item) => {
                        let step = { ...item};
                        if ( step.name === 'CALL' ) {
                            const target = step.properties.target;
                            const sdef = subroutineList.find ( item => item.name === target);
                            if ( sdef ) {
                                const substeps = [ ...sdef.steps];
                                step.properties.substeps = substeps;
                            }
                        }
                        newWorkflow.steps.push(step);
                    });
                    if ( def.category.endsWith('SUB') ) {
                        newWorkflow.category = 'DEF_SUB';
                    }
                } else {
                    newWorkflow.steps.push( 
                        { 
                            name: 'START',
                            id: 0,
                            label: 'START',
                        }
                    );
                }

            } else {
                newWorkflow.steps.push( 
                    { 
                        name: 'START',
                        id: 0,
                        label: 'START',
                    }
                );
            }
            console.log(JSON.stringify(newWorkflow));
            addServerWorkflow(newWorkflow);
        }
    };

    const addServerWorkflow = (workflow) => {
        netPost('/api/workflow/def', workflow)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not create workflow definition: status = ' + resp.status);
                }
            });
    };

    const workflowDialogCancelHandler = () => {
        setWorkflowDialogOpen(false);
    }


    const handleChange = (event) => {
        // setInstanceIndex(event.target.value);
    };

    const saveToServer = (def) => {
        netPost('/api/workflow/def/update', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save workflow definition: status = ' + resp.status);
                } else {
                    delete def.saveSteps;
                    setSaveWorkflowEnabled(false);
                    handleItemClick(selectedIndex);
                    unmarkModified();
                }
            });
    };

    const saveWorkflowHandler = () => {
        saveToServer(workflowList[selectedIndex]);
    };

    const workflowChangeHandler = (modified, index) => {
        if ( modified ) {
            markModified();
            setSaveWorkflowEnabled(true);
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            setCheckinEnabled(false);
            setPublishEnabled(false);
        } else {
            setSaveWorkflowEnabled(false);
            handleItemClick(selectedIndex);
            unmarkModified();
        }
    };

    const markModified = () => {
        const m = [ ...modified];
        if ( !m.includes(selectedIndex) ) {
            m.push(selectedIndex);
        }
        setModified(m);
        
        setSaveWorkflowEnabled(true);
        // setUndoSaveEnabled(true);
        // modified inhibites checkin
        setCheckinEnabled(false);
    };

    const unmarkModified = () => {
        const m = modified.filter( item => item !== selectedIndex);
        setModified(m);
        setCheckinEnabled(isLockedByMe(selectedIndex));
        setSaveWorkflowEnabled(false);
        // setUndoSaveEnabled(false);
    };

    const doUnmarkModified = (index) => {
        const m = modified.filter( item => item !== index);
        setModified(m);
        // setCheckinEnabled(isLockedByMe(index));
    };


    const modifiedColor = (index) => {
        return saveRequired(index) ? 'black' : 'transparent';
    };

    const modifiedLabel = (index) => {
        return saveRequired(index) ? 'Modified' : null;
    }

    const saveRequired = (index) => {
        return modified.includes(index);
    };

    const saveWorkflowCancel = () => {
        setWorkflowSaveOpen(false);
        // just keep the current state it may work out or may loose changes later
    };

    const saveWorkflowSave = (index) => {
        setWorkflowSaveOpen(false);
        doUnmarkModified(index);
        saveToServer(workflowList[index]);
    };

    const saveWorkflowDiscard = (index) => {
        setWorkflowSaveOpen(false);
        const def = workflowList[index];
        if ( def && typeof def.saveSteps !== 'undefined') {
            // put back the steps
            def.steps = [ ...def.saveSteps];
            delete def.saveSteps;
            // report we have no changes
            workflowChangeHandler(false, index);          
        }
    }

    return (
        
        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '25% 3px 1fr', gridTemplateRows: '100%' }}>

            <Box sx={{position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid',  gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content'}}>
                <Typography align="center" sx={{paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Workflows</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                />
                <Box 
                    sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }} 
                    onContextMenu={(event) => handleContextMenu(event, -1)}
                >

                    <List sx={{ minHeight: '2em' }}>
                        {
                            workflowList.map((def, index) => (
                                <ListItem
                                    key={'mwwa-' + index} 
                                    sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                    onContextMenu={(event) => handleContextMenu(event, index)}
                                >
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: lockIconColor(index), fontSize: '12px'}}>
                                        <Tooltip title={lockIconLabel(index)}>
                                            <span>
                                                <LockIcon fontSize="inherit" />
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: modifiedColor(index), fontSize: '12px', paddingTop: '0.5ex'}}>
                                        <Tooltip title={modifiedLabel(index)}>
                                            <Typography sx={{paddingLeft: '4px'}}>*</Typography>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: publishedIconColor(index), fontSize: '12px'}}>
                                        <CheckIcon fontSize="inherit" />
                                    </Box>
                                    <ListItemButton selected={selectedIndex === index} onClick={(event) => handleItemClick(index)} sx={{paddingLeft: '4px', fontStyle: def.category.endsWith('SUB') ? 'italic' : 'normal'}}>
                                        <ListItemText>{def.label}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                        }
                    </List>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleMenuCheckout} disabled={!checkoutEnabled}>Checkout</MenuItem>
                        <MenuItem onClick={handleMenuRevokeCheckout} disabled={!checkinEnabled}>Revoke Checkout</MenuItem>
                        <MenuItem onClick={saveWorkflowHandler} disabled={!saveWorkflowEnabled}>Save</MenuItem>
                        <MenuItem onClick={handleMenuCheckin} disabled={!checkinEnabled}>Checkin</MenuItem>
                        <MenuItem onClick={handleCreateWorkflowAction} disabled={!addWorkflowEnabled}>Add</MenuItem>
                        <MenuItem onClick={handleMenuDeleteWorkflow} disabled={!deleteWorkflowEnabled}>Delete</MenuItem>
                    </Menu>
                    <WorkflowCheckinDialog open={checkinDialogOpen} onSave={handleCheckinSave} onCancel={handleCheckinCancel} 
                        title={checkinDialogTitle} workflowName={checkinDialogWorkflowName} />
                    <WorkflowRevokeCheckoutDialog open={revokeDialogOpen} onSave={handleRevokeCheckoutSave} title={revokeDialogTitle}
                        onCancel={handleRevokeCheckoutCancel} workflowName={checkinDialogWorkflowName} />
                </Box>
                <Box sx={{padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Checkout Workflow">
                        <IconButton color="inherit" disabled={!checkoutEnabled} onClick={handleCheckoutAction} sx={{paddingLeft: '2px', paddingRight: '6px'}}>
                            <OutputIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Revoke Checkout Workflow">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleRevokeCheckoutAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <ReplayIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Workflow">
                        <IconButton color="inherit" disabled={!saveWorkflowEnabled} sx={{paddingLeft: '6px', paddingRight: '6px'}} onClick={saveWorkflowHandler}>
                            <SaveAltIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Checkin Workflow">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleCheckinAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <ExitToAppIcon color="inherit" sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Create a Workflow">
                        <IconButton color="inherit" sx={{ marginLeft: '1em', paddingLeft: '6px', paddingRight: '6px' }} disabled={!addWorkflowEnabled} onClick={handleCreateWorkflowAction} >
                            <AddIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Workflow">
                        <IconButton color="inherit" disabled={!deleteWorkflowEnabled} onClick={handleDeleteWorkflowAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <CreateWorkflowDialog open={workflowDialogOpen} documentList={docList} onSave={workflowDialogCreateHandler} onCancel={workflowDialogCancelHandler} 
                        template={workflowDialogTemplate} name={workflowDialogName} description={workflowDialogDescription} 
                        title={workflowDialogTitle} />
                    <WorkflowDeleteDialog title="Delete Workflow" open={workflowDeleteOpen} workflowName={workflowDeleteName} 
                        onSave={deleteWorkflowSave} onCancel={deleteWorkflowCancel} />
                    <WorkflowSaveDialog title="Save Workflow" open={workflowSaveOpen} workflowName={workflowSaveName} workflowIndex={workflowSaveIndex}
                        onSave={saveWorkflowSave} onCancel={saveWorkflowCancel} onDiscard={saveWorkflowDiscard} />
                </Box>
            </Box>

            <Divider orientation="vertical"/>


            <Box sx={{overflowY: 'auto', position: 'relative', height: '100%'}}>
                
                <Box sx={{height: '100%'}}>
                    {
                        workflowList && workflowList.length > 0 ?
                            <WorkflowDefinitionAttributesEdit def={workflowList[selectedIndex]} onChange={workflowChangeHandler} workflowIndex={selectedIndex}
                                saveEnabled={saveWorkflowEnabled} workflowList={workflowList}/>
                            : null
                    }
                </Box>
                
            </Box>


            
        </Box>
    );

}


export { WorkflowDefinitionAttributesEdit, EditWorkflow, };